<div class="btn-group component-action-button">
    <ng-container *ngFor="let action of actions" [ngSwitch]="action.type">
        <ng-container *ngSwitchCase="actionType.View">
            <button container="body"  [ngbTooltip]="actions.length === 4 ? actionTooltip('see') : 'projects.dashboard'|translate" id="preview" type="button" class="btn btn-sm" [ngClass]="buttonOutlined ? 'btn-outline-primary' : 'btn-primary'" (debounceClick)="onButtonClick(action.type)" [appActionRight]="initAction(action)" *ngIf="!canEdit || !hideViewIfEditAllowed">
                <fa-icon [icon]="faEye" [fixedWidth]="true"></fa-icon>
            </button>
        </ng-container>
        <button container="body"  [ngbTooltip]="actions.length === 4 ? actionTooltip('edit') : 'tags.configuration'|translate" id="edit" *ngSwitchCase="actionType.FormUpdate" type="button" class="btn btn-sm" [ngClass]="buttonOutlined ? 'btn-outline-primary' : 'btn-primary'" (debounceClick)="onButtonClick(action.type)" [appActionRight]="initAction(action)">
            <fa-icon [icon]="faEdit" [fixedWidth]="true"></fa-icon>
        </button>
        <div *ngSwitchCase="actionType.Export" [appActionRight]="initAction(action)" class="btn-group">
            <button id="share" type="button" class="btn dropdown-toggle" [ngClass]="buttonOutlined ? 'btn-outline-primary' : 'btn-primary'" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-placement="top">
                <fa-icon [icon]="faShareSquare" [fixedWidth]="true"></fa-icon>
            </button>
            <div class="dropdown-menu">
                <a id="exportWord" class="dropdown-item" style="cursor: pointer;" (debounceClick)="onButtonClick({ action: action.type, export: 'docx' })">
                    <fa-icon [icon]="faFileWord" style="color:#1451b1"></fa-icon> Word
                </a>
                <a id="exportExcel" class="dropdown-item" style="cursor: pointer;" (debounceClick)="onButtonClick({ action: action.type, export: 'sheet' })">
                    <fa-icon [icon]="faFileExcel" style="color:#10743d"></fa-icon> Excel
                </a>
                <a id="exportPdf" class="dropdown-item" style="cursor: pointer;" (debounceClick)="onButtonClick({ action: action.type, export: 'pdf' })">
                    <fa-icon [icon]="faFilePdf" style="color:#cc1b21"></fa-icon> PDF
                </a>
            </div>
        </div>
        <button container="body" id="duplicate" [ngbTooltip]="'pim.actions.duplicate' | translate" *ngSwitchCase="actionType.Duplicate" class="btn btn-sm" [ngClass]="buttonOutlined ? 'btn-outline-primary' : 'btn-primary'" (debounceClick)="onButtonClick(action.type, $event)" [appActionRight]="initAction(action)">
            <fa-icon [icon]="faClone" [fixedWidth]="true"></fa-icon>
        </button>
        <button id="update" [ngbTooltip]="'pim.actions.edit'| translate" *ngSwitchCase="actionType.Update" class="btn btn-sm" [ngClass]="buttonOutlined ? 'btn-outline-primary' : 'btn-primary'" (debounceClick)="onButtonClick(action.type)" [appActionRight]="initAction(action)">
            <fa-icon [icon]="faTools" [fixedWidth]="true"></fa-icon>
        </button>
        <button container="body" [ngbTooltip]="'tooltip.delete'|translate" id="delete" *ngSwitchCase="actionType.Delete" class="btn btn-sm" [ngClass]="buttonOutlined ? 'btn-outline-danger' : 'btn-danger'" (debounceClick)="onButtonClick(action.type)" [appActionRight]="initAction(action)" >
            <fa-icon [icon]="faTrash" [fixedWidth]="true"></fa-icon>
        </button>
        <button id="historic" *ngSwitchCase="actionType.Story" class="btn btn-sm" [ngClass]="buttonOutlined ? 'btn-outline-primary' : 'btn-primary'" (debounceClick)="onButtonClick(action.type, $event)" [appActionRight]="initAction(action)">
            <fa-icon [icon]="faHistory" [fixedWidth]="true"></fa-icon>
        </button>
    </ng-container>
</div>
