<div class="justify-content-center">
    <div class="row">
        <div class="col-12 col-md-12 tableComponent">
            <div class="card">
                <div class="card-body table-responsive">
                    <h3 class="text-primary text-center">{{ "projects.dashboard" | translate }}</h3>
                    <div *ngIf="_selectedRows.length" class="col text-right">
                        <button id="cart" class="btn btn-maestro-yellow" data-toggle="modal" data-target="#cartModal"
                            [disabled]="!_selectedRows.length">
                            {{ "datatable.selection" | translate }}
                            <span *ngIf="_selectedRows.length"
                                class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-danger">{{
                                _selectedRows.length }}</span>
                        </button>
                    </div>
                    <hr/>
                    <div *ngIf="!table || table.length === 0">
                        <div class="font-italic text-center font-weight-bold">{{ 'general.no.result' | translate }}</div>
                    </div>
                    <div class="row">
                        <div class="col-7">
                            <div class="border-left border-warning px-3 mb-3" style="border-left-width: 3px !important;">
                                <small class="text-muted">{{ "projects.project" | translate }}</small>
                                <div><h4>{{ project.title }}</h4></div>
                                <div><p [innerHTML]="project.description"></p></div>
                            </div>
                        </div>
                        <div class="col-5">
                            <ul *ngIf="project.projectDocuments">
                                <li *ngFor="let doc of project.projectDocuments; let i = index">
                                    <a href="#" (click)="openDocument(doc.id, doc.filename);">{{ doc.filename }}</a>
                                </li>
                            </ul>

                        </div>
                    </div>
                    <hr/>
                    <div class="row">
                        <div class="col-4">
                            <div class="border-left border-danger px-3 mb-3" style="border-left-width: 3px !important;">
                                <small class="text-muted">{{ "projects.export.progress.title" | translate }}</small>
                                <div><strong>{{ "projects.export.progress.workflow" | translate }}</strong></div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="border-left border-info px-3 mb-3" style="border-left-width: 3px !important;">
                                <small class="text-muted">{{ "projects.export.progress.title" | translate }}</small>
                                <div><strong>{{ "projects.export.progress.configuration" | translate }}</strong></div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="border-left border-warning px-3 mb-3" style="border-left-width: 3px !important;">
                                <small class="text-muted">{{ "projects.export.progress.title" | translate }}</small>
                                <div><strong>{{ "projects.export.progress.page" | translate }}</strong></div>
                            </div>
                        </div>
                    </div>
                    <div class="row mt-2 mb-2">
                        <div class="col-md-12 text-center">                        
                            <ng-container *ngTemplateOutlet="exportButtons"></ng-container>
                        </div>
                    </div>

                    <p-table  [value]="exports" [(selection)]="_selectedRows" dataKey="name">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width: 1rem">
                                    <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
                                </th>
                                <th class="border-top-0">{{ "table.name" | translate }}</th>
                                <th class="border-top-0">{{ "table.workflow" | translate }}</th>
                                <th class="border-top-0"></th>
                                <th class="border-top-0"></th>
                                <th class="border-top-0 text-center">{{ "table.groups" | translate }}</th>
                                <th class="border-top-0 text-right">{{ "table.element" | translate }}</th>
                                <th class="border-top-0 text-right" style="max-width: 170px;width: 170px;">{{ "table.actions" | translate }}</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-projectExport>
                            <tr>
                                <td class="pt-2">
                                    <p-tableCheckbox [value]="projectExport"></p-tableCheckbox>
                                </td>
                                <td><span *ngIf="projectExport.versionId"> └ Version : </span> {{ projectExport.name }}</td>
                                <td>
                                    <div class="clearfix">
                                        <div class="clearfix" *ngIf="getPageStepProgressInfo(projectExport).workflow">
                                            <span *ngIf="getPageStepProgressInfo(projectExport).type !== 'Cdf_page'" style="wrongcolor:{{ projectExport.color }}"><strong>{{ getPageStepProgressInfo(projectExport).place }}</strong> </span>
                                            <span class="float-right text-muted">{{ getPageStepProgressInfo(projectExport).workflow }}</span>
                                        </div>
    
                                        <div class="progress-group-bars">
    
                                            <div *ngIf="getPageStepProgressInfo(projectExport).workflow" class="progress" style="height: 4px;">
                                                <div class="progress-bar bg-danger" role="progressbar" style="width:{{ getPageStepProgressInfo(projectExport).progress }}%;" aria-valuenow="{{ getPageStepProgressInfo(projectExport).progress }}" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <div *ngIf="projectExport.workflow" class="progress" style="height: 4px;">
                                                <div class="progress-bar bg-danger" role="progressbar" style="width:{{ projectExport.progress }}%;" aria-valuenow="{{ projectExport.progress }}" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
    
                                            <div class="progress" style="height: 4px;">
                                                <div class="progress-bar bg-info" role="progressbar" style="width: 100%" aria-valuenow="16" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                            <div class="progress" *ngIf="projectExport.type !== 'CSV' && projectExport.type !== 'JSON' && projectExport.type !== 'XML' && projectExport.type !== 'EXCEL';" style="height: 4px;">
                                                <div *ngIf="projectExport.pageLength <= projectExport.maxLength" class="progress-bar bg-warning" role="progressbar" style="width: {{ projectExport.pageLength / projectExport.maxLength*100 }}%" aria-valuenow="{{ projectExport.pageLength / projectExport.maxLength*100" aria-valuemin="0" aria-valuemax="100"></div>
                                                <div *ngIf="projectExport.pageLength > projectExport.maxLength" class="progress-bar bg-warning" role="progressbar" style="width: 100%" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </td>
                                <td class="text-center">
                                    <div class="text-value">{{ projectExport.elementLength}}</div>
                                    <div class="text-uppercase text-muted small">{{ 'general.products' | translate }}</div>
                                </td>
                                <td *ngIf="projectExport.type !== 'CSV' && projectExport.type !== 'JSON' && projectExport.type !== 'XML' && projectExport.type !== 'EXCEL';" class="text-center">
                                    <div class="text-value">
                                        {{ projectExport.pageLength }}
                                        <!--
                                            Not Logical in interface : we can display this part for some exports that match condition but not for others
                                            <span *ngIf="projectExport.pageLength <= projectExport.maxLength"> / {{ projectExport.maxLength }} </span>
                                        -->
                                    </div>
                                    <div class="text-uppercase text-muted small">{{ (projectExport.type === "CDF" ? 'projects.pages' : 'projects.exports') | translate }}</div>
    
                                </td>
                                <td class="text-center">
                                    <div class="text-value">
                                        <span *ngFor="let groupName of projectExport.groupNames;" class="badge badge-pill badge-primary mr-1 ng-star-inserted">{{ groupName }}</span>
                                    </div>
                                </td>
                                <td *ngIf="projectExport.type === 'CSV' || projectExport.type === 'JSON' || projectExport.type === 'XML' || projectExport.type === 'EXCEL';" ></td>
                                <td>
                                    <div class="float-right">
                                        <span class="text-uppercase"> {{ projectExport.type }} </span>
                                        <span [ngClass]="getIconClass(projectExport.type)" class=" text-muted fa-w-12 fa-2x"></span>
                                    </div>
                                </td>
                                <td class="text-right">
                                    <button *ngIf="projectExport.type === 'EXCEL'" (click)="onActionClick(projectExport, false)" type="button" class="btn btn-sm" data-toggle='modal' data-target='#csvModal' [ngClass]="'btn-outline-primary'">
                                        <fa-icon [icon]="faEye" [fixedWidth]="true"></fa-icon>
                                        <span *ngIf="projectExport.type === 'CSV'">{{ 'general.showCSV' | translate }}</span>
                                        <span *ngIf="projectExport.type === 'EXCEL'">{{ 'general.showExcel' | translate }}</span>
                                    </button>
                                    <button *ngIf="canEdit(projectExport) && !projectExport.versionId" (click)="onActionClick(projectExport)" type="button" class="btn btn-sm" [ngClass]="'btn-outline-primary'">
                                        <div *ngIf="projectExport.type !== 'CSV' && projectExport.type !== 'JSON' && projectExport.type !== 'XML' && projectExport.type !== 'EXCEL'; then thenBtnWorkBlock else elseBtnDownloadBlock"></div>
                                        <ng-template #thenBtnWorkBlock ><fa-icon [icon]="faEdit" [fixedWidth]="true"></fa-icon> {{ 'projects.work' | translate }} </ng-template>
                                        <ng-template #thenBtnWorkBlock><fa-icon [icon]="faEdit" [fixedWidth]="true"></fa-icon> {{ 'projects.work' | translate }}</ng-template>
                                        <ng-template #elseBtnDownloadBlock><fa-icon [icon]="faDownload" [fixedWidth]="true"></fa-icon> {{ 'general.download' | translate }}</ng-template>
                                    </button>
                                    <button *ngIf="canEdit(projectExport) && projectExport.versionId" (click)="onActionClick(projectExport)" type="button" class="btn btn-sm" [ngClass]="'btn-outline-primary'">
                                        <div *ngIf="projectExport.type !== 'CSV' && projectExport.type !== 'JSON' && projectExport.type !== 'XML' && projectExport.type !== 'EXCEL'; then thenBtnWorkBlock else elseBtnDownloadBlock"></div>
                                        <ng-template #thenBtnWorkBlock ><fa-icon [icon]="faEdit" [fixedWidth]="true"></fa-icon> {{ 'projects.work' | translate }} </ng-template>
                                        <ng-template #thenBtnWorkBlock><fa-icon [icon]="faEdit" [fixedWidth]="true"></fa-icon> {{ 'projects.work' | translate }}</ng-template>
                                        <ng-template #elseBtnDownloadBlock><fa-icon [icon]="faDownload" [fixedWidth]="true"></fa-icon> {{ 'general.download' | translate }}</ng-template>
                                    </button>
                                    <div *ngIf="projectExport.type !== 'CSV' && projectExport.type !== 'JSON' && projectExport.type !== 'XML' && projectExport.type !== 'EXCEL'">
                                        <button *ngIf="!projectExport.versionId && canCreateVersion()" class="btn btn-sm" [ngClass]="'btn-outline-info'" (click)="onNewVersion(projectExport.id)">
                                            <i class="far fa-copy"></i>{{'version.new' | translate}}
                                        </button>
                                        <button *ngIf="projectExport.versionId && canCreateVersion()" class="btn btn-sm" [ngClass]="'btn-outline-info'" (click)="onUpdateVersion(projectExport.id, projectExport.versionId)">
                                            <i class="far fa-copy"></i>{{'version.update' | translate}}
                                        </button>
                                        <button *ngIf="projectExport.versionId && canCreateVersion()" class="btn btn-sm" [ngClass]="'btn-outline-danger'" (click)="onDeleteVersion(projectExport.versionId)">
                                            <i class="far fa-trash-alt"></i>{{'version.delete' | translate}}
                                        </button>
                                    </div>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
        </div>
        <!--div class="col-12 col-md-12">
            <div class="card-deck">
                <ng-container *ngFor="let export of exports; let i = index">
                    <div [export]="export" class="card card-export" app-export-card></div>
                    <div *ngIf="i % 2 !== 0" class="w-100 mt-4"></div>
                </ng-container>
            </div>
        </div-->
    </div>
</div>
<swal #versionSwal title="{{ 'version.new' | translate }}" [showConfirmButton]="true" confirmButtonText="{{ 'general.save' | translate}}" [showCloseButton]="true">
    <form *swalPortal [formGroup]="newVersion">
        <div class="form-group">
            <div *ngIf="newVersion.controls.duplicatePages" class="form-group row">
                <label for="active" class="col-sm-4 col-form-label">{{ 'projects.flatplan.duplicatePages' | translate }}</label>
                <div class="col-sm-8">
                    <label class="switch switch-label switch-primary">
                        <input id="active" class="switch-input" type="checkbox" formControlName="duplicatePages"/>
                        <span class="switch-slider" data-checked="✓" data-unchecked="✕"></span>
                    </label>
                </div>
            </div>
            <div class="form-group row">
                <label for="name" class="col-sm-4 col-form-label">
                    {{'table.name' | translate}} :
                </label>
                <div class="col-sm-8">
                    <input class="form-control" type="text" formControlName="name" />
                </div>
            </div>
            <div class="form-group row">
                <label for="groups" class="col-sm-4 col-form-label">
                    {{'table.groups' | translate}} :
                </label>
                <div class="col-sm-8">
                    <p-multiSelect (onChange)="getUsers($event)" formControlName="groups" [options]="groups" optionLabel="name" optionValue="id" [selectionLimit]="1" defaultLabel="{{ (groups.length ? 'admin.groups.selectOwner' : 'admin.groups.noGroup') | translate }}" [style]="{'width':'100%'}"></p-multiSelect>
                </div>
            </div>

            <nav *ngIf="newVersion.controls.steps" >
                <div class="nav nav-tabs" id="nav-steps-tabs" role="tablist">
                    <a *ngFor="let step of newVersion.controls.steps['controls']; let i = index" [ngClass]="{ 'nav-item nav-link': true, active: i === 0 }" id="nav-etape-{{ i }}-tab" data-toggle="tab" href="#nav-etape-{{ i }}" role="tab" [attr.aria-controls]="'nav-etape-' + i" [attr.aria-selected]="i === 0 ? 'true' : 'false'">
                        {{ step.get('name').value }}
                    </a>
                </div>
            </nav>

            <div *ngIf="newVersion.controls.steps" class="tab-content" id="nav-places-content">
                <div *ngFor="let step of newVersion.controls.steps['controls']; let i = index" [ngClass]="{ 'tab-pane fade': true, 'show active': i === 0 }" id="nav-etape-{{ i }}" role="tabpanel" [attr.aria-labelledby]="'nav-etape-' + i + '-tab'" >
                    <div  formArrayName="steps">
                        <div [formGroupName]="i">
                            <div class="form-group row">
                                <label class="col-sm-2 col-form-label">
                                    {{ 'projects.conf.due_date' | translate }}
                                </label>
                                <div class="col-sm-10">
                                    <app-date-picker  [active]="true" formControlName="dateFin"></app-date-picker>
                                </div>
                            </div>
                            <div class="form-group row">
                                <label for="groups" class="col-sm-4 col-form-label">
                                    {{'admin.workflow.users' | translate}} :
                                </label>
                                <div class="col-sm-8">
                                    <p-multiSelect formControlName="users" [options]="users" optionLabel="name" optionValue="id" [style]="{'width':'100%'}"></p-multiSelect>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </form>
</swal>

<!-- Modal for CSV -->
<div class="modal fade" id="csvModal" tabindex="-1" role="dialog" aria-labelledby="csvModal" aria-hidden="true">
    <div class="modal-dialog modal-xl" role="csvModal">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title">{{ 'general.feedAgregator' | translate}}</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body csv-modal-body">
                <table class="myTable tableFixHead">
                    <thead>
                    <tr>
                        <th *ngFor="let header of csvHeaders;">
                            <span>{{ hideQuotes(header) }}</span>
                        </th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let row of csvRows;">
                        <td #td (click)="removeTruncation(td)" *ngFor="let cell of row;">
                            <span *ngIf="cell.length > 0">{{ hideQuotes(cell) }}</span>
                            <span *ngIf="cell.length == 0">{{ cell }}</span>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
            <div class="modal-footer">
                <button (click)="downloadFlux()" class="btn btn-primary">{{ 'general.download' | translate }}</button>
            </div>
        </div>
    </div>
</div>
<!-- END Modal for CSV -->

<!-- Template Mass Buttons Local -->
<ng-template #exportButtons>
    <div class="inline" *ngIf="_selectedRows.length">
        <button type="button" pButton pRipple
            (click)="getIdsForArchive()"
            class="btn btn-info text-white mr-1"><i class="pi pi-download"></i><span class="ml-2">{{
                'projects.export.generation.downloadArchive' | translate }} {{ 'projects.export.generation.archiveBd' | translate }}</span></button>
        <button type="button" pButton pRipple
        (click)="getIdsForArchive(true)"
        class="btn btn-info text-white mr-1"><i class="pi pi-download"></i><span class="ml-2">{{
            'projects.export.generation.downloadArchive' | translate }} {{ 'projects.export.generation.archiveHd' | translate }}</span></button>
    </div>
</ng-template>

<!-- Selection Modal Local -->
<div class="modal fade" id="cartModal" tabindex="-1" role="dialog" aria-labelledby="cartModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h5 class="modal-title" id="cartModalLabel">{{ "datatable.selection" | translate }} : {{
                    _selectedRows.length }} {{ 'projects.project' | translate }}(s)</h5>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <ul class="list-unstyled">
                    <div *ngIf="_selectedRows.length">
                        <li *ngFor="let row of _selectedRows" class="pb-2 pt-2">
                            <div class="row">
                                <div class="col-8 text-truncate mt-3">
                                    <span class="font-weight-bold">{{ row.name }} </span>
                                </div>
                                <div class="col-4 mt-2 text-right">
                                    <button (click)="removeFromSelection(row.name)" class="ml-auto btn btn-sm-danger">
                                        <i class="pi pi-minus-circle"></i>
                                    </button>
                                </div>
                            </div>
                        </li>
                    </div>
                    <div *ngIf="!_selectedRows.length">
                        {{ "datatable.emptySelection" | translate }}
                    </div>
                </ul>
            </div>
            <div class="modal-footer">
                <ng-container *ngTemplateOutlet="exportButtons"></ng-container>
            </div>
        </div>
    </div>
</div>

