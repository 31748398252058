import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { Observable } from 'rxjs';

@Injectable()
export class MessageInformationService {
  
  constructor(private http:HttpClient){}


  getMessage(): Observable<any>{

    return this.http.get<any>(`${environment.adminApiUrl}/messages-information.json`)
  }
}