import { Component, Input } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { ElementService } from "app/core/services/pim/element.service";
import { ElementExportService } from "app/core/services/pim/export.service";
import { ActionEvent, ActionType } from "app/shared/components/action-buttons";
import { MaestroElements } from "app/shared/models";

@Component({
    selector: "app-list-preview",
    templateUrl: "./list-preview.component.html",
})
export class ListPreviewComponent {
    @Input() elements: MaestroElements;
    @Input() actions: ActionType[];

    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        private _elementService: ElementService,
        private _modalService: SwalModalService,
        private _translate: TranslateService,
        private _exportElement: ElementExportService
    ) {}

    /**
     * Handle action click
     *
     * @param event
     */
    onActionClick(event: ActionEvent) {
        const action = typeof event.action === "object" ? event.action["action"] : event.action;
        const id = event.rowId;
        switch (action) {
            case ActionType.Delete:
                this._delete(id);
                break;
            case ActionType.FormUpdate:
                this._router.navigate(["update", id], { relativeTo: this._route });
                break;
            case ActionType.View:
                this._router.navigate(["view", id], { relativeTo: this._route });
                break;
            case ActionType.Export:
                this._exportElement.generate(id, event.action["export"]);
                break;
        }
    }

    /**
     * Delete an element
     *
     * @param id
     */
    private _delete(id: number) {
        if (id) {
            this._elementService.deleteElement(id).subscribe((data) => {
                this._modalService.success(this._translate.instant("general.deleted"));
                //this._elementService.getElements().subscribe(elements => this._initList(elements));
            });
        }
    }
}
