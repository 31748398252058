import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { VersionWTF } from "app/shared/models";
import { Observable } from "rxjs";
import { environment } from "src/environments";

@Injectable()
export class VersionService {
    constructor(private _http: HttpClient) {}

    public getVersions(): Observable<any> {
        return this._http.get(`${environment.pimUrl}/versions.json`);
    }

    public getVersionsByElement(referentId: number): Observable<any> {
        return this._http.get(`${environment.pimUrl}/versions/element/${referentId}.json`);
    }

    public elementsByVersion(referentId: number, versionId: number, version_number: number): Observable<any> {
        return this._http.post(`${environment.pimUrl}/element/${referentId}/version/${versionId}.json`, { version_number: version_number });
    }

    public getVersionValid(referentId: number): Observable<any> {
        return this._http.get(`${environment.pimUrl}/versions/element/${referentId}/valid.json`);
    }

    public createVersion(version: VersionWTF): Observable<any> {
        return this._http.post(`${environment.pimUrl}/version.json`, version);
    }
}
