import { Component, EventEmitter, OnInit, Output, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";
import { faPlusSquare, faTimesCircle } from "@fortawesome/free-regular-svg-icons";
import { faEye, faPlusCircle, faTrash } from "@fortawesome/free-solid-svg-icons";
import { NgbNav } from "@ng-bootstrap/ng-bootstrap";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "app/core/services/admin/user/user.service";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { DROPOWN_SETTINGS } from "app/shared/components/form/drop-down/settings";
import { ExportType } from "app/shared/helpers/export-type.helper";
import { MaestroElementTypes, MaestroElements, MaestroProject, MaestroTemplates } from "app/shared/models";
import { Validators as NgxValidator } from "ngx-editor";
import Swal from "sweetalert2";
import { ExportsConfigurationService } from "../../../../../../../core/services/project/project/exports.service";
import { ProjectConfigurationStepperService } from "../../../../../../../core/services/project/project/stepper.service";
import { ExportConfiguration, ExportConfigurations } from "../../../../../../../shared/models/project/export-configuration";
import { ConfigurationStepComponent } from "../../../../../../../shared/models/project/step-component.interface";

@Component({
    selector: "app-configuration-step-exports",
    templateUrl: "./exports.component.html",
})
export class ConfigurationStepExportsComponent extends ConfigurationStepComponent<ExportConfigurations> implements OnInit {
    submitted: boolean;

    resources: any;
    exportConfigs: ExportConfigurations;
    elements: MaestroElements;
    projectId: number;
    // auths: any;
    isWorkable: boolean;
    canUpdate: boolean;
    categories: Array<any>;
    currentLang: string;

    useProduct = false;

    // forms
    form: FormGroup;
    formArray = this._formBuilder.array([]);
    templateDisabled: boolean = false;
    // data for lists
    exportTypeList: any[] = [];
    currentExportTypeList: Array<any>;
    currentTypeExport: any[] = [];
    templateList: MaestroTemplates[] = [];
    currentNav = 0;
    // icons
    readonly faPlusSquare = faPlusSquare;
    readonly faTimesCircle = faTimesCircle;
    readonly faPlus = faPlusCircle;
    readonly faTrash = faTrash;
    readonly faTimes = faEye;
    // store
    private _templatesByExportType: { [exportTypeId: string]: MaestroTemplates };
    private _elementTypes: MaestroElementTypes;
    project: MaestroProject;

    multiple: any[] = [];
    @Output() eventSubmitted = new EventEmitter<any>();

    dropdownSettings = DROPOWN_SETTINGS.multiple;
    dropdownSettingsSingle = DROPOWN_SETTINGS.single;

    @ViewChild("nav") nav: NgbNav;

    constructor(
        private _formBuilder: FormBuilder,
        private _service: ExportsConfigurationService,
        private _stepper: ProjectConfigurationStepperService,
        private _route: ActivatedRoute,
        private _swalModalService: SwalModalService,
        private _translate: TranslateService,
        private _userService: UserService
    ) {
        super();
        this.currentLang = this._translate.currentLang;
    }

    onlyUnique(value, index, self) {
        return self.indexOf(value) === index;
    }

    ngOnInit(): void {
        this.useProduct = this._stepper.withProduct.getValue();
        this.categories = [];
        let pos = 0;
        ExportType.forEach((type) => {
            let indexCat = this.categories.findIndex((cat) => cat.name == this._translate.instant("projects.conf.categories." + type.categorie));
            if (this.categories.length <= 0 || indexCat < 0) {
                if(type.categorie != "flux" || (type.categorie == "flux" && this.useProduct) ){
                    this.categories.push({
                        id: pos,
                        name: this._translate.instant("projects.conf.categories." + type.categorie),
                    });

                    pos++;
                }
            }
        });

        this._stepper.setCurrentStep(2);
        this.resources = this._route.snapshot.data.exports;
        const acl = this._userService.getUserAclFromToken();
        this.canUpdate = /*this.resources.auths.includes("ON_PROJECT_EXPORT_EDIT") ||*/ acl.MAESTRO_PROJECT_PROJECTS_UPDATE === 1;
        this.projectId = this.resources.projectId;

        this.isWorkable = true;

        if (this.resources.elements && this.resources.elements.length > 0) {
            this.elements = this.resources.elements;
            this._elementTypes = this.resources.elements.map((el) => el.type);

            const copy = [];

            this._elementTypes.forEach((et) => {
                if (!copy.map((ett) => ett.id).includes(et.id)) {
                    copy.push(et);
                }
            });

            this._elementTypes = copy;
        }

        // this._templatesByExportType = this.resources.templatesByExportType;
        let exportTypes = [];
        this.resources.config.forEach((exp) => {
            if (exp.type && exp.type.id) {
                exportTypes.push(exp.type.id);
            }
        });

        this._service.getExportTypesTemplates(JSON.stringify(exportTypes)).subscribe((templates: any) => {
            this._templatesByExportType = templates.data;

            exportTypes.forEach((exportType) => {
                if (!this._templatesByExportType[exportType]) {
                    this._templatesByExportType[exportType] = [];
                }
            });
            this.exportConfigs = this.resources.config;
            this.project = this.resources.project;
            this.form = this._initForm(this.exportConfigs);
        });
    }

    isDeactivable(): boolean {
        return true;
    }

    /**
     * Add a new export
     *
     * @param event
     */
    addTab(event: MouseEvent) {
        event.preventDefault();
        this._addNewTab();
        this.nav.select(this.formArray.controls.length - 1);
    }

    /**
     * Delete an export
     *
     * @param index
     */
    onDeleteTab(index) {
        this._swalModalService.delete().then((result) => {
            if (result.value) {
                this._deleteTab(index);
                this.nav.select(this.formArray.controls.length - 1);
            }
        });
    }

    /**
     * Save the configuration
     */
    onSubmit() {
        this.submitted = true;
        if (this.form.valid) {
            let t = this._formToObject(this.form);

            t.data.forEach((e, k) => {
                let canal = this.exportTypeList[k].find((canal) => canal.id == e.exportType[0].id);

                e["canal"] = canal.key;
                e["exportType"] = canal.typeId;
                e.typeTabs.forEach((et) => {
                    et.templates = e.templates || [];
                });
                // if(this.useProduct){
                //     delete e["templates"];
                // }
            });
            if (!this.isGrantedToSave()) {
                this._stepper.goToNext(this.projectId);
            } else {
                this._service.saveConfiguration(this.projectId, t).subscribe((_) => {
                    this._stepper.validStep(2);
                    this.eventSubmitted.emit(t);
                    this._stepper.goToNext(this.projectId);
                });
            }
        }
    }

    onBack() {
        this._stepper.goToPrevious(this.projectId);
    }

    /**
     * Init form
     * @param configs
     * @returns
     */
    protected _initForm(configs: ExportConfigurations): FormGroup {
        const form = this._formBuilder.group({
            tabs: this.formArray,
        });

        if (configs && configs.length) {
            let indexConfig = 0;
            configs.forEach((mExport) => {
                this.formArray.push(this._initExportForm(mExport, indexConfig));
                indexConfig++;
            });
        } else {
            this._addNewTab();
        }

        return form;
    }

    protected _formToObject(form: FormGroup): any {
        return {
            lastStep: this._stepper.isLastStep(),
            data: form.get("tabs").value.map(
                (data) =>
                    <ExportConfigurations>{
                        ...data,
                    }
            ),
        };
    }

    /**
     * Init export forms
     * @param config
     * @returns
     */
    private _initExportForm(config?: ExportConfiguration, indexConfig?) {
        let categorieName = {};
        let chanel = null;
        let type = null;
        let canal = null;

        if (config.type) {
            type = ExportType.find((exportype) => exportype.id == config.type["id"]);

            if (type) {
                let name = this._translate.instant("projects.conf.categories." + type.categorie);
                categorieName = {
                    id: this.categories.findIndex((cat) => cat.name == name),
                    name: name,
                };
                if (config.canal) {
                    let findChanel = type.chanel.find((chan) => chan.name == config.canal);

                    chanel = { id: findChanel.id, name: this._translate.instant("projects.conf.chanel." + findChanel.name) };
                } else {
                    let findChanel = type.chanel[0];
                    chanel = { id: type.chanel[0].id, name: this._translate.instant("projects.conf.chanel." + type.chanel[0].name) };
                }
            }
        }

        const form = this._formBuilder.group({
            title: [{ value: config.name, disabled: !this.canUpdate }, Validators.required],
            description: [{ value: config.description, disabled: !this.canUpdate }, NgxValidator.required()],
            typeTabs: this._formBuilder.array(this._initTypeTabForms(config)),
            exportType: [{ value: chanel ? [chanel] : [], disabled: !this.canUpdate }, Validators.required],
            templates: [{ value: config.templates ? config.templates : [], disabled: !this.canUpdate }, Validators.required],
            categorie: [{ value: categorieName && categorieName["name"] ? [categorieName] : [], disabled: !this.canUpdate }, Validators.required],
            // data
            id: [{ value: config.id, disabled: !this.canUpdate }],
        });

        if (type && ["csv", "json", "social", "excel"].includes(type.name)) {
            form.removeControl("templates");
        }

        if (type && type.id !== 0) {
            this.updateTemplates(form, type.id, indexConfig);
        }

        if (form.value.categorie.length > 0) {
            this.exportTypeList[indexConfig] = this.filterExportTypeList(form.value.categorie[0]);
        }

        if (form.value.exportType.length) {
            let exportType = form.value.exportType;

            this.multiple[indexConfig] = true;
            if (exportType.length > 0) {
                canal = this.currentExportTypeList.find((canal) => canal.id == exportType[0].id);

                type = ExportType.find((typeExport) => typeExport.id == canal.typeId);
            }

            if (!form.get("templates")) {
                form.addControl("templates", this._formBuilder.control([], Validators.required));
            }

            if (form.value.exportType && form.value.exportType.length > 0 && exportType[0] && exportType[0].id !== form.value.exportType[0].id) {
                form.patchValue({
                    templates: [],
                });
            } else {
                form.patchValue({
                    templates: config.templates ? config.templates : [],
                });
            }

            setTimeout(() => {
                if (type && canal) {
                    if (["csv", "json", "social", "excel"].includes(type.name)) {
                        form.removeControl("templates");

                        if (this.useProduct) {
                            (form.controls.typeTabs as any).controls[0].controls.elements.setValidators([Validators.required]);
                        }
                    } else if (type.name === "XML") {
                        this.multiple[indexConfig] = false;

                        if (this.useProduct) {
                            (form.controls.typeTabs as any).controls[0].controls.elements.setValidators([Validators.required]);
                        }
                    } else {
                        if (this.useProduct) {
                            (form.controls.typeTabs as any).controls[0].controls.elements.clearValidators();
                            (form.controls.typeTabs as any).controls[0].controls.elements.updateValueAndValidity();
                        }
                    }
                } else {
                    this.templateList[this.currentNav] = [];
                }

                this._stepper.withWorkflow.next(false);
                this._stepper.withTemplate.next(false);
                if (this.form.value.tabs.map((t) => (t.categorie[0] ? t.categorie[0].name : "NotSet")).filter((str) => ["Print", "Web"].includes(str)).length > 0) {
                    this._stepper.withWorkflow.next(true);
                    this._stepper.withTemplate.next(true);
                } else if (
                    this.form.value.tabs.map((t) => (t.categorie[0] ? t.categorie[0].name : "NotSet")).filter((str) => ["Flux"].includes(str)).length > 0 &&
                    this.form.value.tabs.map((t) => (t.exportType[0] ? t.exportType[0].name : "NotSet")).filter((str) => ["XML"].includes(str)).length > 0
                ) {
                    this._stepper.withTemplate.next(true);
                }
                if (this.form.value.tabs.map((t) => (t.exportType[0] ? t.exportType[0].name : "NotSet")).filter((str) => ["CSV", "JSON", "EXCEL"].includes(str)).length > 0) {
                    this._stepper.withFlux.next(true);
                }

                this._stepper.setSteps();
            }, 150);
        }

        return form;
    }

    private _initTypeTabForms(config?: ExportConfiguration) {
        return config.elementTypes.map((type) => {
            const f = this._formBuilder.group({
                elements: { value: type.elements, disabled: !this.canUpdate },

                // data unuse for form
                title: { value: type.name, disabled: !this.canUpdate },
                id: { value: type.id, disabled: !this.canUpdate },
            });
            return f;
        });
    }

    private _addNewTab() {
        const maestroExport = {
            name: "New export",
            description: null,
            elementTypes: this._elementTypes.map((type) => ({
                ...type,
                ...{ templates: [] },
            })),
            type: null,
        };

        this.formArray.push(this._initExportForm(maestroExport));
        this.currentNav = this.formArray.length - 1;
    }

    private updateTemplates(form: any, exportTypeId: number, tabIndex) {
        if (!this._templatesByExportType[exportTypeId]) {
            let exportTypes = [];
            exportTypes.push(exportTypeId);

            this.templateList[exportTypeId] = [];
            this._service.getExportTypesTemplates(JSON.stringify(exportTypes)).subscribe((templates: any) => {
                this._templatesByExportType[exportTypeId] = templates.data[exportTypeId];
                this.templateList[exportTypeId] = templates.data[exportTypeId];
            });
            this.currentTypeExport[tabIndex] = exportTypeId;
        } else {
            this.templateList[exportTypeId] = this._templatesByExportType[exportTypeId];

            this.currentTypeExport[tabIndex] = exportTypeId;
        }
    }

    private _deleteTab(tabIndex) {
        const form = this.formArray.controls[tabIndex] as FormGroup;
        if (form.value && form.value.id) {
            this._service.deleteConfiguration(form.value.id).subscribe(
                (_) => this.formArray.removeAt(tabIndex),
                (_) => {}
            );
        } else {
            this.formArray.removeAt(tabIndex);
        }
    }

    setDescription(exportTab: FormGroup, value: string): void {
        exportTab.patchValue({ description: value });
    }

    isGrantedToSave() {
        return this.canUpdate;
    }

    showPreviews() {
        const templateId = this._route.snapshot.params.id;
        let t = this._formToObject(this.form);

        t.data.forEach((e) => {
            e.typeTabs.forEach((et) => {
                et.templates = e.templates || [];
            });
            delete e["templates"];
        });

        const templates = t.data[this.currentNav].typeTabs.length > 0 ? t.data[this.currentNav].typeTabs[0].templates : [];
        let idTemplates = "";
        if (templates.length > 0) {
            templates.forEach((template) => {
                idTemplates += template.id + "_";
            });
            idTemplates = idTemplates.slice(0, -1);
        }

        if (idTemplates) {
            this._service.getPreviews(idTemplates, "Image").subscribe((response) => {
                const images = response.data;
                let swalContent = `
                    <div style="display: flex;
                        flex-wrap: wrap;
                        justify-content: center;
                        align-items: center;
                        max-height: 400px;
                        overflow-y: auto;"
                    >`;

                if (images.length === 0) {
                    swalContent += `<div class="alert alert-warning" role="alert" style="width:100%">` + this._translate.instant("modal.template") + `</div>`;
                } else {
                    images.forEach((item) => {
                        swalContent += `<img src="data:image/jpeg;base64,' + item.base64File + '" style="max-width: 100%; max-height: 100%; object-fit: contain; margin: 10px;">`;
                    });
                }
                swalContent += "</div>";

                Swal.fire({
                    title: this._translate.instant("modal.previewTemplateTitle"),
                    html: swalContent,
                    showCloseButton: true,
                    showConfirmButton: false,
                });
            });
        }
    }

    filterExportTypeList(categorie) {
        let result = [];
        let types = [];

        types = ExportType.filter((type) => {
            return this._translate.instant("projects.conf.categories." + type.categorie) == categorie.name;
        });

        if (types.length > 0) {
            types.forEach((type) => (result = result.concat(type.chanel.map((c) => <any>{ id: c.id, key: c.name, name: this._translate.instant("projects.conf.chanel." + c.name), typeId: type.id }))));
        }

        this.currentExportTypeList = result;

        return result;
    }

    onSelectCategorie(categorie, index, form) {
        this.exportTypeList[index] = this.filterExportTypeList(categorie);

        const exportType = form.get("exportType");
        const templates = form.get("templates");
        if (exportType) {
            exportType.setValue([]);
        }

        if (templates) {
            templates.setValue([]);
        }
        // this.formArray.controls[index].get('templates').setValue([]);
    }

    onSelectExportType(exportType, form, tabIndex) {
        let type = null;
        let canal = null;
        this.multiple[tabIndex] = true;

        if (exportType) {
            canal = this.currentExportTypeList.find((canal) => canal.id == exportType.id);

            type = ExportType.find((typeExport) => typeExport.id == canal.typeId);
            this.updateTemplates(form, canal.typeId, tabIndex);
        }

        if (!form.get("templates")) {
            form.addControl("templates", this._formBuilder.control([], Validators.required));
        }

        if (form.value.exportType && form.value.exportType.length > 0 && exportType && exportType.id !== form.value.exportType[0].id) {
            form.patchValue({
                templates: [],
            });
        }

        setTimeout(() => {
            if (type && canal) {
                if (["csv", "json", "social", "excel"].includes(type.name)) {
                    form.removeControl("templates");

                    if (this.useProduct) {
                        (form.controls.typeTabs as any).controls[0].controls.elements.setValidators([Validators.required]);
                    }
                } else if (type.name === "XML") {
                    this.multiple[tabIndex] = false;

                    if (this.useProduct) {
                        (form.controls.typeTabs as any).controls[0].controls.elements.setValidators([Validators.required]);
                    }
                } else {
                    if (this.useProduct) {
                        (form.controls.typeTabs as any).controls[0].controls.elements.clearValidators();
                        (form.controls.typeTabs as any).controls[0].controls.elements.updateValueAndValidity();
                    }
                }
            } else {
                this.templateList[this.currentNav] = [];
            }

            this._stepper.withWorkflow.next(false);
            this._stepper.withTemplate.next(false);
            if (this.form.value.tabs.map((t) => (t.categorie[0] ? t.categorie[0].name : "NotSet")).filter((str) => ["Print", "Web"].includes(str)).length > 0) {
                
                this._stepper.withWorkflow.next(true);
                this._stepper.withTemplate.next(true);
            } 
            if (
                this.form.value.tabs.map((t) => (t.categorie[0] ? t.categorie[0].name : "NotSet")).filter((str) => ["Flux"].includes(str)).length > 0 &&
                this.form.value.tabs.map((t) => (t.exportType[0] ? t.exportType[0].name : "NotSet")).filter((str) => ["XML"].includes(str)).length > 0
            ) {
                this._stepper.withTemplate.next(true);
            }
            if(
                this.form.value.tabs.map((t) => (t.exportType[0] ? t.exportType[0].name : "NotSet")).filter((str) => ["CSV","EXCEL"].includes(str)).length > 0
            ){
                
                this._stepper.withFlux.next(true);
            }

            this._stepper.setSteps();
        }, 150);
    }
}
