import { NgModule } from "@angular/core";
import { SharedComponentsModule, SharedModule } from "app/shared";
import { VariableListComponent } from "./variable-list.component";

@NgModule({
    declarations: [VariableListComponent],
    imports: [SharedModule, SharedComponentsModule],
    exports: [VariableListComponent],
    providers: [],
})
export class VariableListModule {}
