import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared/shared.module";
import { ActionButtonsModule } from "../action-buttons/action-buttons.module";
import { ViewButtonsModule } from "../view-buttons/view-buttons.module";
import { BreadCrumbFlatplanToolbarComponent } from "./bread-crumb-flatplan-toolbar/bread-crumb-flatplan-toolbar.component";
import { BreadCrumbMenuComponent } from "./bread-crumb-menu/bread-crumb-menu.component";
import { BreadcrumbComponent } from "./breadcrumb.component";
import { NgbAlertModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
    imports: [SharedModule, ViewButtonsModule, ActionButtonsModule,NgbAlertModule],
    declarations: [BreadcrumbComponent, BreadCrumbMenuComponent, BreadCrumbFlatplanToolbarComponent],
    exports: [BreadcrumbComponent],
})
export class BreadcrumbModule {}
