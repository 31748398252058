<div style="z-index: 4;" class="tableComponent row">
    <div class="col-lg-12">
        <div class="card">
            <form [formGroup]="form">
                <div class="card-body">
                    <fieldset class="p-2" style="border: 1px solid lightgrey;" id="twigFs">
                        <legend class="text-center text-primary">{{ "templates.config.twig.title" | translate }}
                            <button *ngIf="actualPreviews && actualPreviews.length>0" class="btn btn-primary btn-primary-filter btn-sm" type="button" (click)="showPreviews()">
                                <fa-icon [icon]="faTimes"></fa-icon>
                            </button>
                        </legend>
                        <div>
                            <h5 class="text-primary">{{ "templates.config.twig.or" | translate }}</h5>
                            <label for="twigId">{{ "templates.config.twig.name" | translate }}</label>
                            <select (change)="updateTwigValues($event.target.value)" formControlName="twigId"
                                class="form-control" id="twigId">
                                <option></option>
                                <option *ngFor="let twig of twigs" [value]="twig.id">{{ twig.name }}</option>
                            </select>
                        </div>
                        <h5 *ngIf="!updateMode" class="text-primary mt-3">{{ "templates.config.twig.create" | translate
                            }}</h5>
                        <h5 *ngIf="updateMode" class="text-primary mt-3">{{ "templates.config.twig.update" | translate
                            }}</h5>
                        <div class="form-row">
                            <div class="col-sm-6 form-group">
                                <label for="twigName">{{ "templates.config.twig.name" | translate }}</label>
                                <input formControlName="twigName" type="text" class="form-control" id="twigName">
                            </div>
                            <div class="col-sm-6 form-group">
                                <label for="exportType">{{ "templates.config.twig.exportType" | translate }}</label>
                                <select (change)="emptyPictoList()" formControlName="exportType" class="form-control" id="exportType">
                                    <option></option>
                                    <option *ngFor="let exportType of exportTypes" [value]="exportType.id">{{ exportType.name }}</option>
                                </select>
                            </div>
                        </div>
                        <div class="form-group">
                            <label for="twigName">{{ "templates.config.twig.content" | translate }}</label>
                            <ngs-code-editor style="height: 25rem;" theme="vs-dark" [codeModel]="codeModel"
                                [options]="options" (valueChanged)="onCodeChanged($event)"></ngs-code-editor>

                                <span *ngIf="!isTwigValid()" style="color: red;">
                                    {{ 'response.error.twig.create' | translate }}
                                </span>
                        </div>
                            <div class="row">
                                <div class="col" *ngIf="!updateMode; else elseBlock">
                                    <button *ngIf="form.value.exportType" (click)="validateContent(form.value.twigContent, form.value.exportType)" class="btn btn-block btn-secondary"><i class="pi pi-check-circle"></i> {{ 'templates.config.twig.verify' | translate }}</button>
                                </div>
                                <ng-template #elseBlock>
                                    <div class="col">
                                        <button *ngIf="form.value.exportType" (click)="validateContent(form.value.twigContent, form.value.exportType)" class="btn btn-block btn-info"><i class="pi pi-check-circle"></i> {{ 'templates.config.twig.verify' | translate }}</button>
                                    </div>
                                    <div class="col">
                                        <button *ngIf="updateMode" (click)="warningSave(true)" class="btn btn-block btn-primary" [disabled]="!isTwigValid() || codeModel.value == initialCode">{{ "templates.config.twig.updateButton" | translate }}</button>
                                    </div>
                                </ng-template>
                            </div>
                        <fieldset *ngIf="form.value.twigId" class="p-2" style="border: 1px solid lightgrey;" id="twigFs">
                            <legend class="text-center text-primary">{{ "templates.config.twig.updateVariables" | translate }}
                            </legend>
                            <button (click)="createVariable()" class="btn btn-primary">{{ "templates.config.twig.createVariable" | translate }}</button>

                            <table class="table table-striped mb-0">
                                <thead>
                                    <tr>
                                        <th style="border: 0;" class="text-center text-truncate" scope="col">
                                            {{ "model.productField" | translate }}
                                        </th>
                                        <th style="border: 0;" class="text-center text-truncate" scope="col">
                                            {{ "model.name" | translate }}
                                        </th>
                                        <th style="border: 0;" class="text-center text-truncate" scope="col">
                                            {{ "model.type" | translate }}
                                        </th>
                                        <th style="border: 0;" class="text-center text-truncate" scope="col">
                                            {{ "model.fieldset" | translate }}/{{ "model.list" | translate }}
                                        </th>
                                        <th style="border: 0;" class="text-center text-truncate" scope="col">
                                            {{ "model.variable" | translate }}
                                        </th>
                                        <th  style="border: 0;" class="text-center text-truncate" scope="col">
                                            {{ "general.action" | translate }}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody formArrayName="variables" *ngIf="hideVariables" class="p-2 mt-4"  [appSortable]="sortableJSOptions">
                                    <tr [formGroupName]="iVar"
                                        *ngFor="let variable of form.controls.variables.controls; let iVar = index">

                                        <td style="min-width: 2rem;">
                                            <center>
                                                <label class="switch switch-success mb-0">

                                                    <input type="checkbox" class="switch-input"
                                                    formControlName="forElement">
                                                    <span class="switch-slider"></span>
                                                </label>
                                            </center>
                                        </td>
                                        <td style="min-width: 10rem;">
                                            <input formControlName="name" type="text" class="form-control"  placeholder="nom de la variable" (change)="autoLinkVariableProject('name','variable',iVar)">
                                        </td>
                                        <td style="min-width: 10rem;" *ngIf="form.value.variables[iVar].inputs.length <= 0">
                                            <select class="form-control" formControlName="type" (change)="autoLinkVariableProject('type','variable',iVar)">
                                                <option
                                                    *ngFor="let fieldType of allFieldTypes(['productLink'])"
                                                    [value]="fieldType">{{ "fieldType." + fieldType | translate}}
                                                </option>
                                            </select>
                                        </td>
                                        <td style="min-width: 10rem;" *ngIf="form.value.variables[iVar].inputs.length > 0">
                                            <select class="form-control" formControlName="type">
                                                <option [value]="'fieldset'">{{ "fieldType.fieldset" | translate}}</option>
                                            </select>
                                        </td>
                                        <td style="min-width: 10rem;" *ngIf="form.value.variables[iVar].type === 'list' || form.value.variables[iVar].type === 'list multiple'">
                                            <button  class="btn btn-primary" (click)="updateListTwig(iVar)">{{ "templates.config.twig.updateList" | translate}}</button> <br/>
                                            <div syle="width: 100%" class="mt-2" [innerHTML]="getContentVariable(iVar)"></div>
                                        </td>
                                        <td [id]="'listVariableInput' + iVar" [class]="'fieldset-' + variable.value.id" style="min-width: 10rem;" *ngIf="form.value.variables[iVar].type === 'fieldset' || form.value.variables[iVar].type === 'productLink'" formArrayName="inputs">
                                            <div  [appSortable]="sortableJSOptionsInput">

                                                <tr [formGroupName]="j" *ngFor="let input of form.controls.variables.controls[iVar].controls.inputs.controls; let j = index; trackBy:trackByFn">
                                                    <td>
                                                        <input type="text" formControlName="name"  class="form-control"  placeholder="nom de l'input" (change)="autoLinkVariableProject('name','input',iVar, j)">
                                                    </td>
                                                    <td>
                                                        <select class="form-control" formControlName="type"  (change)="autoLinkVariableProject('type','input',iVar, j)">
                                                            <option
                                                                *ngFor="let fieldType of allFieldTypes(['productLink','fieldset'])"
                                                                [value]="fieldType">{{ "fieldType." + fieldType | translate}}
                                                            </option>
                                                        </select>
                                                    </td>
                                                    <td *ngIf="form.value.variables[iVar].inputs[j].type === 'list' || form.value.variables[iVar].inputs[j].type === 'list multiple'" class="d-flex row">
                                                        <button  class="btn btn-primary ml-1 mr-auto" (click)="updateListTwig(iVar,j)">{{ "templates.config.twig.updateList" | translate}}</button> <br/>
                                                        <span class="mt-2 col-12" [innerHTML]="getContentInput(iVar,j)"></span>
                                                    </td>
                                                    <td *ngIf="form.value.variables[iVar].inputs[j].type !== 'list' && form.value.variables[iVar].inputs[j].type !== 'list multiple'" ></td>
                                                    <td style="min-width: 10rem;">
                                                        <app-drop-down type="single" [list]="variableProjects['input'] && variableProjects['input'][form.value.variables[iVar].inputs[j].type] ? variableProjects['input'][form.value.variables[iVar].inputs[j].type] : []" formControlName="variableProject"> </app-drop-down>

                                                    </td>
                                                    <td>
                                                        <button class="ml-auto btn btn-danger"
                                                            (click)="deleteVariable(form.value.variables[iVar].inputs[j].name, form.value.variables[iVar].isUse, iVar, j)">
                                                            <fa-icon [icon]="faTrash"></fa-icon>
                                                        </button>
                                                        <button class="btn btn-primary ml-1 sortableInput mr-auto">
                                                            <fa-icon [icon]="faSort"></fa-icon>
                                                        </button>
                                                    </td>

                                                </tr>
                                            </div>
                                            <tr *ngIf="form.value.variables[iVar].type === 'fieldset' || form.value.variables[iVar].type === 'productLink'">
                                                <td>
                                                    <input type="button" value="Create Input" (click)="createInput(iVar)" class="btn btn-primary">
                                                </td>
                                            </tr>
                                        </td>
                                        <td *ngIf="form.value.variables[iVar].type !== 'fieldset' && form.value.variables[iVar].type !== 'productLink' && form.value.variables[iVar].type !== 'list' && form.value.variables[iVar].type !== 'list multiple'"></td>
                                        <td style="min-width: 10rem;">
                                            <!-- <app-drop-down class="drop_down" type="single" [list]="variableProjects['variable'] && variableProjects['variable'][form.value.variables[iVar].type] ? variableProjects['variable'][form.value.variables[iVar].type] : []" formControlName="variableProject"> </app-drop-down>       -->
                                            <ng-multiselect-dropdown
                                            [settings]="dropdownSettingsSingle"
                                            [data]="variableProjects['variable'] && variableProjects['variable'][form.value.variables[iVar].type] ? variableProjects['variable'][form.value.variables[iVar].type] : []"
                                            formControlName="variableProject"
                                        >
                                        </ng-multiselect-dropdown>
                                        </td>
                                        <td>
                                            <button class="ml-auto btn btn-danger"
                                                (click)="deleteVariable(form.value.variables[iVar].name, form.value.variables[iVar].isUse, iVar, null)">
                                                <fa-icon [icon]="faTrash"></fa-icon>
                                            </button>
                                            <button class="btn btn-primary ml-1 sortable mr-auto">
                                                <fa-icon [icon]="faSort"></fa-icon>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <span *ngIf="variablesInvalid()" style="color: red;">
                                {{ "templates.config.twig.errorVariable" | translate }}
                            </span>
                            <button type="button" [disabled]="!isTwigValid() || variablesInvalid() || codeModel.value != initialCode" (click)="updateVariables(true)" *ngIf="updateMode"
                                class="btn btn-block btn-primary">{{ "templates.config.twig.updateVariableButton" | translate
                                }}</button>
                        </fieldset>




                    </fieldset>

                    <fieldset class="p-2 mt-4" style="border: 1px solid lightgrey;" id="templateFs">
                        <legend class="text-center text-primary">{{ "templates.config.template.title" | translate }}
                        </legend>

                        <div class="form-row">
                            <div class="form-group col-sm-4">
                                <label for="templateName">{{ "templates.config.template.name" | translate }}</label>
                                <input formControlName="templateName" type="text" class="form-control"
                                    id="templateName">
                            </div>
                            <div class="form-group col-sm-4">
                                <label for="regeneratedAt">{{ "templates.config.template.regenerated" | translate
                                    }}</label>
                                <input formControlName="regeneratedAt" type="date" class="form-control"
                                    id="regeneratedAt">
                            </div>
                            <div class="form-group col-sm-4">
                                <label for="suffix">{{ "templates.config.template.suffix" | translate }}</label>
                                <input formControlName="suffix" type="text" class="form-control" id="suffix">
                            </div>
                            <div class="form-group col-sm-4" *ngIf="isFontPathRequired()">
                                <label for="fontPath">{{ "templates.config.template.fontPath" | translate }}</label>
                                <input formControlName="fontPath" type="text" class="form-control" id="fontPath"
                                    placeholder="{{fontPathPlaceholder}}">
                            </div>
                        </div>
                        <div class="form-row" *ngIf="isNbPageAndProductRequired()">
                            <div class="form-group col-sm-6">
                                <label for="numberPage">{{ "templates.config.template.numberPage" | translate }}</label>
                                <input formControlName="numberPage" type="number" class="form-control" id="numberPage">
                            </div>
                            <div class="form-group col-sm-6">
                                <label for="numberElement">{{ "templates.config.template.numberElement" | translate
                                    }}</label>
                                <input formControlName="numberElement" type="number" class="form-control"
                                    id="numberElement">
                            </div>
                        </div>

                        <div class="form-row">
                            <div class="form-group col-sm-4" *ngIf="isFileRequired()">
                                <label for="fileJsx">{{ "templates.config.template.fileJsx" | translate }}</label>
                                <input formControlName="fileJsx" type="file" accept=".jsx" class="form-control"
                                    id="fileJsx" (change)="handleFileJsxInput($event.target.files)">
                                <div *ngIf="actualJsx">
                                    <small>{{ "templates.config.template.replace"| translate }} : </small>
                                    <ul *ngIf="actualJsx.length > 0">
                                        <li>
                                            <a href="#" (click)="downloadDedicatedFile(actualJsx[0].fileTypeName, actualJsx[0].originalName);">{{ actualJsx[0].originalName }}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="form-group col-sm-4" *ngIf="isFileRequired()">
                                <label for="fileIndt">{{ "templates.config.template.fileIndt" | translate }}</label>
                                <input formControlName="fileIndt" type="file" accept=".indt" class="form-control" id="fileIndt" (change)="handleFileIndtInput($event.target.files)">
                                <div *ngIf="actualIndt">
                                    <small>{{ "templates.config.template.replace"| translate }} : </small>
                                    <ul *ngIf="actualIndt.length > 0">
                                        <li>
                                            <a href="#" (click)="downloadDedicatedFile(actualIndt[0].fileTypeName, actualIndt[0].originalName);">{{ actualIndt[0].originalName }}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div class="form-group col-sm-4">
                                <label for="previewsTemplate">{{ "templates.config.template.previewsTemplate" | translate }}</label>
                                <input formControlName="previewsTemplate" multiple type="file" accept=".png, .jpg, .jpeg, .gif" class="form-control" id="previewsTemplate" (change)="handleFilesPreviewInput($event.target.files)">
                                <div *ngIf="actualPreviews">
                                    <small>{{ "templates.config.template.replaceMulti"| translate }} : </small>
                                    <ul *ngIf="actualPreviews.length > 0">
                                        <li *ngFor="let preview of actualPreviews; let i = index">
                                            <a href="#" (click)="downloadDedicatedFile(preview.fileTypeName, preview.originalName);">{{ preview.originalName }}</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div class="form-row" *ngIf="isFileRequired()">
                            <div class="form-group col-12">
                                <label>{{ "templates.config.template.picto" | translate }}</label>
                                <app-drop-down [type]="'multi'" [list]="pictoList" formControlName="pictos">
                                </app-drop-down>
                            </div>
                        </div>

                        <div class="row px-3">
                            <div class="mx-1" *ngFor="let picto of form.value.pictos">
                                <img style="height: 5rem; width: 7rem" [src]="getPictoThumb(picto.id)">
                            </div>
                        </div>

                        <div class="form-row" *ngIf="isCSSJSFileRequired()">
                            <div class="form-group col-sm-6">
                                <label for="filesCSS">{{ "templates.config.template.filesCSS" | translate }}</label>
                                <input formControlName="filesCSS" type="file" accept=".css" multiple
                                    class="form-control" id="filesCSS"
                                    (change)="handlefilesCSSInput($event.target.files)">
                                <small *ngIf="updateMode && actualJsx">{{ "templates.config.template.replaceMulti"
                                    | translate }} : {{ actualJsx[0].originalName }}</small>
                                <ul *ngIf="filesCSS">
                                    <li *ngFor="let file of filesCSS">{{file.name}}</li>
                                </ul>
                                <ul *ngIf="!filesCSS">
                                    <li *ngFor="let file of dedicatedCSSFiles">{{file.originalName}}</li>
                                </ul>
                            </div>
                            <div class="form-group col-sm-6">
                                <label for="filesJS">{{ "templates.config.template.filesJS" | translate }}</label>
                                <input formControlName="filesJS" type="file" accept=".js" multiple class="form-control"
                                    id="filesJS" (change)="handlefilesJSInput($event.target.files)">
                                <small *ngIf="updateMode && actualIndt">{{ "templates.config.template.replaceMulti"
                                    | translate }} : {{ actualIndt[0].originalName }}</small>
                                <ul *ngIf="filesJS">
                                    <li *ngFor="let file of filesJS">{{file.name}}</li>
                                </ul>
                                <ul *ngIf="!filesJS">
                                    <li *ngFor="let file of dedicatedJSFiles">{{file.originalName}}</li>
                                </ul>
                            </div>
                        </div>

                        <hr />

                        <div  class="form-group drop_down_var_proj">
                            <label class="h5 text-primary text-center w-100" for="options">{{
                                "templates.config.template.options.title" | translate }}</label>
                            <fa-icon class="float-right" [icon]="faPlus" (click)="addOption()"></fa-icon>
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th>{{ "templates.config.template.options.name" | translate }}</th>
                                        <th>{{ "templates.config.template.options.twigVar" | translate }}</th>
                                        <th>{{ "templates.config.template.options.type" | translate }}</th>
                                        <th>{{ "templates.config.template.options.values" | translate }}</th>
                                        <th>{{ "model.variable" | translate }}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody formArrayName="options">
                                    <tr [formGroupName]="i"
                                        *ngFor="let option of form.value.options; let i = index; trackBy:trackByFn">

                                        <td>
                                            <input formControlName="name" type="text" class="form-control"  (change)="autoLinkVariableProject('name','option',i)"
                                                [id]="'optionName' + i">
                                        </td>
                                        <td>
                                            <input formControlName="twigVar" type="text" class="form-control"
                                                [id]="'optionTwigVar' + i">
                                        </td>
                                        <td>
                                            <select formControlName="type" type="text" class="form-control"   (change)="autoLinkVariableProject('type','option',i)"
                                                [id]="'optionType' + i">
                                                <option></option>
                                                <option value="text">{{ "general.text" | translate }}</option>
                                                <option value="textarea">{{ "general.textarea" | translate }}</option>
                                                <option value="number">{{ "general.number" | translate }}</option>
                                                <option value="boolean">{{ "general.boolean" | translate }}</option>
                                                <option value="list">{{ "general.list" | translate }}</option>
                                                <option value="color">{{ "general.color" | translate }}</option>
                                            </select>
                                        </td>
                                        <td *ngIf="form.value.options[i].type === 'list'" class="d-flex">
                                            <input type="text" class="form-control" style="width: 30%;"
                                                [id]="'newValue' + i">
                                            <fa-icon class="mt-2 ml-2 mr-2" [icon]="faPlus"
                                                (click)="addValueToOption(i)"></fa-icon>
                                            <span class="mt-2 text-truncate" [innerHTML]="getContent(i)"></span>
                                        </td>
                                        <td *ngIf="form.value.options[i].type !== 'list'">{{
                                            "templates.config.template.options.noValue" | translate }}
                                        </td>
                                        <td style="min-width: 10rem;">
                                            <app-drop-down type="single" [list]="variableProjects['option'] && variableProjects['option'][form.value.options[i].type] ? variableProjects['option'][form.value.options[i].type] : []" formControlName="variableProject"> </app-drop-down>
                                        </td>
                                        <td>
                                            <fa-icon [icon]="faMinus" (click)="removeOption(i)"></fa-icon>
                                        </td>
                                    </tr>
                                    <tr *ngIf="form.value.options.length === 0">
                                        <td class="text-center border-bottom font-weight-bold" colspan="6">{{
                                            "general.no.data" | translate }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <hr />

                        <div class="form-group drop_down_var_proj">
                            <label class="h5 text-primary text-center w-100"
                                for="positionsMedia">{{ "templates.config.template.positions.title" | translate
                                }}</label>
                            <fa-icon class="float-right" [icon]="faPlus" (click)="addPositionMedia()"></fa-icon>
                            <table class="table table-striped">
                                <thead>
                                    <tr>
                                        <th>{{ "model.productField" | translate }}</th>
                                        <th>{{ "templates.config.template.positions.name" | translate }}</th>
                                        <th>{{ "templates.config.template.positions.marker" | translate }}</th>
                                        <th>{{ "templates.config.template.positions.width" | translate }}</th>
                                        <th>{{ "templates.config.template.positions.height" | translate }}</th>
                                        <th>{{ "templates.config.template.positions.optional" | translate }}</th>
                                        <th>{{ "templates.config.template.positions.adjustment.title" | translate }}</th>
                                        <th>{{ "model.variable" | translate }}</th>
                                        <th></th>
                                    </tr>
                                </thead>
                                <tbody formArrayName="positionMedias">
                                    <tr [formGroupName]="i"
                                        *ngFor="let position of form.value.positionMedias; let i = index; trackBy:trackByFn">
                                        <td style="min-width: 2rem;">
                                            <center>
                                                <label class="switch switch-success mb-0">

                                                    <input type="checkbox" class="switch-input"
                                                    formControlName="forElement">
                                                    <span class="switch-slider"></span>
                                                </label>
                                            </center>
                                        </td>
                                        <td>
                                            <input formControlName="name" type="text" class="form-control"
                                                [id]="'positionName' + i" (change)="autoLinkVariableProject('media',i)">
                                        </td>
                                        <td>
                                            <input formControlName="marker" type="text" class="form-control"
                                                [id]="'positionMarker' + i">
                                        </td>
                                        <td>
                                            <input formControlName="width" type="number" step="0.1" class="form-control"
                                                [id]="'positionWidth' + i">
                                        </td>
                                        <td>
                                            <input formControlName="height" type="number" step="0.1"
                                                class="form-control" [id]="'positionHeight' + i">
                                        </td>
                                        <td>
                                            <div class="form-group row">
                                                <div class="col-sm-10">
                                                    <label class="switch switch-lg switch-outline-primary-alt">
                                                        <input [id]="'positionOptional' + i" formControlName="optional"
                                                            type="checkbox" floating-label class="switch-input" />
                                                        <span class="switch-slider"></span>
                                                    </label>
                                                </div>
                                            </div>
                                        </td>
                                        <td>
                                            <select formControlName="adjustmentOption" class="form-control"
                                                [id]="'adjustmentOption' + i">
                                                <option></option>
                                                <option value="0">{{
                                                    "templates.config.template.positions.adjustment.fill_proportionnally"
                                                    | translate }}</option>
                                                <option value="1">{{
                                                    "templates.config.template.positions.adjustment.center_content"
                                                    | translate }}</option>
                                                <option value="2">{{
                                                    "templates.config.template.positions.adjustment.proportionnally"
                                                    | translate }}</option>
                                                <option value="3">{{
                                                    "templates.config.template.positions.adjustment.frame_to_content"
                                                    | translate }}</option>
                                                <option value="4">{{
                                                    "templates.config.template.positions.adjustment.content_to_frame"
                                                    | translate }}</option>
                                            </select>
                                        </td>
                                        <td style="min-width: 10rem;">
                                            <app-drop-down type="single" [list]="variableProjects['media'] ? variableProjects['media'] : []" formControlName="variableProject"> </app-drop-down>
                                        </td>
                                        <td>
                                            <fa-icon [icon]="faMinus" (click)="removeMedia(i)"></fa-icon>
                                        </td>
                                    </tr>
                                    <tr *ngIf="form.value.positionMedias.length === 0">
                                        <td class="text-center border-bottom font-weight-bold" colspan="9">{{
                                            "general.no.data" | translate }}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </fieldset>
                </div>

                <div class="card-footer">
                    <div class="row">
                        <div class="col-8">
                            <button class="btn btn-danger" type="button" (click)="onBack()">
                                <fa-icon [icon]="faTimesCircle"></fa-icon> {{ 'general.close' | translate | titlecase }}
                            </button>
                        </div>
                        <div class="col-4 text-right">
                            <div class="btn-group">
                                <button [disabled]="form.invalid" *ngIf="updateMode" class="btn btn-info" (click)="onSubmit(true)">
                                    <fa-icon [icon]="faSave"></fa-icon>
                                    <span>
                                        {{ 'general.update_stay' | translate | titlecase }}
                                    </span>
                                </button>
                                <button [disabled]="form.invalid" type="submit" class="btn btn-primary" (click)="onSubmit()">
                                    <fa-icon [icon]="faSave"></fa-icon>
                                    <span *ngIf="!updateMode">
                                        {{ 'general.create' | translate | titlecase }}
                                    </span>
                                    <span *ngIf="updateMode">
                                        {{ 'general.update' | translate | titlecase }}
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
</div>

<swal #saveTwigModal [showCancelButton]="true" [cancelButtonColor]="'#FF0000'" [showConfirmButton]="false" title="{{ 'general.modif.saveTwig' | translate }}">
    <div *swalPortal class="row">
    <button class="btn btn-primary col-5" (click)="updateTwigTemplate('create')">
    {{ 'button.oneTemplate' | translate }}
    </button>
    <button class="btn btn-primary offset-2 col-5" (click)="updateTwigTemplate('update')">
    {{ 'button.allTemplate' | translate }}
    </button>
    </div>
    </swal>

    <swal #updateListModal [showCancelButton]="true" [cancelButtonColor]="'#f86c6b'" [showConfirmButton]="true" [confirmButtonColor]="'#4eb8aa'" title="{{ 'general.modif.saveTwig' | translate }}">
    <div *swalPortal class="row">
    <table>
    <thead>
    <tr>
    <th>{{ 'templates.config.template.options.values' | translate }}</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let value of currentList; let ind = index; trackBy:trackByFn">
    <td>
    <input type="text" class="inputList" [value]="value" />
    </td>
    <td>
    <button class="ml-auto btn btn-danger"
    (click)="deleteValueList(ind)">
    <fa-icon [icon]="faTrash"></fa-icon>
    </button>
    </td>
    </tr>
    <tr>
    <fa-icon class="mt-2 ml-2 mr-2" [icon]="faPlus"
    (click)="addValueToList()"></fa-icon>
    </tr>
    </tbody>
    </table>
    </div>
    </swal>

    <swal #warningTwigSaveModal [showCancelButton]="true" [cancelButtonColor]="'#FF0000'" [showConfirmButton]="true" [confirmButtonColor]="'#00FF00'" title="{{ 'general.warning' | translate }}">
    <div *swalPortal class="row">
    <h3>{{ 'projects.template.warningTwigSave' | translate }}</h3>
    <span>
    <h2>{{ projectsNameSelectTemplate }}</h2>
    </span>
    </div>
    </swal>

    <swal #warningVariableSaveModal [showCancelButton]="true" [cancelButtonColor]="'#FF0000'" [showConfirmButton]="true" [confirmButtonColor]="'#00FF00'" title="{{ 'general.warning' | translate }}">
    <div *swalPortal class="row">
    <h3>{{ 'projects.template.warningVariableSave' | translate }}</h3>
    <span>
    <h2>{{ deleteUseVariableArray }}</h2>
    </span>
    </div>
    </swal>


