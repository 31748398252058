import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { faPlusSquare } from "@fortawesome/free-regular-svg-icons";
import { faBars, faFilter, faHome } from "@fortawesome/free-solid-svg-icons";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { ViewButtonDef } from "app/shared/components/view-buttons";
import { BreadcrumbDef } from "app/shared/models";
import { UnsubscribePipe } from "app/shared/pipes";
import { Subscription } from "rxjs";

@Component({
    selector: "app-breadcrumb",
    templateUrl: "./breadcrumb.component.html",
    styleUrls: ["./breadcrumb.component.scss"],
})
export class BreadcrumbComponent implements OnInit, OnDestroy {
    @Input() sidebarButton: boolean;
    @Input() searchInput: boolean;
    @Input() actionButton: boolean;
    @Input() importButton: boolean;
    @Input() actionButtonIcon: string;
    @Input() breadcrumbDef: BreadcrumbDef;
    @Input() buttonTitle: string;
    @Input() importButtonTitle: string;
    @Input() generalActionButtons: boolean;
    @Input() generalActionButtonsActions: any;
    @Input() viewButtons: boolean;
    @Input() viewButtonsActions: any;
    @Input() viewButtonsDefault: ViewButtonDef;
    @Input() flatplanTooblar: Boolean;
    @Input() buttonId: string;
    searchValue: string;

    readonly defaultButtonTitle = "create";
    /**
     * Font Awesome
     */
    readonly faBars = faBars;
    readonly faHome = faHome;
    readonly faPlusSquare = faPlusSquare;
    readonly faFilter = faFilter;

    private _serviceSubscriptions: Subscription[] = [];

    constructor(private _unsubscribePipe: UnsubscribePipe, private _layoutService: LayoutService) {}

    ngOnInit(): void {
        this.initSubscriptions();
    }

    ngOnDestroy(): void {
        this._unsubscribePipe.transform(this._serviceSubscriptions);
    }

    onButtonclick() {
        this._layoutService.actionButton.click();
    }

    onGeneralActionButtonclick(event) {
        this._layoutService.generalActionButtons.click(event);
    }

    onViewButtonclick(event) {
        this._layoutService.viewButtons.click(event);
    }

    onSearch(event) {
        this._layoutService.searchInput.filter(event.srcElement.value);
    }

    onImportClick() {
        this._layoutService.importButton.click();
    }

    private initSubscriptions() {
        this._serviceSubscriptions = [
            this._layoutService.sidebar.enable$.subscribe((value) => (this.sidebarButton = value)),
            this._layoutService.searchInput.enable$.subscribe((value) => (this.searchInput = value)),
            this._layoutService.searchInput.currentValue$.subscribe((value) => (this.searchValue = value)),
            this._layoutService.actionButton.enabled$.subscribe((enabled) => (this.actionButton = enabled)),
            this._layoutService.actionButton.title$.subscribe((title) => (this.buttonTitle = title || this.defaultButtonTitle)),
            this._layoutService.actionButton.id$.subscribe((id) => (this.buttonId = id)),
            this._layoutService.actionButton.icon$.subscribe((icon) => (this.actionButtonIcon = icon)),
            this._layoutService.importButton.enabled$.subscribe((enabled) => (this.importButton = enabled)),
            this._layoutService.importButton.title$.subscribe((title) => (this.importButtonTitle = title)),

            this._layoutService.breadcrumb.path$.subscribe((breadCrumb) => (this.breadcrumbDef = breadCrumb)),

            this._layoutService.generalActionButtons.enabled$.subscribe((enabled) => (this.generalActionButtons = enabled)),
            this._layoutService.generalActionButtons.actions$.subscribe((actions) => (this.generalActionButtonsActions = actions)),
            this._layoutService.viewButtons.enabled$.subscribe((enabled) => (this.viewButtons = enabled)),
            this._layoutService.viewButtons.default$.subscribe((viewButtonsDefault) => (this.viewButtonsDefault = viewButtonsDefault)),
            this._layoutService.viewButtons.actions$.subscribe((viewActions) => (this.viewButtonsActions = viewActions)),

            this._layoutService.flatplanToolbar.enabled$.subscribe((value) => (this.flatplanTooblar = value)),
        ];
    }
}
