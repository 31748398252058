<div style="position: absolute; top: 8%; right: 2%; z-index: 9000;">
    <div *ngFor="let message of messages">
        <div class="toast show" style="min-width: 12rem;" role="alert" aria-live="assertive" aria-atomic="true">
            <div class="toast-header" style="padding-top: .35rem; padding-bottom: .35rem;" [ngClass]="'bg-' + message.type">
                <ng-container *ngIf="message.type === 'success'">
                    <fa-icon class="text-white mr-2" [icon]="faCheck"></fa-icon>
                    <strong class="mr-auto text-white">{{ "general.success" | translate}}</strong>
                </ng-container>
                <ng-container *ngIf="message.type === 'danger'">
                    <fa-icon class="text-white mr-2" [icon]="faDanger"></fa-icon>
                    <strong class="mr-auto text-white">{{ "general.error" | translate}}</strong>
                </ng-container>
                <ng-container *ngIf="message.type === 'info'">
                    <fa-icon class="text-white mr-2" [icon]="faWarning"></fa-icon>
                    <strong class="mr-auto text-white">{{ "general.info" | translate}}</strong>
                </ng-container>

            </div>
            <div class="toast-body font-weight-bolder" [ngClass]="'text-' + message.type" [innerHtml]="message.message"></div>
        </div>
    </div>
</div>
