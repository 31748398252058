<div   *ngIf="!showCode; else showCodeTemplate" class="editor NgxEditor__Wrapper">
    <form class="ngx-form" [formGroup]="form" *ngIf="(isEditorActive || keepActive) && !disabled">
        <ngx-editor-menu [customMenuRef]="customMenu" [editor]="editor" [toolbar]="toolbar" >
        </ngx-editor-menu>
        <ngx-editor    id="{{fieldId}}" (onEditorCreated)="setFocus($event)" (ngModelChange)="updateWord()" [outputFormat]="'html'" [editor]="editor" placeholder="{{ 'editor.enter' | translate }}" formControlName="editorContent" (focusOut)="emitOut()">
        </ngx-editor>
    </form>
    <ng-template #customMenu>
        <div class="NgxEditor__Seperator"></div>
        <ng-container>
            <div class="NgxEditor__MenuItem NgxEditor__MenuItem--IconContainer pointer" (click)="showSource()">
                <i class="fas fa-code ml-1 mr-1"></i>
            </div>
        </ng-container>
        <div class="NgxEditor__Seperator"></div>
        <ng-container *ngIf="pluginActivated">
            <div class="NgxEditor__MenuItem--IconContainer pointer" >
                <span class="ml-1" style="font-size: 1rem;" (click)="insertTrident()">&Psi;</span>
            </div>
            <div class="NgxEditor__MenuItem--IconContainer pointer" >
                <span class="ml-1" style="font-size: 1rem;" (click)="insertCarre()">■</span>
            </div>
            <div class="NgxEditor__MenuItem--IconContainer pointer" >
                <span class="ml-1" style="font-size: 1rem;" (click)="insertTriangle()">▲</span>
            </div>
            <div class="NgxEditor__MenuItem--IconContainer pointer" >
                <span class="ml-1" style="font-size: 1rem;" (click)="insertInsec()">→</span>
            </div>
            <div class="NgxEditor__MenuItem--IconContainer pointer" >
                <span class="ml-1" style="font-size: 1rem;" (click)="insertBulle()">&bull;</span>
            </div>
            <div class="NgxEditor__MenuItem--IconContainer pointer" >
                <span class="ml-1" style="font-size: 1rem;" (click)="insertRetour()">¬</span>
            </div>
            <div class="NgxEditor__MenuItem--IconContainer pointer" >
                <span class="ml-1" style="font-size: 1rem;" (click)="insertSautColonne()">&#8660;</span>
            </div>
        </ng-container>



        <div class="NgxEditor__MenuItem--IconContainer pointer ml-auto" *ngIf="showHistory" (click)="historyClick.emit()">
            <svg height="20" width="25" class="mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
        </div>
        <div class="NgxEditor__MenuItem--IconContainer pointer" [ngClass]="{ 'ml-auto': !showHistory }" *ngIf="!keepActive" (click)="closeEditor()">
            <svg height="20" width="25" class="text-danger mt-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="3" d="M6 18L18 6M6 6l12 12"></path></svg>
        </div>

    </ng-template>

    <div *ngIf="isEditorActive || keepActive" class="bg-secondary pt-1 px-2 border-top border-secondary text-black mb-0">
        <span class="ml-auto">
            {{ "editor.word" | translate }} : {{ getWordsNumber() }}, {{ "editor.character" | translate }} : {{ getCharactersNumber() }}
        </span>
    </div>
    <div [ngStyle]="disabled ? { 'background-color': '#e4e7ea' } : ''" class="col-12 d-block overflow-auto pointer mb-0" style="min-height: 7rem; max-height: 7rem;" [innerHTML]="getContent()" *ngIf="(!isEditorActive && !keepActive) || disabled" (click)="openEditor()"></div>
</div>

<ng-template #showCodeTemplate>
    <div class="editor NgxEditor__Wrapper">
        <!-- <textarea [formControl]="textAreaFormControl" [value]="form.value.editorContent" [disabled]="true" style="width:100%; border:none; height:125px"></textarea> -->
        <ngx-editor-menu [customMenuRef]="customMenu" [editor]="editor" [toolbar]="toolbar" >
        </ngx-editor-menu>
        <textarea  (change)="updateSource($event)"  [value]="form.value.editorContent" [disabled]="false" style="width:100%; border:none; height:125px"></textarea>
        <ng-template #customMenu>
            <div class="NgxEditor__Seperator"></div>
            <ng-container>
                <div class="NgxEditor__MenuItem NgxEditor__MenuItem--IconContainer pointer" (click)="showSource()">
                    <i class="fas fa-paragraph ml-1 mr-1"></i>
                </div>
            </ng-container>
            <div class="NgxEditor__Seperator"></div>
        </ng-template>
    </div>
</ng-template>

