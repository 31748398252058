import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from "@angular/core";
import { FormArray, FormBuilder, FormGroup, Validators } from "@angular/forms";
import { faPlusSquare } from "@fortawesome/free-regular-svg-icons";
import { faCheck, faTimes, faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { AuthService } from "app/core/services/admin/auth/auth.service";
import { DataService } from "app/core/services/global/data/data.service";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { TableService } from "app/core/services/global/table/table.service";
import { ToastService } from "app/core/services/global/toast/toast.service";
import { TreeService } from "app/core/services/global/tree/tree.service";
import { ElementTypeService } from "app/core/services/pim/element-type.service";
import { ElementService } from "app/core/services/pim/element.service";
import { ThumbnailsService } from "app/core/services/thumbnails/thumbnails.service";
import { ACL } from "app/shared/models/acl";
import { saveAs } from "file-saver";
import { ConfirmationService, LazyLoadEvent, TreeNode } from "primeng-lts/api";
import { Table } from "primeng-lts/table";
import { environment } from "src/environments";
import * as xlsx from "xlsx";
import { ActionEvent, ActionType } from "../action-buttons";
import { TreeTagComponent } from "../massAction/tree/tag/treeTag.component";

@Component({
    selector: "app-datatable",
    templateUrl: "./datatable.component.html",
})
export class DatatableComponent implements OnInit {
    @Input() tableTitle: string;
    @Input() actions: ActionType[];
    @Input() paginator: boolean = true; // Display pagination
    @Input() rows: number = 10; // Define the number of rows per page
    @Input() rowsPerPageOptions: number[] = [10, 25, 50, 100, 1000]; // Define a range of how many records to load
    @Input() showCurrentPageReport: boolean = true; // Display 'currentPageReportTemplate' set in HTML
    @Input() searchBar: boolean = true; // Display the serach bar
    @Input() searchDataParameter: string; // Display the entry in search input
    @Input() scrollable: boolean = true; // Display scroll in table and keep columns sticky
    @Input() expandableRow: boolean = false; // Display row expansion
    @Input() dataKey: string = "id"; // Property to uniquely identify a row
    @Input() selectColumns: boolean = true; // Display a multiselect to show/hide columns
    @Input() selectRows: boolean = false; // Display a multiselect to select rows (works with 'exportRows')
    @Input() exportRows: boolean = false; // Display export buttons (works with 'selectRows')

    @Input() affLink: boolean = false; // Afficher le lien dans la premiere colone du tableau
    @Input() linkColName: string; // Nom de la colonne sur laquelle afficher le lien
    @Input() baseLink: string; // Base du lien a afficher dans la premiere colonne

    @Input() tableRows: any[] = [];
    @Input() tableColumns: any[] = [];
    @Input() totalRecords: number = 0;
    @Input() recordsFiltered: number = 0;
    @Input() globalFilterFields: string[] = []; // Define which columns can be use to search data
    @Input() _selectedColumns: any[] = []; // Define which columns are selected
    @Input() exportFilename: string = "";
    @Input() filterable: boolean = false;
    @Input() filters: any = {}; // An object that contains properties with data linked to columns
    @Input() subData: any = {}; // Contains the sub-data of sub-method displayed in expanded rows
    @Input() subDataType: string = ""; // Let to control and displayed subData with right structure
    @Input() preFilter: any = {};
    @Input() defaultSortOrder: number = -1;
    @Input() sortOrder: number = -1;

    @Input() shortPaginatorIsFirstPage: boolean = true;
    @Input() shortPaginatorIsLastPage: boolean = false;
    @Input() first: number = 0;

    @Input() showAdvancedSearch: boolean = false; // Display advanced search checkox

    _selectedRows: any[] = []; // Define which rows are selected
    reloadRowExpansion: boolean = false;
    _exportColumns: any[] = [];
    nodes = {}; // Contains all filtered nodes by filer type
    nodeArray: TreeNode[] = []; // Contains all selected nodes with their references for filtering
    tooltipZIndex: number = 9999;
    // @TODO: Keep row selection between pages
    // tmpSelectedRows: any = []; // Contains all selected rows between pages
    massTagNode: TreeNode[] = []; // Contains all selected nodes with their references for mass action
    massTagAdd: boolean = true; // Determine state mode
    massTagActive: boolean = false; // Determine if we reload datatable data when modal is hide

    strictMode: boolean = false; // Use to search a strict value
    searchOnFieldValue: boolean = false; // Use to search on element value and list value
    searchOnMedia: boolean = false; // Use to search on DAM medias
    searchOnArchived: boolean = false; // Use in project to display archived projects

    @Output() action: EventEmitter<ActionEvent> = new EventEmitter();
    @Output() lazyEvent: EventEmitter<LazyLoadEvent> = new EventEmitter();
    @Output() subDataCall: EventEmitter<number | null> = new EventEmitter();
    @Output() massTagStart: EventEmitter<any> = new EventEmitter(); // Emitted when add or remove tag
    @Output() massTagEnd: EventEmitter<any> = new EventEmitter(); // Emitted when focus out modal
    @Output() nodeSelected: EventEmitter<boolean> = new EventEmitter(); // Used to know in prefilter case if selected or not
    @Output() shortPaginatorPrev: EventEmitter<any> = new EventEmitter();
    @Output() shortPaginatorReset: EventEmitter<any> = new EventEmitter();
    @Output() shortPaginatorNext: EventEmitter<any> = new EventEmitter();
    @Output() changeAdvancedSearch: EventEmitter<any> = new EventEmitter();
    @Output() clearFilters: EventEmitter<any> = new EventEmitter();
    @Output() downloadArchive: EventEmitter<any> = new EventEmitter(); // Used with project and export to download generation archive
    @Output() removeElements: EventEmitter<any> = new EventEmitter(); // Emitted when elements are removed

    @ViewChild("search") searchInput: ElementRef;
    @ViewChild("treeTag") treeTag: TreeTagComponent;
    @ViewChild("massUpdate") massUpdate: SwalComponent;

    damUrl = environment.damUrl;

    eventValue = null; // Used to filter only on button click
    tagAsSalabilityIndicator: boolean;
    massUpdateData = [];
    massUpdateForm: FormGroup;

    readonly faCheck = faCheck;
    readonly faTimes = faTimes;
    readonly faPlusSquare = faPlusSquare;
    readonly faTrash = faTrashAlt;

    private acl: ACL;

    private selectableDataToReset = [];
    private clearMassUpdate: boolean = true;
    dissmissedInfo: boolean = false;
    dissmissedWarning: boolean = false;

    constructor(
        private _translateService: TranslateService,
        private _tableService: TableService,
        private _treeService: TreeService,
        private _dataService: DataService,
        private _thumbnailsService: ThumbnailsService,
        private _elementService: ElementService,
        private _swalModalService: SwalModalService,
        private _elementTypeService: ElementTypeService,
        private fb: FormBuilder,
        private _toasterService: ToastService,
        private _authService: AuthService,
        private _confirmationService: ConfirmationService
    ) {}

    ngOnInit(): void {
        this.acl = this._authService.getUserAclFromToken();

        this.tagAsSalabilityIndicator = environment.tagAsSalabilityIndicator;

        if (undefined !== this.preFilter && this.preFilter.hasOwnProperty("type") && this.preFilter.data.length && this.preFilter.data[0].hasOwnProperty("id")) {
            if (this.preFilter.hasOwnProperty("tree") && this.preFilter.tree) {
                this.treeSelectFilter({ node: this.preFilter.data }, true, this.preFilter.type);
                this.nodeArray = this.preFilter.data;

                this.changeNodesReference(this.nodes[this.preFilter.type]);
            } else {
                this.nodes[this.preFilter.type] = this.preFilter.data;
            }
        }

        this.massUpdateForm = this.fb.group({
            models: this.fb.array([]),
        });
    }

    preLoad(): void {
        this.reloadRowExpansion = true;
    }

    onActionClick(action: ActionType, rowId: number) {
        if (action === 4) {
            // Delete case
            this.removeFromSelection(rowId);
        }

        this.action.emit({ action, rowId });
    }

    controlValue(value: string) {
        if ("workflowEnd" === value) {
            value = this._translateService.instant("workflowEnd");
        } else if (null === value) {
            value = "";
        }

        return value;
    }

    onExpandAction(expanded: boolean, objectId: number) {
        if (expanded) {
            this.subDataCall.emit(objectId);
            this.reloadRowExpansion = false;
        } else {
            this.subDataCall.emit(null);
        }
    }

    @Input() get selectedColumns(): any[] {
        return this._selectedColumns;
    }

    set selectedColumns(val: any[]) {
        // Restore original order
        this._selectedColumns = this.tableColumns.filter((col) => val.includes(col));
    }

    exportPdf() {
        this.setExportData();

        let ids = this._selectedRows.map((element) => {
            return element.id;
        });

        this._elementService.exportPdf(ids).subscribe((res) => {
            this._dataService.downloadFile(res.data.blob, res.data.fileName);

            this._exportColumns = [];
        });
    }

    exportExcel() {
        this.setExportData();

        let newSelectedRows = [];

        this._selectedRows.forEach((row) => {
            let newRow = {};

            Object.entries(row).map(([key, value]) => {
                const column = this._exportColumns.find((obj) => obj.dataKey === key);
                const newKey = undefined !== column ? column.title : null;

                if (null !== newKey) {
                    if (Array.isArray(value)) {
                        newRow[newKey] = value.join();
                    } else {
                        newRow[newKey] = value;
                    }
                }

                return newRow;
            });

            if (newRow.hasOwnProperty("ID")) {
                const rowOrder = { ID: null };
                newRow = Object.assign(rowOrder, newRow); // Re-order ID column to first place
            }

            if (Object.keys(newRow).length) {
                newSelectedRows.push(newRow);
            }
        });

        const worksheet = xlsx.utils.json_to_sheet(newSelectedRows);
        const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
        const excelBuffer: any = xlsx.write(workbook, { bookType: "xlsx", type: "array" });
        this.saveAsExcelFile(excelBuffer, this.exportFilename);
        this._exportColumns = [];
    }

    saveAsExcelFile(buffer: any, fileName: string): void {
        let EXCEL_TYPE = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data: Blob = new Blob([buffer], {
            type: EXCEL_TYPE,
        });
        saveAs(data, fileName + EXCEL_EXTENSION);
    }

    setExportData() {
        const controlled = this.controlRowId(this._selectedRows[0]);

        if (controlled) {
            this._exportColumns.push({ title: "ID", dataKey: "id" });
        }

        let fileColumns = this._selectedColumns.map((col) => {
            if (col.exportable) {
                return { title: this._translateService.instant("table." + col.field), dataKey: col.field };
            }
        });

        this._exportColumns = [...this._exportColumns, ...fileColumns];
    }

    prepareCSVExport() {
        this._selectedColumns.forEach((col) => {
            col.header = this._tableService.translateColumns(col);
        });

        const controlled = this.controlRowId(this.tableRows[0]);

        if (controlled) {
            this._selectedColumns.unshift({ field: "id", header: "ID" });
        }
    }

    rollbackCSVData() {
        const controlled = this.controlRowId(this.tableRows[0]);

        if (controlled) {
            this._selectedColumns.shift();
        }
    }

    controlRowId(row: any) {
        return row.hasOwnProperty("id");
    }

    clearSelection() {
        this._selectedRows = [];
    }

    removeSelection() {
        const ids = this._selectedRows.map((e) => e.id);
        this._swalModalService.delete().then((result) => {
            if (result.isConfirmed) {
                this._elementService.deleteElements(ids).subscribe((res) => {
                    this._selectedRows = [];
                    this.removeElements.emit();
                });
            }
        });
    }

    /**
     * Add or remove a node from nodes array
     * @param event
     * @param selected
     * @param filterList
     */
    treeSelectFilter(event: any, selected: boolean, filterList: string): void {
        /*
        Here is the trick, we make a copy of node event and push it in our array that contains selected nodes (filters).
        In that way we can later change their property to avoid error like :
            - TypeError: cyclic object value (Firefox)
            - TypeError: Converting circular structure to JSON (Chrome and Opera)
        While continuing to use the original tree structure provided by the event node in component

        Warning : There is a bug when tag have same name in lowercase (key property) and result in a multiselect of data with same key (Tag A = key a; tag a = key a).
                  Moreover this can create cyclic error if pre select parent and expand children.
                  Change the key here has no effect.
        */

        let nodeCopy = [];

        if (Array.isArray(event.node)) {
            nodeCopy = [...event.node];
        } else {
            nodeCopy.push({ ...event.node });
        }

        this.controlNodes(filterList);

        if (selected) {
            Object.values(nodeCopy).forEach((node: any) => {
                this.nodes[filterList].push({ ...node });
            });
        } else {
            const nodeCopyIds = Object.values(nodeCopy).map((n: any) => n.id);
            this.nodes[filterList] = this.nodes[filterList].filter((n) => !nodeCopyIds.includes(n.id));
        }
    }

    /**
     * Change nodes property to avoid cyclic / circular error and let them as a non-referenced object
     * @param filterListData
     * @returns
     */
    changeNodesReference(filterListData: TreeNode[]): TreeNode[] {
        filterListData.forEach((node) => {
            node.parent = null;
            node.children = null;
        });

        return filterListData;
    }

    /**
     * Use to set a default value to nodes
     * @param filterList
     */
    private controlNodes(filterList: string): void {
        if (!this.nodes.hasOwnProperty(filterList)) {
            this.nodes[filterList] = [];
        }
    }

    // @TODO: See why tree is broken (doesn't display children after select all) & why still selected when unselected all
    /*
  selectUnselectAll(filterList: string) {
    this.controlNodes(filterList);

    const propertyName = filterList + "Selected";
    let propertyValue = true;

    if (this.nodesState[propertyName]) {
      propertyValue = !this.nodesState[propertyName];
    }

    this.nodesState[propertyName] = propertyValue;

    if (propertyValue) {
      let filtersCopy = [...this.filters[filterList].data];

      filtersCopy.forEach(node => {
        let nodeChildren = this.selectRecursive(node);

        if (nodeChildren.length) {
          filtersCopy = filtersCopy.concat(nodeChildren[0]);
        }
      });

      this.nodes[filterList] = filtersCopy;
      this.nodeArray = filtersCopy; // At this time in filtersCopy nodes are non referenced to parent or child, why ? => need references to display tree

    } else {
      this.nodes[filterList] = [];
    }
  }

  private selectRecursive(node: TreeNode) {
    let nodeChildren = [];

    if (node.children) {
      nodeChildren.push(node.children);

      node.children.forEach(childNode => {
        this.selectRecursive(childNode);
      });
    }

    return nodeChildren
  }
  */

    /**
     * Remove one row from selected
     * @param rowId
     */
    removeFromSelection(rowId: number): void {
        this._selectedRows = this._dataService.removeFromSelection(this._selectedRows, rowId);
    }

    // @TODO: Keep row selection between pages
    /*
  onRowSelect(event: any) {
    const found = this.tmpSelectedRows.find(r => r.id === event.data.id);

    if (!found) {
      this.tmpSelectedRows.push(event.data);
    }
  }

  onRowUnselect(event: any) {
    this.tmpSelectedRows = this.tmpSelectedRows.filter(r => r.id !== event.data.id);
  }

  headerCheckboxToggle(event: any): void {
    this.tmpSelectedRows = this.tmpSelectedRows.concat(this._selectedRows);
    console.log(this.tmpSelectedRows)

    if (!event.checked) {
      this.tableRows.forEach(row => {
        this.tmpSelectedRows.filter(r => r.id !== row.id);
      });
    } else {
      // @TODO: Fix if checked then unchecked and rechecked content added twice
    }

    this._selectedRows = this.tmpSelectedRows;
  }
  */

    massTagSelection(event: any): void {
        const objectIds = this._selectedRows.map((e) => e.id);
        event["objectIds"] = objectIds;
        this.massTagActive = true;
        this.massTagStart.emit(event);
    }

    massTagOpen() {
        const that = this; // Need to change scope since it will focus html and not ts

        return $("#appTreeTagModal").on("hide.bs.modal", function () {
            that.treeTag.massTagNode = [];
            that.treeTag.propagateSelectionUp = false;
            that.treeTag.propagateSelectionDown = false;
            that.treeTag.massTagAdd = true;

            that.massTagClose();
        });
    }

    massTagClose(): void {
        if (this.massTagActive) {
            this.massTagEnd.emit();
            this.massTagActive = false;
        }
    }

    clearTable(table: Table): void {
        this.preFilter = {};
        this.nodes = {};
        this.nodeArray = [];
        this.searchInput.nativeElement.value = "";
        this.eventValue = null;

        // @TODO: Dirty but avoid to get x lazy load event (and back request) on clear where we don't have control. See in upper versions if solution has been provided.
        // We de-activate lazy load to avoid many event launch, then we clear (this is here were library launch many event) then we re-enable and filter to launch only one lazy load event.
        const sortOrder = this.sortOrder;
        const totalrecords = table.totalRecords;
        const filters = table.filters;

        table.lazy = false;
        table.clear();
        table.lazy = true;
        table.sortOrder = sortOrder;
        table.totalRecords = totalrecords;
        table.filters = filters;
        this.strictMode = false;
        this.searchOnFieldValue = false;
        this.searchOnMedia = false;
        this.searchOnArchived = false;
        table._filter();

        this.clearFilters.emit({ clear: true });
    }

    isModalHidden(modalName: string) {
        return document.getElementById(modalName).getAttribute("aria-hidden");
    }

    checkSelection(event: any): void {
        let found = true;

        if (event.hasOwnProperty("itemValue")) {
            found = event.value.find((o) => o.id === event.itemValue.id);
        } else {
            found = event.value.length ? true : false;
        }

        if (undefined !== found && false !== found) {
            this.nodeSelected.emit(true);
        } else {
            this.nodeSelected.emit(false);
        }
    }

    changeAdvancedSearchParameter(param: string) {
        this[param] = !this[param];

        if (/*"searchOnFieldValue" == param || */ "strictMode" == param && this[param]) {
            this["searchOnMedia"] = false;
        } else if ("searchOnMedia" == param) {
            // this["searchOnFieldValue"] = false;
            this["strictMode"] = false;
        }

        this.changeAdvancedSearch.emit({ advancedSearchParam: param, advancedSearchValue: this[param] });
    }

    getIdsForArchive(archiveHd: boolean = false) {
        let data = this._dataService.getIdsForArchive(this._selectedRows, this.searchDataParameter, archiveHd);

        this.downloadArchive.emit(data);
    }

    openMassUpdate() {
        if (this.clearMassUpdate) {
            this.massUpdateData = [];
            this.massUpdateForm.reset();
            this.massUpdateForm.setControl("models", this.fb.array([]));
        }

        let elementTypes = [];

        this._selectedRows.forEach((row) => {
            if (!elementTypes.includes(row.elementType)) {
                elementTypes.push(row.elementType);
            }
        });

        this._elementTypeService.getMultipleStructure(elementTypes).subscribe((res) => {
            this.massUpdateData = res.data;
            this.initMassUpdateForm();
            this.massUpdate.fire();
        });
    }

    initMassUpdateForm() {
        const modelsArray = this.massUpdateForm.get("models") as FormArray;

        Object.entries(this.massUpdateData).forEach(([modelKey, modelValue]) => {
            modelsArray.push(
                this.fb.group({
                    idModel: modelValue.id,
                    model: modelKey,
                    fields: this.fb.array([]),
                })
            );
        });
    }

    addModelField(modelIndex: number): void {
        const formGroup = this.fb.group({
            field: ["", Validators.required], // Need to store the selected object to keep name displayed, WARNING when disabled = undefined
            input: [""], // Need to store the selected object to keep name displayed, WARNING when disabled = undefined
            subInput: [""], // Need to store the selected object to keep name displayed, WARNING when disabled = undefined
            clear: [false], // Will clear the field
            value: [""],
            addBefore: [{ value: false, disabled: true }], // Add before field value
            addAfter: [{ value: false, disabled: true }], // Add after field value

            /* Field properties */
            id: ["", Validators.required], // Field ID
            name: [""], // Field Name
            fieldType: [""], // Field Type
            multiple: [false], // Field multiple
            selectable: [""],
            objectType: ["", Validators.required],
            protocol: [""],
            linkedId: [null],
            /* ---- */

            inputProperties: [""], // Input properties that we use for controls
            inputData: [""], // Fieldset Inputs for current field

            subInputProperties: [""], // Sub Input properties that we use for controls
            subInputData: [""], // Fieldset Sub Inputs for current field

            replacerField: [""], // The field that will replace
            replacerFieldData: [""], // The field replacer list

            replacerInput: [""], // The field's input that will replace
            replacerInputData: [""], // The field's inputs replacer list

            replacerSubInput: [""], // The input's sub input that will replace
            replacerSubInputData: [""], // The input's sub inputs replacer list
        });

        // Flag pour éviter la récursivité infinie
        let isControlChanging = false;

        // Récupération des contrôles
        const controlField = formGroup.get("field");
        const controlInput = formGroup.get("input");
        const controlSubInput = formGroup.get("subInput");
        const controlReplacerField = formGroup.get("replacerField");
        const controlValue = formGroup.get("value");
        const controlAddBefore = formGroup.get("addBefore");
        const controlAddAfter = formGroup.get("addAfter");
        const controlClear = formGroup.get("clear");

        // Abonnement pour `controlReplacerField`
        controlReplacerField.valueChanges.subscribe(() => {
            if (!isControlChanging) {
                isControlChanging = true;

                // Désactiver tous les autres contrôles si `controlReplacerField` change
                controlField.disable();
                controlInput.disable();
                controlSubInput.disable();
                controlValue.disable();
                controlAddBefore.disable();
                controlAddAfter.disable();
                controlClear.disable();

                isControlChanging = false;
            }
        });

        // Abonnement pour `controlClear`
        controlClear.valueChanges.subscribe((clear) => {
            if (!isControlChanging) {
                isControlChanging = true;

                if (clear) {
                    // Si `clear` est true, désactiver tous sauf `controlField`, `controlInput`, et `controlSubInput`
                    controlValue.disable();
                    controlAddBefore.disable();
                    controlAddAfter.disable();
                    controlReplacerField.disable();
                } else {
                    // Si `clear` est false, réactiver tous sauf `controlField`, `controlInput`, et `controlSubInput`
                    controlValue.enable();
                    //controlAddBefore.enable();
                    //controlAddAfter.enable();
                    controlReplacerField.enable();
                }

                // `controlField`, `controlInput` et `controlSubInput` restent activés
                controlField.enable();
                controlInput.enable();
                controlSubInput.enable();

                isControlChanging = false;
            }
        });

        // Abonnement pour `controlValue`
        controlValue.valueChanges.subscribe((value) => {
            if (!isControlChanging) {
                isControlChanging = true;

                if (value) {
                    // Si `value` est true, désactiver tous sauf `controlAddBefore` et `controlAddAfter`
                    //controlField.disable();
                    //controlInput.disable();
                    //controlSubInput.disable();
                    controlReplacerField.disable();
                    controlClear.disable();
                    controlAddBefore.enable();
                    controlAddAfter.enable();
                } else {
                    // Si `value` est false, réactiver tous sauf `controlAddBefore` et `controlAddAfter`
                    //controlField.enable();
                    //controlInput.enable();
                    //controlSubInput.enable();
                    controlReplacerField.enable();
                    controlClear.enable();
                    controlAddBefore.disable();
                    controlAddAfter.disable();
                    controlAddBefore.setValue(false);
                    controlAddAfter.setValue(false);
                }

                // `controlAddBefore` et `controlAddAfter` restent activés
                // controlAddBefore.enable();
                // controlAddAfter.enable();

                isControlChanging = false;
            }
        });
        const model = this.models.at(modelIndex).get("fields") as FormArray;

        model.push(formGroup);
    }

    get models(): FormArray {
        return this.massUpdateForm.get("models") as FormArray;
    }

    fields(modelIndex: number): FormArray {
        return this.models.at(modelIndex).get("fields") as FormArray;
    }

    fieldGroups(modelIndex: number, fieldIndex: number): FormGroup {
        return this.fields(modelIndex).at(fieldIndex) as FormGroup;
    }

    deleteFormField(modelIndex: number, fieldIndex: number) {
        this.fields(modelIndex).removeAt(fieldIndex);
    }

    onCascadeSelectChange(modelIndex: number, fieldIndex: number, event: any, model: string, from: string, type: string = "field"): void {
        const selectedObject = event.value;

        if (selectedObject) {
            const fieldGroup = this.fieldGroups(modelIndex, fieldIndex);

            if (from === "source") {
                if (type === "field") {
                    let replacerFieldData = [...this.massUpdateData[model].tabs];

                    replacerFieldData = replacerFieldData
                        .map((tab) => ({
                            ...tab,
                            sections: tab.sections
                                .map((section) => ({
                                    ...section,
                                    fields: section.fields.filter(
                                        (field) =>
                                            (field.fieldType !== "fieldset" &&
                                                field.fieldType === selectedObject.fieldType &&
                                                field.id != selectedObject.id &&
                                                field.linkedId == selectedObject.linkedId) ||
                                            (field.fieldType === "fieldset" && field.id != selectedObject.id)
                                    ),
                                }))
                                .filter((section) => section.fields.length > 0),
                        }))
                        .filter((tab) => tab.sections.some((section) => section.fields.length > 0));

                    fieldGroup.patchValue({
                        id: selectedObject.id,
                        name: selectedObject.name,
                        fieldType: selectedObject.fieldType,
                        multiple: selectedObject.multiple,
                        selectable: selectedObject.selectable,
                        objectType: selectedObject.objectType,
                        protocol: selectedObject.protocol,
                        value: selectedObject.value,
                        linkedId: selectedObject.linkedId,
                        input: "",
                        inputProperties: "",
                        inputData: selectedObject.inputs.length ? selectedObject.inputs : "",
                        subInput: "",
                        subInputProperties: "",
                        subInputData: "",
                        replacerFieldData: replacerFieldData,
                    });
                } else if (type === "input") {
                    const replacerFieldData = this.getReplacerData(model, selectedObject);
                    const fieldWithoutSubFieldset = selectedObject.inputs.length
                        ? selectedObject.inputs.filter((input) => input.fieldType !== "fieldset")
                        : ""; /* Do not let to use sub fieldset of sub fieldset */

                    fieldGroup.patchValue({
                        inputProperties: selectedObject,
                        subInput: "",
                        subInputProperties: "",
                        subInputData: fieldWithoutSubFieldset,
                        replacerFieldData: replacerFieldData,
                    });
                } else if (type === "subInput") {
                    const replacerFieldData = this.getReplacerData(model, selectedObject);

                    fieldGroup.patchValue({
                        subInputProperties: selectedObject,
                        replacerFieldData: replacerFieldData,
                    });
                }
            } else if (from === "target") {
                let lastFieldType = "";
                let lastId = null;
                let replacerData = [];
                let lastLinkedId = null;

                if (fieldGroup.value.subInputProperties && fieldGroup.value.subInputProperties.id) {
                    lastFieldType = fieldGroup.value.subInputProperties.fieldType;
                    lastLinkedId = fieldGroup.value.subInputProperties.linkedId;
                    lastId = fieldGroup.value.subInputProperties.id;
                } else if (fieldGroup.value.inputProperties && fieldGroup.value.inputProperties.id) {
                    lastFieldType = fieldGroup.value.inputProperties.fieldType;
                    lastLinkedId = fieldGroup.value.inputProperties.linkedId;
                    lastId = fieldGroup.value.inputProperties.id;
                } else {
                    lastFieldType = fieldGroup.value.fieldType;
                    lastLinkedId = fieldGroup.value.linkedId;
                    lastId = fieldGroup.value.id;
                }

                const fieldsetInputs = selectedObject.inputs.filter((input) => input.fieldType === "fieldset");

                if (fieldsetInputs.length) {
                    replacerData = selectedObject.inputs.filter(
                        (input) =>
                            (input.fieldType === lastFieldType && input.id != lastId && input.linkedId == lastLinkedId) ||
                            (type === "field" && input.fieldType === "fieldset") /* Do not let to use sub fieldset of sub fieldset */
                    );
                } else {
                    replacerData = selectedObject.inputs.filter((input) => input.fieldType === lastFieldType && input.id != lastId && input.linkedId == lastLinkedId);
                }

                if (type === "field") {
                    fieldGroup.patchValue({
                        replacerInput: "",
                        replacerInputData: replacerData.length ? replacerData : "",
                        replacerSubInput: "",
                        replacerSubInputData: "",
                    });
                } else if (type === "input") {
                    fieldGroup.patchValue({
                        replacerSubInput: "",
                        replacerSubInputData: replacerData.length ? replacerData : "",
                    });
                } else if (type === "subInput") {
                    // N∕A
                }
            }
        }
    }

    getReplacerData(model, selectedObject) {
        // Recursive method to see if a fieldset has a searched field or antoher fieldset with this type
        function containsRelevantField(fields, fieldType, linkedId) {
            return fields.some((field) => {
                if (field.fieldType === fieldType && field.linkedId == linkedId) {
                    return true; // Field is of the serached type
                }
                if (field.fieldType === "fieldset" && field.inputs) {
                    // If it's a fieldset : recursive control
                    return containsRelevantField(field.inputs, fieldType, linkedId);
                }
                return false; // Else continue to search
            });
        }

        let replacerFieldData = [...this.massUpdateData[model].tabs];

        replacerFieldData = replacerFieldData
            .map((tab) => ({
                ...tab,
                sections: tab.sections
                    .map((section) => ({
                        ...section,
                        fields: section.fields
                            .filter((field) => {
                                // Keep field that are of the searched type (fieldset)
                                return (field.fieldType === selectedObject.fieldType && field.linkedId == selectedObject.linkedId) || field.fieldType === "fieldset";
                            })
                            .map((field) => {
                                // If fieldsdet, control it has searched field type or fieldset
                                if (field.fieldType === "fieldset") {
                                    const hasRelevantField = field.inputs && containsRelevantField(field.inputs, selectedObject.fieldType, selectedObject.linkedId);

                                    if (!hasRelevantField) {
                                        // Exclude fieldset when no searched field or no fieldset
                                        return null;
                                    }
                                }
                                return field;
                            })
                            .filter((field) => field !== null), // Filtrer les null (les fieldsets exclus)
                    }))
                    .filter((section) => {
                        // Ne garder que les sections qui ont des fields avec le fieldType recherché
                        return section.fields.some((field) => (field.fieldType === selectedObject.fieldType && field.linkedId == selectedObject.linkedId) || field.fieldType === "fieldset");
                    }),
            }))
            .filter((tab) => tab.sections.some((section) => section.fields.length > 0));

        return replacerFieldData;
    }

    saveMassUpdate() {
        const elementPerModel = this._selectedRows.reduce((acc, current) => {
            const formModel = this.massUpdateForm.value.models.find((modelData) => {
                return modelData.model === current.elementType;
            });

            if (formModel && formModel.fields.length) {
                if (!acc[current.elementType]) {
                    acc[current.elementType] = [];
                }

                acc[current.elementType].push(current);
            }

            return acc;
        }, {});

        if (Object.keys(elementPerModel).length !== 0) {
            let list = "";

            Object.entries(elementPerModel).map(([key, elements]: any) => {
                return (list += `<span>${key} : ${elements.length} ${this._translateService.instant("projects.flatplan.product")}</span><br><br>`);
            });

            const message = `
                <h3 class="text-danger">
                    ${this._translateService.instant("general.massUpdateWarning")}
                </h3>
                <hr>
                ${list}
            `;

            this._confirmationService.confirm({
                header: this._translateService.instant("general.warning"),
                message: message,
                icon: "pi pi-exclamation-triangle",
                acceptLabel: this._translateService.instant("general.Tovalidate"),
                acceptButtonStyleClass: "bg-primary",
                rejectLabel: this._translateService.instant("general.cancel"),
                rejectButtonStyleClass: "bg-danger",

                accept: () => {
                    this.clearMassUpdate = true;

                    this.selectableDataToReset.forEach((selectable) => {
                        selectable.setValue([]);
                    });

                    let ids = this._selectedRows.map((element) => {
                        return element.id;
                    });

                    this._elementService.massUpdate({ models: this.clearListCascade(this.massUpdateForm.value.models), elements: ids }).subscribe((res) => {
                        if (res.data) {
                            this._toasterService.show({ type: "success", message: this._translateService.instant("general.massUpdateProduct") });
                        } else {
                            this._toasterService.show({ type: "warning", message: this._translateService.instant("general.massUpdateNoProduct") });
                        }
                    });
                },
                reject: () => {
                    this.clearMassUpdate = false;
                    this.massUpdate.fire();
                    this.clearMassUpdate = true; // Avoid on cancel and re-open weird behaviors (dupliate models etc ...)
                },
            });
        }
    }

    clearListCascade(models) {
        models.forEach((model) => {
            model.fields.forEach((field) => {
                if (field.fieldType == "listcascade") {
                    // Avoid cyclic error
                    field.selectable = [];
                    if (field.field) {
                        field.field.selectable = [];
                    }
                } else if (field.fieldType == "fieldset") {
                    if (field.input && field.input.fieldType == "listcascade") {
                        field.input.selectable = []; // Avoid cyclic error
                    }
                    if (field.inputProperties && field.inputProperties.fieldType == "listcascade") {
                        field.inputProperties.selectable = [];
                    }

                    if (field.replacerField && field.replacerField.selectable) {
                        field.replacerField.selectable = [];
                    }

                    if (field.input && field.input.fieldType == "fieldset" && field.subInput && field.subInput.fieldType == "listcascade") {
                        field.subInput.selectable = []; // Avoid cyclic error
                    }

                    if (field.subInputProperties && field.subInputProperties.fieldType == "listcascade") {
                        field.subInputProperties.selectable = [];
                    }
                }
            });
        });

        return models;
    }

    // Mass Update List Cascade
    onNodeSelect(event, modelIndex: number, fieldIndex: number) {
        const selectedNodeControl = this.fieldGroups(modelIndex, fieldIndex).get("value");
        let parsedData = JSON.parse(selectedNodeControl.value && selectedNodeControl.value.length ? selectedNodeControl.value : '""');
        parsedData = [...parsedData, event.node]; // Set base data structure
        let value = this._dataService.getSelectedValue(parsedData, event, true);
        selectedNodeControl.setValue(JSON.stringify(value)); // Set managed data structure without circular ref
        let selectedNodeControlSelectableData = this.fieldGroups(modelIndex, fieldIndex).get("selectable");
        this.selectableDataToReset.push(selectedNodeControlSelectableData); // Prepare source to avoid circular reference
    }

    // Mass Update List Cascade
    onNodeUnselect(event, modelIndex: number, fieldIndex: number) {
        const selectedNodeControl = this.fieldGroups(modelIndex, fieldIndex).get("value");

        let parsedData = JSON.parse(selectedNodeControl.value && selectedNodeControl.value.length ? selectedNodeControl.value : '""');
        let updatedNodes = parsedData.filter((val) => val.id !== event.node.id);
        let parentNodes = parsedData.filter((val) => val.parentId == event.node.parent.id && val.id != event.node.id);
        if (!parentNodes.length) {
            updatedNodes = updatedNodes.filter((val) => val.id !== event.node.parent.id);
        }
        const error = updatedNodes.find((val) => val.selected === true && val.partialSelected === true);
        const found = updatedNodes.find((val) => val.selected === true);
        if (error || !found) {
            updatedNodes = [];
        }
        selectedNodeControl.setValue(JSON.stringify(updatedNodes));
    }
}
