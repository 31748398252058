<div class="row">
    <div class="col-12">
        <h3 *ngIf="!configuration">{{ 'admin.workflow.create' | translate }}</h3>
        <h3 *ngIf="configuration">{{ 'admin.workflow.update' | translate }}</h3>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <div class="card">
            <div class="card-body">
                <form *ngIf="formConfiguration" [formGroup]="formConfiguration">
                    <div class="form-group row">
                        <label for="name" class="col-5 col-md-2 col-form-label">{{ 'admin.workflow.name' | translate }}</label>
                        <div class="col-7 col-md-10">
                            <input type="text" formControlName="name" class="form-control" id="name" />
                        </div>
                    </div>
                    <div class="form-group row">
                        <label for="module" class="col-5 col-md-2 col-form-label">{{ 'admin.workflow.module' | translate }}</label>
                        <div class="col-7 col-md-10">
                            <select id="module" class="form-control" formControlName="module" (change)="listCommand()">
                                <option value="pim">{{ 'pim.title' | translate }} </option>
                                <option value="projects">{{ 'projects.title' | translate }}</option>
                                <option value="cdf">{{ 'cdf.title' | translate }}</option>
                                <option value="dam">{{ 'dam.title' | translate }}</option>
                            </select>
                        </div>
                    </div>
                    <div class="form-group row" *ngIf="formConfiguration.controls.module.value === 'pim'">
                        <label for="elementTypes" class="col-5 col-md-2 col-form-label">{{ 'pim.elementTypes.title' | translate }}</label>
                        <div class="col-7 col-md-10">
                            <ng-multiselect-dropdown formControlName="elementTypes" id="multiple-select-elemtype" placeholder="{{ 'general.select' | translate }}" [settings]="dropdownElementTypesSettings" [data]="dropdownElementTypesList" (onSelect)="getElements()" (onSelectAll)="getElements()"></ng-multiselect-dropdown>
                        </div>
                    </div>
                </form>
            </div>
        </div>
        <form [formGroup]="formPlace" (ngSubmit)="onSubmit()">
            <nav>
                <div class="nav nav-tabs" id="nav-places-tabs" role="tablist">
                    <a *ngFor="let place of formPlace.controls.places['controls']; let i = index" [ngClass]="{ 'nav-item nav-link': true, active: i === 0 }" id="nav-etape-{{ i }}-tab" data-toggle="tab" href="#nav-etape-{{ i }}" role="tab" [attr.aria-controls]="'nav-etape-' + i" [attr.aria-selected]="i === 0 ? 'true' : 'false'">
                        {{ 'general.step.title' | translate }} {{ i + 1 }}
                        <fa-icon [icon]="faTimesCircle" class="text-danger" (click)="removePlace(i, place)"></fa-icon>
                    </a>
                </div>
            </nav>

            <div class="tab-content" id="nav-places-content">
                <div *ngFor="let place of formPlace.controls.places['controls']; let i = index" [ngClass]="{ 'tab-pane fade': true, 'show active': i === 0 }" id="nav-etape-{{ i }}" role="tabpanel" [attr.aria-labelledby]="'nav-etape-' + i + '-tab'" >
                    <div formArrayName="places" class="row">
                        <div class="col-12">
                            <div [formGroupName]="i">
                                <div class="form-group row">
                                    <label for="step-name" class="col-5 col-md-2 col-form-label">{{ 'admin.workflow.name' | translate }}</label>
                                    <div class="col-7 col-md-10">
                                        <input type="text" formControlName="name" class="form-control" [id]="'step-name-' + i" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="step-end-date" class="col-5 col-md-2 col-form-label">{{ 'general.date.end' | translate }}</label>
                                    <div class="col-7 col-md-10">
                                        <label class="switch switch-lg switch-outline-primary-alt">
                                            <input [id]="'step-end-date-' + i" type="checkbox" floating-label formControlName="date_fin" class="switch-input" />
                                            <span class="switch-slider"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="module === 'projects'">
                                    <label for="blockTexts" class="col-5 col-md-2 col-form-label"> {{ 'projects.conf.block.texts' | translate }}</label>
                                    <div class="col-7 col-md-10">
                                        <label class="switch switch-lg switch-outline-primary-alt">
                                            <input [id]="'step-block-text-' + i" type="checkbox" floating-label formControlName="blockTexts" class="switch-input" />
                                            <span class="switch-slider"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="form-group row" *ngIf="module === 'projects'">
                                    <label for="blockPictures" class="col-5 col-md-2 col-form-label"> {{ 'projects.conf.block.pictures' | translate }}</label>
                                    <div class="col-7 col-md-10">
                                        <label class="switch switch-lg switch-outline-primary-alt">
                                            <input [id]="'step-block-picture-' + i" type="checkbox" floating-label formControlName="blockPictures" class="switch-input" />
                                            <span class="switch-slider"></span>
                                        </label>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="step-color" class="col-5 col-md-2 col-form-label">{{ 'admin.workflow.color' | translate}}</label>
                                    <div class="col-7 col-md-10">
                                        <input type="color" formControlName="color" class="form-control" [id]="'step-color-' + i" />
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="multiple-select-user" class="col-5 col-md-2 col-form-label">{{ 'admin.workflow.users' | translate }}</label>
                                    <div class="col-7 col-md-10">
                                        <ng-multiselect-dropdown formControlName="users" [id]="'step-select-user-' + i" placeholder="{{ 'general.select' | translate }}" [settings]="dropdownUsersSettings" [data]="dropdownUsersList"></ng-multiselect-dropdown>
                                    </div>
                                </div>
                                <div class="form-group row">
                                    <label for="multiple-select-command" class="col-5 col-md-2 col-form-label">{{ 'general.action' | translate }}</label>
                                    <div class="col-7 col-md-10">
                                        <ng-multiselect-dropdown formControlName="commands" [id]="'step-select-command-' + i" placeholder="{{ 'general.select' | translate }}" [settings]="dropdownCommandsSettings" [data]="dropdownCommandsList"></ng-multiselect-dropdown>
                                    </div>
                                </div>

                                <div class="form-group row">
                                    <label for="send-mail" class="col-5 col-md-2 col-form-label"> {{ 'admin.workflow.sendMail' | translate }}</label>
                                    <div class="col-7 col-md-10">
                                        <label class="switch switch-lg switch-outline-primary-alt">
                                            <input [id]="'send-mail-' + i" type="checkbox" floating-label formControlName="sendMail" class="switch-input" />
                                            <span class="switch-slider"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="card card-footeronly">
                <div class="card-footer">
                    <div class="row">
                        <button type="button" class="btn btn-danger" (click)="onBack()">
                            <fa-icon [icon]="faTimesCircle"></fa-icon>
                            <span class="ml-1 d-none d-md-inline-block">{{ 'general.cancel' | translate }}</span>
                        </button>
                        <div class="btn-group ml-auto">
                            <button type="button" class="btn btn-primary" (click)="addPlace()">
                                <fa-icon [icon]="faPlusSquare"></fa-icon>
                                <span class="ml-1 d-none d-md-inline-block">{{ 'general.step.add' | translate }}</span>
                            </button>
                            <button *ngIf="formConfiguration && formConfiguration.valid && formPlace.value.places.length > 0" class="btn btn-primary" type="submit" value="Valider" [disabled]="!formPlace.valid" >
                                <fa-icon [icon]="faSave"></fa-icon>
                                <span class="ml-1 d-none d-md-inline-block">{{ 'general.save' | translate }}</span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
