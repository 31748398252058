import { Injectable } from "@angular/core";
import { Observable, ReplaySubject } from "rxjs";

@Injectable()
export class DataService {
    constructor() {}

    uniqueData(arrayData: any, property: string = "id") {
        let map = new Map();

        for (const data of arrayData) {
            map.set(data[property], data);
        }

        const uniqueData = [...map.values()];

        return uniqueData;
    }

    convertFile(file: File): Observable<string> {
        const result = new ReplaySubject<string>(1);
        const reader = new FileReader();
        reader.readAsBinaryString(file);
        reader.onload = (event) => result.next(btoa(event.target.result.toString()));

        return result;
    }

    /**
     * Extract object ids
     * @param selectedRows
     * @param searchDataParameter
     * @param archiveHd
     * @param attribute
     * @returns
     */
    getIdsForArchive(selectedRows: any, searchDataParameter: string, archiveHd: boolean = false, attribute: string = "id") {
        const ids = selectedRows.map((row) => {
            return row[attribute];
        });

        return { [searchDataParameter]: ids, archiveHd: archiveHd };
    }

    /**
     * Remove one object
     * @param selectedRows
     * @param rowId
     * @param attribute
     * @returns
     */
    removeFromSelection(selectedRows: any, rowId: string | number, attribute: string = "id") {
        return selectedRows.filter((r) => r[attribute] !== rowId);
    }

    formatDateValue(value) {
        if (4 === value.indexOf("/")) {
            value = value.split("/").reverse().join("/");
        }

        return value;
    }
}
