import { Component,OnInit } from "@angular/core";
import { MessageInformationService } from "app/core/services/admin/user/MessageInformation.service";
import { Alert } from "app/shared/models/Alert";

@Component({
    selector: "app-alert-message",
    templateUrl: "./alert-message.component.html",
})
export class AlertMessageComponent implements OnInit  {

  displayAlertSystem = true;
  displayAlertUser = true;

  messageInformations = [];
  AlertSystems:Alert = {
    type: "danger",
    message: "",
  }

  AlertUser:Alert = {
      type: "warning",
      message:"",
  }
    
  constructor(private _messagesInformationServices:MessageInformationService){}
  ngOnInit(): void {

    this._messagesInformationServices.getMessage().subscribe((response) => {
      this.messageInformations = response.data;
          this.messageInformations.forEach((message) => {
              if(message.isSystemMessage === true){                        
                  this.AlertSystems.message = message.message
              } else {
                  this.AlertUser.message = message.message

              }
          })

        

  })
    
  }

  close(alert){
    if(alert === 'system'){
      this.displayAlertSystem = false;
    }

    if(alert === "user"){
      this.displayAlertUser = false;
    }
    
  }

}         
     