import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError } from "rxjs/operators";
import { environment } from "src/environments";

@Injectable()
export class PreviewService {
    constructor(private _http: HttpClient) {}

    /**
     * Get a media's preview
     *
     * @param id
     * @param forCropPreview
     * @returns
     */
    getPreview(id: number, forCropPreview : string = 'false'): Observable<any> {
        if (forCropPreview === 'true') {
            let params = new HttpParams().set('forCropPreview', forCropPreview);
            return this._http.get(`${environment.damUrl}/media/${id}/preview.json`, { params: params }).pipe(catchError((_) => of([])));
        }
        return this._http.get(`${environment.damUrl}/media/${id}/preview.json`).pipe(catchError((_) => of([])));
    }

    /**
     * Get all available formats
     */
    getFormats() {
        return this._http.get(`${environment.damUrl}/formats.json`);
    }

    getDownloadFile(id: number, format: string = "HD", extension: string = ""): Observable<any> {
        return this._http.post(`${environment.damUrl}/media/${id}/download.json`, {
            format : format,
            extension : extension
        }).pipe(catchError((_) => of([])));
    }

    
}
