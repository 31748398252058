import { DatePipe } from "@angular/common";
import { Pipe, PipeTransform } from "@angular/core";
import { WorkflowData, WorkflowStep } from "../components/workflow/workflow";

@Pipe({
    name: "workflow",
})
export class WorkflowPipe implements PipeTransform {
    constructor(private datePipe: DatePipe) {}

    transform(workflow: WorkflowData): WorkflowData {
        let display: WorkflowData;

        display = {
            display: workflow.display,
            transitions: workflow.transitions,
            places: this.preparePlaces(workflow.display, workflow.currentPlace, workflow.transitions),
            currentPlace: workflow.currentPlace,
            authoSetValue: workflow.authoSetValue,
            isLastPlace: workflow.isLastPlace,
        };

        display = this.buttonDisabled(display);

        return display;
    }

    private preparePlaces(display, currentPlace, transitions) {
        const self = this;
        const places = [];
        
        if (display) {            
            for (let i = 0; i < display.length; ++i) {
                if (!display[i].nom.includes("_Reject")) {
                    let place: WorkflowStep;
                    const isActive = self.getCheck(display[i].endPlace, currentPlace);
                    const currentTransition = transitions[display[i].endPlace.key];
                    
                    if (currentTransition) {
                        let lastInteraction;
                        if (currentTransition.length > 1) {
                            
                            currentTransition.forEach(transition => {
                                lastInteraction = transition;
                            });
                        } else {
                            lastInteraction = transitions[display[i].endPlace.key];
                        }

                        place = {
                            isActive,
                            disabled: false,
                            title: display[i].endPlace.nom,
                            style: "",
                            key: display[i].endPlace.key,
                            transitions: transitions[display[i].endPlace.key],
                            isValidated: lastInteraction.isValid,
                            isInvalidated: lastInteraction.isInvalid,
                            lastDateInteraction: lastInteraction.date,
                            lastUserInteraction: lastInteraction.user,
                            note:lastInteraction.note
                        };
                    } else {
                        place = {
                            isActive,
                            disabled: false,
                            title: display[i].endPlace.nom,
                            style: "",
                            key: display[i].endPlace.key,
                            isInvalidated: false,
                            isValidated: false,
                            transitions: transitions[display[i].startPlace.key],
                        };
                    }
                    places.push(place);
                }
            }
        }
        return places;
    }

    private getCheck(place, currentPlace) {
        if (currentPlace) {
            return currentPlace === place.key ? true : null;
        } else {
            return place.default === true ? true : null;
        }
    }

    private buttonDisabled(display) {
        const places = display.places;
        if (places && places.length) {
            for (let i = 0; i < places.length; ++i) {
                const index = places.findIndex((place) => place.key === places[i].key);
                let afterCheck = false;
                let beforeCheck = false;
                if (places[index - 1] !== undefined) {
                    beforeCheck = places[index - 1].isActive;
                }
                if (places[index + 1] !== undefined) {
                    afterCheck = places[index + 1].isActive;
                }
                if (places[index].isActive || (!afterCheck && !beforeCheck)) {
                    places[index].disabled = true;
                } else {
                    places[index].disabled = null;
                }
            }
        }

        return display;
    }
}
