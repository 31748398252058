import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, Resolve, RouterStateSnapshot, UrlTree } from "@angular/router";
import { ExportsConfigurationService } from "app/core/services/project/project/exports.service";
import { ProjectService } from "app/core/services/project/project/project.service";
import { forkJoin, Observable } from "rxjs";
import { map } from "rxjs/operators";
import { ExportsConfigurationModule } from "./exports.module";

@Injectable({
    providedIn: ExportsConfigurationModule,
})
export class ExportsGuard implements CanActivate, Resolve<any> {
    constructor(private _service: ExportsConfigurationService, private _projectService: ProjectService) {}

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        return true;
    }

    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const projectId = route.parent.parent.parent.params.id;

        return forkJoin([
            this._service.getConfiguration(projectId),
            this._service.getElements(projectId),
            this._service.getExportTypesById(projectId),
        ]).pipe(
            map((data) =>   
                <any>{
                    config: (data[0] as any).data,
                    elements: data[1],
                    exportTypes: (data[2] as any).data,
                    projectId: projectId,
                }
            )
        );
    }
}
