import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "app/core/services/admin/user/user.service";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { ActionButtonsDef, ActionEvent, ActionType } from "app/shared/components/action-buttons";
import { TableView } from "app/shared/components/table";
import { ACL } from "app/shared/models/acl";
import { TableDataPipe } from "app/shared/pipes/table-data.pipe";
import { MAESTRO_ROUTES } from "app/shared/routes";
import { Subscription } from "rxjs";
import { TemplateService } from "../../../../../core/services/project/template/template.service";

@Component({
    selector: "app-templates-list",
    templateUrl: "./templates-list.component.html",
})
export class TemplatesListComponent implements OnInit, OnDestroy, TableView {
    table: [string, string][];
    columns: string[];
    private _actionButtonSub: Subscription;
    private _searchSub: Subscription;
    readonly actions: ActionButtonsDef = [
        { type: ActionType.Update, right: "MAESTRO_PROJECT_PROJECTS_UPDATE" },
        { type: ActionType.Delete, right: "MAESTRO_PROJECT_PROJECTS_DELETE" },
    ];
    private _exportTypes = [];
    private _templates: any;

    constructor(
        private _layout: LayoutService,
        private _tableDataPipe: TableDataPipe,
        private _router: Router,
        private _route: ActivatedRoute,
        private _service: TemplateService,
        private _swal: SwalModalService,
        private _translateService: TranslateService,
        private _userService: UserService
    ) {}

    ngOnInit(): void {
        this._templates = this._route.snapshot.data.templates.data;
        
        this._service.getExportTypes().subscribe((response) => {
            this._exportTypes = response.data;
            this._templates.forEach((t) => {
                const et = this._exportTypes.find((e) => e.id === t.exportType);
                if (et) {
                    t.exportType = et.value;
                } else {
                    t.exportType = "";
                }
            });
            this._initList(this._templates);
        });

        const acl: ACL = this._userService.getUserAclFromToken();

        if (acl.MAESTRO_PROJECT_TEMPLATE_CREATE === 1) {
            this._layout.actionButton.enable = true;
            this._layout.actionButton.title = "project.templates.create";
            this._actionButtonSub = this._layout.actionButton.click$.subscribe((_) => this.onCreateButtonClick());
        }

        this._layout.breadcrumb.setPath({ routerLink: null, name: this._translateService.instant("breadcrumb.project.templates.list") }, 1);
        this._layout.searchInput.enable = true;
        this._searchSub = this._layout.searchInput.filter$.subscribe((value) => this.onFilter(value));
    }

    ngOnDestroy(): void {
        if (undefined !== this._actionButtonSub) {
            this._actionButtonSub.unsubscribe();
        }
        this._searchSub.unsubscribe();
        this._layout.searchInput.enable = false;
        this._layout.actionButton.enable = false;
    }

    /**
     * Filter template list by name
     * @param value
     * @returns
     */
    onFilter(value: string): void {
        if (value.length < 3) {
            this._initList(this._templates);
            return;
        }
        this._initList(
            this._templates.filter((template) => {
                return template.name.toLowerCase().includes(value.toLowerCase()) || template.exportType.toLowerCase().includes(value.toLowerCase());
            })
        );
    }

    /**
     * Handle action click
     * @param event
     */
    onActionClick(event: ActionEvent) {
        const id = event.rowId;
        switch (event.action) {
            case ActionType.Delete:
                this._service.deleteById(id).subscribe(() => {
                    this._initList(this._templates.filter((t) => t.id !== id));
                    this._templates = this._templates.filter((t) => t.id !== id);
                });
                break;
            case ActionType.Update:
                this._router.navigate([id, MAESTRO_ROUTES.actions.update], { relativeTo: this._route });
                break;
        }
    }

    onCreateButtonClick() {
        this._router.navigate([MAESTRO_ROUTES.actions.create], { relativeTo: this._route });
    }

    /**
     * Init the list
     * @param templates
     */
    private _initList(templates: any) {
        const formatedData = this._tableDataPipe.transform(templates);
        this.columns = formatedData[0];

        const trueColumns = [];

        this.columns.forEach((c) => {
            trueColumns.push("templates." + c);
        });

        this.columns = trueColumns;

        this.table = formatedData[1];
    }
}
