import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { faEdit, faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { Tab } from "app/shared/models";
import { Router } from '@angular/router';
import { SidebarService } from "../../..";
import { Subscription } from "rxjs";

@Component({
    selector: "app-sidebar-routing-form",
    templateUrl: "./sidebar-routing-form.component.html",
    styleUrls: ["./sidebar-routing-form.component.scss"],
})
export class SidebarRoutingFormComponent implements OnInit {
    @Output() validSubmit: EventEmitter<Tab> = new EventEmitter();
    @Output() delete: EventEmitter<Tab> = new EventEmitter();
    @Input() currentMode: string;
    @Input() formDisplayed: boolean;
    @Input() aclOptions: {
        delete: number;
        edit: number;
        create: number;
    };
    private _errorSubmit: Subscription;
    controlInput:boolean;
    private _currentNametabs :Subscription;
    nameTabs;
   
    constructor(fb: FormBuilder,private sidebarService:SidebarService,private _router:Router) {
        this._errorSubmit = sidebarService.errorSubmit$.subscribe((def) =>  this.controlInput = def);
        this._currentNametabs = sidebarService.tabName$.subscribe((def) => this.nameTabs = def);

        this.tabForm = fb.group({
            [this.tabNameInput]: ["", Validators.required,],
        });
    }



    ngOnInit(): void {
    }

    readonly faPlus = faPlus;
    readonly faEdit = faEdit;
    readonly faTrashAlt = faTrashAlt;

    submitted: boolean;
    readonly tabForm: FormGroup;
    readonly tabNameInput = "tabForm";

  

    onCancel() {
        this.submitted = false;
        this.sidebarService.errorSubmit = false;
        this._closeForm();
    }

    onCreate() {
        this.tabForm.get(this.tabNameInput).setValue("");

        this._openForm();
        this._changeMode("create");
        // this._router.navigate([this.options.absoluteRoute])
    }

    onEdit() {
        if(this.tabForm.get(this.tabNameInput)){
            this.controlInput = true 
        }
        this.tabForm.get(this.tabNameInput).setValue(this.nameTabs);
        this._openForm();
        this._changeMode("edit");

    }

    onCreateSubmit() {
        this.submitted = true;
        if (this.tabForm.valid) {
            this.validSubmit.emit({ name: this.tabForm.value[this.tabNameInput] });
        }
    }

    onUpdateSubmit() {
        this.submitted = true;
        if (this.tabForm.valid) {
            this.validSubmit.emit({
                id: 69,
                name: this.tabForm.value[this.tabNameInput],
            });
        }
    }

    private _closeForm() {
        this.submitted = false;
        this.formDisplayed = false;
    }

    private _changeMode(mode: "create" | "edit") {
        this.submitted = false;
        this.currentMode = mode;
    }

    private _openForm() {
        this.submitted = false;
        this.formDisplayed = true;
        this.controlInput = false;
    }
}
