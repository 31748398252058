import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { ProjectService } from "app/core/services/project/project/project.service";
// import { ProjectService } from "app/core/services/project/project/project.service";
import { MaestroProject } from "app/shared/models";
import { forkJoin, Observable } from "rxjs";
import { map } from "rxjs/operators";

@Injectable()
export class ConfigurationResolver implements Resolve<MaestroProject> {
    constructor(private _projectService: ProjectService) {}

    /**
     * Get data for each steps
     * @param route
     * @returns
     */
    resolve(route: ActivatedRouteSnapshot): Observable<any> {
        const projectId = route.parent.parent.params.id;
        if (projectId) {
            return forkJoin([
                this._projectService.getProjectExportTypes(projectId),
            ]).pipe(
                map(
                    (data: any) =>
                        <any>{
                            projectId: projectId,
                            exportTypes: data[0].data,
                        }
                )
            );
        }
    }
}
