import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { Cmyk, ColorPickerService } from "ngx-color-picker";

@Component({
    selector: "app-color-picker-maestro",
    templateUrl: "./color-picker-maestro.component.html",
    styleUrls: ["./color-picker-maestro.css"],
})
export class ColorPickerMaestroComponent implements OnInit {

    color: string;
    public cmykColor: Cmyk = new Cmyk(0, 0, 0, 0);
    cmykValue: string;
    @Input() value = "";
    @Input() disabled:boolean = false;

    @Output() colorSelect = new EventEmitter<string>();

    constructor(private cpService: ColorPickerService) { }

    ngOnInit() {
        try {
            let CmykFromBase = JSON.parse(this.value);
            this.cmykColor = new Cmyk(CmykFromBase.c, CmykFromBase.m, CmykFromBase.y, CmykFromBase.k, CmykFromBase.a);
            let Rgb = this.cpService.cmykToRgb(this.cmykColor);
            let hex = this.cpService.rgbaToHex(this.cpService.denormalizeRGBA(Rgb), true);
            this.color = hex;
        }
        catch (e) {
            this.cmykColor = new Cmyk(0, 0, 0, 0);
            let Rgb = this.cpService.cmykToRgb(this.cmykColor);
            let hex = this.cpService.rgbaToHex(this.cpService.denormalizeRGBA(Rgb));
            this.color = hex;
        }
    }

    onChangeColorCmyk(color: string) {
        const hsva = this.cpService.stringToHsva(color);

        if (hsva) {
            const rgba = this.cpService.hsvaToRgba(hsva);

            return this.cpService.rgbaToCmyk(rgba);
        }

        return new Cmyk(0, 0, 0, 0);
    }

    saveField() {
        let saveValue = JSON.stringify(this.cmykColor);
        this.colorSelect.emit(saveValue);
    }
}
