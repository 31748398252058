import { KeyValue } from "@angular/common";
import { Component, EventEmitter, forwardRef, Input, OnChanges, OnInit, Output, SimpleChanges } from "@angular/core";
import { AbstractControl, ControlValueAccessor, FormBuilder, FormGroup, NG_VALUE_ACCESSOR, Validators } from "@angular/forms";
import { TranslateService } from "@ngx-translate/core";
import { TableService } from "app/core/services/global/table/table.service";
import { ConfirmationService } from "primeng-lts/api";

const minimal: number = 1;

@Component({
    selector: "app-form-table",
    templateUrl: "./form-table.component.html",
    styleUrls: ["./form-table.component.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => FormTableComponent),
            multi: true,
        },
    ],
})
export class FormTableComponent implements OnInit, OnChanges, ControlValueAccessor {
    @Input() cells: string = ""; // JSON table data
    @Input() id: number; // Used to force ngOnChanges cause cells won't do it correctly
    @Input() maxCol: number = 25;
    @Input() minCol: number = minimal;
    @Input() maxRow: number = 50;
    @Input() minRow: number = minimal;
    @Input() maxHeaderRow: number = 10;
    @Input() minHeaderRow: number = minimal;
    @Input() importFromSource: boolean = false; // Let to import a table model if specified
    @Input() sources: string[] = [];
    @Input() readOnly: boolean = false; // Used for history
    @Input() overlayPanelMode: boolean = false;
    @Input() isPimVariable: boolean = true;
    @Input() disabledImportTable: boolean = false;

    tableForm: FormGroup; // Table structure
    tableFormInputType = {
        col: "number",
        row: "number",
        headerRow: "number",
        // note: "textarea"
    };

    cellForm: FormGroup; // Cell structure
    cellFormInputType = {
        col: "number",
        startHeaderCol: "number",
        // row: "number",
        startHeaderRow: "number",
    };
    cellFormEnabled: boolean = false; // Manage form display

    cols: any[] = []; // Used by p-table to display table columns
    rows: any[] = []; // Used by p-table to display table rows
    headerRow: number[] = []; // Represents the row's number that columns can occupy
    // note: string = "";
    focusedCell: string = ""; // Display in form the cell's name if defined
    rowCols: any[] = []; // Organize columns by header's row to display (can't be used with p-table)
    // tableLocked: boolean = false;

    @Output() cellsEmitter: EventEmitter<any> = new EventEmitter();
    @Output() importTableFromSource: EventEmitter<any> = new EventEmitter<any>(null);

    private _changeCallbacks: Array<(str: string) => any> = [];
    private _touchedCallbacks: Array<(str: string) => any> = [];

    constructor(private _fb: FormBuilder, private _confirmationService: ConfirmationService, private _translate: TranslateService, private _tableService: TableService) {}

    ngOnInit(): void {}

    ngOnChanges(changes: SimpleChanges) {
	let saveTableForm = true;

        if (this.cells) {
            try {
                const data = JSON.parse(this.cells); // Extract JSON data from parent to create table

                if (data && data.hasOwnProperty("cols") && data.hasOwnProperty("rows") && data.hasOwnProperty("headerRow")) {
                    const dataColsCopy = [...data.cols]; // Prevent error console about expression changed after checked
                    const colsWithoutEmpty = dataColsCopy.filter((col) => !col.empty); // Get cols without empty generated cols

                    let colsOrdered = colsWithoutEmpty.sort(function (a, b) {
                        // Get cols with right original order to display row values in right "column"
                        return a.position - b.position;
                    });

                    this.cols = colsOrdered;
                    this.rows = data.rows;
                    this.headerRow = this.fillHeaderRow(data.headerRow);
                    // this.note = data.note;
                }
            } catch (error) {
                // this._tableService.parseError(this.cells);
                this.cells = JSON.stringify(this.cells);
                if (undefined !== this.cells) {
                    this.ngOnChanges(changes);
                }
            }
        } else {
            // When value is null we need to initialize values to avoid to get an other table config and data
            this.cols = [];
            this.rows = [];
            this.headerRow = this.fillHeaderRow(1);
	    saveTableForm = false;
        }

        if (changes.cells && changes.cells.previousValue && changes.cells.currentValue !== changes.cells.previousValue) {
            this.cellFormEnabled = false; // Prevent to have a table's cell form opened in another table
        }

        this.initForm(); // Pre-fill table form
        this.saveTableForm(saveTableForm); // Re-create structural array
    }

    writeValue(obj: string): void {
        this._onChange(obj);
    }

    registerOnChange(fn: (str: string) => any) {
        this._changeCallbacks.push(fn);
    }

    registerOnTouched(fn: (str: string) => any): void {
        this._touchedCallbacks.push(fn);
    }

    private _onChange(str: string) {
        this._changeCallbacks.forEach((cb) => cb(str));
    }

    initForm(): void {
        this.tableForm = this._fb.group({
            col: [this.cols.length ? this.cols.length : this.minCol, [Validators.required, Validators.min(this.minCol), Validators.max(this.maxCol)]],
            row: [this.rows.length ? this.rows.length : this.minRow, [Validators.required, Validators.min(this.minRow), Validators.max(this.maxRow)]],
            headerRow: [this.headerRow.length ? this.headerRow.length : this.minHeaderRow, [Validators.required, Validators.min(this.minHeaderRow), Validators.max(this.maxHeaderRow)]],
            // note: [this.note ? this.note : ""]
        });

        // if (this.tableLocked) {
        //   this.tableForm.disable();
        // }
    }

    saveTableForm(saveInDb: boolean = true): void {
        if (this.tableForm.invalid) {
            return;
        }

        const col = this.tableForm.value.col;
        const row = this.tableForm.value.row;
        const headerRow = +this.tableForm.value.headerRow;
        // this.note = this.tableForm.value.note;

        this.updateHeaderRowCols(this.headerRow.length, headerRow);

        this.headerRow = this.fillHeaderRow(headerRow); // Actualize the headerRow array

        this.setTable(col, true);
        this.setTable(row, false);

        this.createRowCols(saveInDb);
    }

    saveCellForm(): void {
        if (this.cellForm.invalid) {
            return;
        }

        const controls = this.cellForm.controls;
        const cellType = "cols"; // controls["header"].value ? "cols" : "rows"; // Actually we just let cols to be grouped

        this[cellType].filter((cell) => {
            if (cell.position === controls["position"].value) {
                cell.col = +controls["col"].value;
                cell.startHeaderCol = +controls["startHeaderCol"].value;
                // cell.row = +controls["row"].value;
                cell.startHeaderRow = +controls["startHeaderRow"].value;
            }
        });

        this.cellFormEnabled = false;

        this.createRowCols();
    }

    /**
     * Create cols and rows
     * @param maxQuantity
     * @param header
     */
    setTable(maxQuantity: number, header: boolean): void {
        // @TODO: Add footer case in V2 ?
        const colsLength = this.cols.length;
        const rowsLength = this.rows.length;

        if (header) {
            // Cols
            if (maxQuantity < colsLength) {
                this.removeRowColField(maxQuantity);
                this.cols = this.cols.filter((col) => col.position < maxQuantity); // Remove cols
            } else if (maxQuantity > colsLength) {
                // Will generate new cols
                const newQuantity = maxQuantity - colsLength;

                for (let i = 0; i < newQuantity; i++) {
                    const newStart = this.cols.length ? this.cols[this.cols.length - 1].startHeaderCol + 1 : i + 1; // Determine where to start to push

                    const cell = {
                        col: 1,
                        startHeaderCol: newStart,
                        row: 1,
                        startHeaderRow: 1,
                        field: "",
                        header: "",
                        position: colsLength + i,
                        locked: false,
                        empty: false,
                    };

                    this.cols.push(cell);
                }
            }
        } else if (maxQuantity < rowsLength) {
            // Rows
            this.rows = this.rows.filter((row) => row.position < maxQuantity); // Remove rows
        } else if (maxQuantity > rowsLength) {
            // Rows
            const newQuantity = maxQuantity - rowsLength; // Determine how many row to create

            for (let i = 0; i < newQuantity; i++) {
                const cell = {
                    col: 1,
                    row: 1,
                    position: rowsLength + i,
                    locked: false,
                };

                this.rows.push(cell);
            }
        }
    }

    /**
     * Remove row's associated column's field
     * @param maxQuantity
     */
    removeRowColField(maxQuantity: number): void {
        const colsToRemove = this.cols.filter((col) => col.position + 1 > maxQuantity); // Get columns that will be removed

        colsToRemove.forEach((col) => {
            this.populateRows(col.field, "");
        });

        this.setCells();
    }

    /**
     * Launched when cell focused
     * @param event
     */
    onEditInit(event: any) {
        this.cellFormEnabled = false; // Prevent to display col cell form when click on col then immediatly click on row
        const head = event.data.hasOwnProperty("header") ? true : false; // Determine if it's a column
        const empty = event.data.hasOwnProperty("empty") ? event.data.empty : false; // Determine if it's an empty column

        if (head && !empty) {
            const data = event.data;
            this.focusedCell = data.header;
            this.cellFormEnabled = true;

            this.cellForm = this._fb.group({
                col: [data.col, [Validators.required, Validators.min(1), Validators.max(this.cols.length)]],
                startHeaderCol: [data.startHeaderCol],
                // row: [data.row, [Validators.required, Validators.min(this.minHeaderRow), Validators.max(this.headerRow.length)]], // @TODO: See in next version
                startHeaderRow: [data.startHeaderRow],
                position: [data.position, Validators.required],
                header: [head, Validators.required],
            });

            this.cellForm.get("startHeaderCol").setValidators([
                Validators.required,
                Validators.min(data.position + 1), // Don't let the value be less than its original startHeaderCol
                (control: AbstractControl) => Validators.max(this.cols.length - (this.cellForm.controls["col"].value - 1))(control),
            ]); // Dynamic max validator

            this.cellForm
                .get("startHeaderRow")
                .setValidators([
                    Validators.required,
                    Validators.min(this.minHeaderRow),
                    (control: AbstractControl) => Validators.max(this.headerRow.length /*- (this.cellForm.controls["row"].value - 1)*/)(control),
                ]); // Dynamic max validator

            this.cellForm.get("startHeaderCol").updateValueAndValidity();
            this.cellForm.get("startHeaderRow").updateValueAndValidity();
            this.cellForm.controls["position"].disable();
            this.cellForm.controls["header"].disable();

            if (data.locked) {
                this.cellForm.disable();
            }
        }
    }

    /**
     * Launched when cell outfocused
     * @param event
     */
    onEditComplete(event: any) {
        const data = event.data;

        if (!data.empty && !data.locked) {
            // Not an empty col
            if (data.hasOwnProperty("header")) {
                // Cols
                const col = this.rowCols[data.startHeaderRow - 1][event.index]; // Since when we move a column it will be moved in array this.cols the index won't be correct unless we use this.rowCols to find the right header array and use event.index to find the col since columns moved are replaced by empty col
                this.focusedCell = data.header;

                if (event.field !== col.header) {
                    const newField = data.header ? (data.header.toLowerCase() + "_" + data.startHeaderRow + "_" + event.index).trim().split(" ").join("_") : "";

                    if (newField !== col.field) {
                        if ("" !== newField) {
                            this.populateRows(col.field, newField);
                            // this.cols[event.index].field = newField; // Don't use index with this.cols since it's variable contrary to position
                            this.cols[data.position].field = newField;
                        }

                        this.setCells();
                    }
                }
            } else {
                // Rows
                const field = this.cols[event.index].field;

                if (data[field] !== event.field) {
                    this.setCells();
                }
            }
        }
    }

    /**
     * Launched on 'esc' key
     * @param event
     */
    onEditCancel(event: any) {
        this.cellFormEnabled = false;
    }

    /**
     * Update the cell's value based on column's property
     * @param field
     * @param newField
     */
    populateRows(field: string, newField: string): void {
        this.rows.forEach((row) => {
            if (row.hasOwnProperty(field)) {
                const value = row[field] ? row[field] : "";

                if ("" !== newField) {
                    row[newField] = value;
                }

                delete row[field]; // Remove old field
            } else {
                row[newField] = "";
            }
        });
    }

    /**
     * Return value of the given variable's params
     * @param type Dynamic getter
     * @param control
     * @returns
     */
    getTableMinMaxValue(type: string, control: string): number {
        const variable = type + control;

        return this[variable];
    }

    /**
     * Getter that calculate the cell's proportions on the fly
     * @param type
     * @param control
     * @returns
     */
    getCellMinMaxValue(type: string, control: string): number {
        let value = -1;

        switch (control) {
            case "Col":
                value = "min" === type ? this[type + control] : this.tableForm.controls["col"].value;
                break;
            case "Row":
                value = "min" === type ? this[type + "HeaderRow"] : this.tableForm.controls["headerRow"].value;
                break;
            case "StartHeaderRow":
                if ("min" === type) {
                    value = this.minHeaderRow;
                } else {
                    const cellRow = /*+this.cellForm.controls["row"].value*/ 1;

                    if (cellRow > 0) {
                        value = this.headerRow.length - (cellRow - 1);
                    } else {
                        value = 1;
                    }
                }
                break;
            case "StartHeaderCol":
                if ("min" === type) {
                    value = this.cellForm.controls["position"].value + 1; // Don't let the value be less than its original startHeaderCol
                } else {
                    const cellCol = +this.cellForm.controls["col"].value;

                    if (cellCol > 0) {
                        value = this.cols.length - (cellCol - 1);
                    } else {
                        value = 1;
                    }
                }
                break;
        }

        return value <= 0 ? 1 : value;
    }

    /**
     * Create cols array foreach header row with empty cols
     */
    createRowCols(saveInDb: boolean = true): void {
        let rowCols = [];

        this.headerRow.forEach((head) => {
            let data = this.cols.filter((col) => col.startHeaderRow === head); // Get only the cols that match the current header row
            let lastCol = 0;
            let lastStart = 1;
            let lastEnd = lastCol + lastStart;
            let emptyColColValue = 1; // Contains the last emptyCol.col value generated before current col

            const dataCopy = [...data]; // Create a copy to avoid to loop on same object if we add empty col, here we ensure that we will loop on every object in array except new empty col
            let previous = 1; // Used to push empty col at the right index

            dataCopy.forEach((col, key, arr) => {
                const lastItem = Object.is(arr.length - 1, key); // Check if last iteration

                if (lastEnd !== col.startHeaderCol) {
                    let colLength;
                    if (emptyColColValue === 1) {
                        colLength = col.startHeaderCol - lastEnd;
                    } else {
                        colLength = col.startHeaderCol - (lastEnd + (emptyColColValue - 1));
                    }

                    emptyColColValue = colLength;

                    const emptyCol = {
                        col: colLength,
                        startHeaderCol: lastEnd,
                        row: 1,
                        startHeaderRow: head,
                        field: "",
                        header: "",
                        position: col.position - 1,
                        locked: true,
                        empty: true,
                    };

                    if (lastEnd > 0 && emptyCol.col > 0) {
                        data.splice(emptyCol.startHeaderCol - previous, 0, emptyCol); // Will push empty col before current col
                    }
                }

                if (emptyColColValue === 1) {
                    lastEnd = col.col + col.startHeaderCol;
                } else {
                    const mutator = lastItem ? 1 : emptyColColValue - 1;
                    lastEnd = col.col + (col.startHeaderCol - mutator);
                }

                if (lastItem && lastEnd - 1 < this.cols.length && data.length < this.cols.length) {
                    // @TODO: Add model and mutualize code
                    const emptyCol = {
                        col: emptyColColValue === 1 ? this.cols.length - (lastEnd - 1) : this.cols.length - lastEnd, // this.cols.length - (lastEnd - 1),
                        startHeaderCol: lastEnd,
                        row: 1,
                        startHeaderRow: head,
                        field: "",
                        header: "",
                        position: col.position + 1,
                        locked: true,
                        empty: true,
                    };

                    if (lastEnd > 0 && emptyCol.col > 0) {
                        data.splice(emptyCol.startHeaderCol - 1, 0, emptyCol); // Will push empty col after current col which is last col of header row
                    }
                }

                previous += col.col - 1; // Let to take in count many col with col > 1 on same row and push these empty cols at the right place
            });

            rowCols.push(data);
        });

        this.rowCols = rowCols;

        this.setCells(saveInDb); // Let to save the structure if no data filled
    }

    /**
     * Change columns header row to the new limit if they exceed it
     * @param previousHeaderRow
     * @param nextHeaderRow
     */
    updateHeaderRowCols(previousHeaderRow: number, nextHeaderRow: number): void {
        const newHeaderRow = previousHeaderRow - nextHeaderRow; // Determine if we reduce the headerRow or not

        if (newHeaderRow > 0) {
            // Reduced headerRow
            let cols = this.cols.filter((col) => col.startHeaderRow > nextHeaderRow);

            cols.forEach((col) => {
                col.startHeaderRow = nextHeaderRow;
            });
        }
    }

    /**
     * Prevent table modification
     */
    // lockTable(): void { // @TODO: Add in json and set it's value in onChanges (V2)
    //   if (!this.readOnly && !this.overlayPanelMode) {
    //     this.tableLocked = !this.tableLocked;
    //   }
    // }

    /**
     * Prevent cell modification
     * @param cell
     */
    lockCell(cell: any): void {
        if (!this.readOnly && !this.overlayPanelMode) {
            cell.locked = !cell.locked;
            this.setCells();
        }
    }

    /**
     * Send table to parent control
     */
    setCells(saveInDb: boolean = true): void {
        const colsWithEmpty = [].concat(...this.rowCols); // Merge standard cols with empty generated cols // In ES19 we can use arr.flat()
        const data = JSON.stringify({ cols: colsWithEmpty, rows: this.rows, headerRow: this.headerRow.length, maxCol: this.cols.length, maxRow: this.rows.length });
        this._onChange(data);

	if (saveInDb) {
          this.cellsEmitter.emit(data);
	}
    }

    /**
     * Create an array based on table's headerRow number
     * @param headerRowNumber
     * @returns
     */
    fillHeaderRow(headerRowNumber: number): number[] {
        return Array(headerRowNumber)
            .fill(0)
            .map((x, i) => ++i); // Value will start at 1
    }

    /**
     * Keep original order from '*ngFor' with '| keyvalue' // @TODO: Do it global (service)
     * @param _left
     * @param _right
     * @returns
     */
    onCompare(_left: KeyValue<any, any>, _right: KeyValue<any, any>): number {
        return -1;
    }

    confirm(event: Event, source: string) {
        this._confirmationService.confirm({
            target: event.target,
            message: this._translate.instant("general.table.confirm"),
            icon: "pi pi-exclamation-triangle",
            acceptLabel: this._translate.instant("general.yes"),
            rejectLabel: this._translate.instant("general.no.title"),
            acceptButtonStyleClass: "bg-primary",
            rejectButtonStyleClass: "bg-secondary",
            accept: () => {
                this.importTableFromSource.emit(source);
            },
            reject: () => {},
        });
    }

    errorCell(cell: any): boolean {
        let error;
        const cellStartCol = cell.hasOwnProperty("startHeaderCol");

        if (cell.hasOwnProperty("col") && cell.col > this.tableForm.value.col) {
            error = true;
        } else if (cell.hasOwnProperty("row") && cell.row > this.tableForm.value.row) {
            error = true;
        } else if ((cellStartCol && cell.startHeaderCol > this.tableForm.value.col) || (cellStartCol && cell.col + cell.startHeaderCol - 1 > this.tableForm.value.col)) {
            error = true;
        } else if (cell.hasOwnProperty("startHeaderRow") && cell.startHeaderRow > this.tableForm.value.headerRow) {
            error = true;
        } else {
            error = false;
        }

        return error;
    }
}
