import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, Resolve } from "@angular/router";
import { ElementService } from "app/core/services/pim/element.service";
import { HomeService } from "app/core/services/pim/home.service";
import { ElementListResources } from "app/shared/models/pim/pim-home-resources";
import { Observable } from "rxjs";

@Injectable()
export class ElementListResolver implements Resolve<ElementListResources> {
    constructor(private service: ElementService, private home: HomeService) { }

    resolve(route: ActivatedRouteSnapshot): Observable<ElementListResources> {
        return this.service.getSidebarData();
    }
}
