import { NgModule } from "@angular/core";
import { SharedModule } from "app/shared/shared.module";
import { CascadeSelectModule } from "primeng-lts/cascadeselect";
import { TableModule } from "primeng-lts/table";
import { TabViewModule } from "primeng-lts/tabview";
import { MultiSelectModule } from "primeng/multiselect";
import { ActionButtonsModule } from "../action-buttons/action-buttons.module";
import { ColorPickerMaestroModule } from "../form/color-picker-maestro/color-picker-maestro.module";
import { DatePickerModule } from "../form/date-picker/date-picker.module";
import { FormMediaModule } from "../form/media/form-media.module";
import { FormTableModule } from "../form/table/form-table.module";
import { TreeTagModule } from "../massAction/tree/tag/treeTag.module";
import { TextEditorModule } from "../text-editor/text-editor.module";
import { DatatableComponent } from "./datatable.component";

@NgModule({
    declarations: [DatatableComponent],
    imports: [
        TableModule,
        MultiSelectModule,
        SharedModule,
        ActionButtonsModule,
        TreeTagModule,
        TabViewModule,
        DatePickerModule,
        TextEditorModule,
        ColorPickerMaestroModule,
        FormTableModule,
        FormMediaModule,
        CascadeSelectModule,
    ],
    exports: [DatatableComponent],
})
export class DatatableModule {}
