import { NgModule } from "@angular/core";
import { ActionButtonsModule } from "./components/action-buttons/action-buttons.module";
import { AlertMessageModule } from "./components/alert-message/alert-message.module";
import { BreadcrumbModule } from "./components/breadcrumb/breadcrumb.module";
import { CropperModule } from "./components/cropper/cropper.module";
import { EditFormModule } from "./components/dam-metadata/edit-form.module";
import { DatatableModule } from "./components/datatable/datatable.module";
import { DataviewModule } from "./components/dataview/dataview.module";
import { FormHeaderModule } from "./components/form-header/form-header.module";
import { ColorPickerMaestroModule } from "./components/form/color-picker-maestro/color-picker-maestro.module";
import { DatePickerModule } from "./components/form/date-picker/date-picker.module";
import { DropDownModule } from "./components/form/drop-down/drop-down.module";
import { FormLabelModule } from "./components/form/form-label/form-label.module";
import { FormTableModule } from "./components/form/table/form-table.module";
import { MaestroHeaderModule } from "./components/maestro-header/maestro-header.module";
import { TreeTagModule } from "./components/massAction/tree/tag/treeTag.module";
import { ModalHistoryModule } from "./components/modal-history/modal-history.module";
import { ElementNoFieldDataComponentModule } from "./components/no-field-data/element-no-field-data.module";
import { OrderDataModule } from "./components/order-data/order-data.module";
import { PanelProgressBarModule } from "./components/panel-progress-bar/panel-progress-bar.module";
import { SideBarModule } from "./components/sidebar/sidebar.module";
import { StepperModule } from "./components/stepper/stepper.module";
import { TableModule } from "./components/table/table.module";
import { TextEditorModule } from "./components/text-editor/text-editor.module";
import { ToastModule } from "./components/toast/toast.module";
import { ViewButtonsModule } from "./components/view-buttons/view-buttons.module";
import { WorkflowModule } from "./components/workflow/workflow.module";

@NgModule({
    exports: [
        DatePickerModule,
        DropDownModule,
        FormLabelModule,
        StepperModule,
        TableModule,
        WorkflowModule,
        ActionButtonsModule,
        ViewButtonsModule,
        TextEditorModule,
        BreadcrumbModule,
        SideBarModule,
        ToastModule,
        MaestroHeaderModule,
        FormHeaderModule,
        ElementNoFieldDataComponentModule,
        CropperModule,
        ModalHistoryModule,
        ColorPickerMaestroModule,
        DatatableModule,
        DataviewModule,
        FormTableModule,
        TreeTagModule,
        EditFormModule,
        AlertMessageModule,
        PanelProgressBarModule,
        OrderDataModule,
    ],
})
export class SharedComponentsModule {}
