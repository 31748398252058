import { KeyValue } from "@angular/common";
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { TranslateService } from "@ngx-translate/core";
import { AuthService } from "app/core/services/admin/auth/auth.service";
import { DataModelService } from "app/core/services/dam/datamodel.service";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { ElementService } from "app/core/services/pim/element.service";
import { TagService } from "app/core/services/tag/tag.service";
import { ThumbnailsService } from "app/core/services/thumbnails/thumbnails.service";
import { ThumbButtons } from "app/shared/helpers/thumb-buttons";
import { MaestroTags } from "app/shared/models";
import { ACL } from "app/shared/models/acl";
import { Thumb } from "app/shared/models/thumb";
import { LazyLoadEvent, SelectItem } from "primeng-lts/api";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { TreeTagComponent } from "../massAction/tree/tag/treeTag.component";
import { PreviewComponent } from "./preview/preview.component";

@Component({
    selector: "app-dataview",
    templateUrl: "./dataview.component.html",
    styleUrls: ["./dataview.component.scss"],
})
export class DataviewComponent implements OnInit, OnDestroy {
    @Input() dataviewContent: Thumb[] = []; // @TODO : Create a model ?
    @Input() lazy: boolean = true; // Enable lazy loading
    @Input() paginator: boolean = true; // Enable pagination
    @Input() rows: number = 12; // Number of row displayed
    @Input() totalRecords: number;
    @Input() showCurrentPageReport: boolean = true; // Display 'currentPageReportTemplate' set in HTML
    @Input() layout: string = "grid"; // Default display format
    @Input() rowsPerPageOptions: number[] = [12, 24, 48, 96]; // Define a range of how many data to load
    @Input() searchBar: boolean = true; // Display the serach bar
    @Input() searchDataParameter: string = "media"; // Display the entry in search input
    @Input() filterBy: string = "name"; // Default search bar parameter to filter
    @Input() sortOptions: SelectItem[] = []; // Sort data based on media fields
    @Input() mediaParam: Thumb;

    sortKey: string; // Used by dataview to sort
    sortField: string; // Used by dataview to sort
    sortOrder: number; // Used by dataview to sort

    @Input() buttons: ThumbButtons; // DAM & PIM
    @Input() type: string; // DAM & PIM
    @Input() filtered: boolean = false; // DAM & PIM
    @Input() titleNotTooltip: string; // PIM (if "title" is provided it will display a tooltip)

    @Input() cartLength: number;
    @Input() filters: any = {}; // An object that contains properties with data linked to columns
    @Input() minified: boolean = false; // Simplify interface
    @Input() selectable: boolean = false;
    @Input() poppedUp: boolean = false; // Avoid weird behaviors on destroy when dataview called in modal
    @Input() selectedMedias: number[] = [];
    @Input() linkedMedias: any[] = [];

    private eventsSubscription: Subscription;
    @Input() events: Observable<Thumb[]>;

    @Input() first: number = 0;
    @Input() singleSelection: boolean = false;
    @Input() linkAdded: boolean = false;

    @Output() lazyEvent: EventEmitter<LazyLoadEvent> = new EventEmitter(); // DAM & PIM
    @Output() searchEvent: EventEmitter<any> = new EventEmitter(); // DAM & PIM
    @Output() tagFiltered: EventEmitter<any> = new EventEmitter(); // DAM
    @Output() addCart: EventEmitter<Thumb> = new EventEmitter(); // DAM
    @Output() tagRemoved: EventEmitter<void> = new EventEmitter(); // DAM
    @Output() thumbUpdateEvent = new EventEmitter<number>(); // DAM
    @Output() deleted = new EventEmitter<number>(); // DAM
    @Output() unlinked: EventEmitter<number> = new EventEmitter(); // PIM
    @Output() massTagStart: EventEmitter<any> = new EventEmitter(); // Emitted when add or remove tag
    @Output() massTagEnd: EventEmitter<any> = new EventEmitter(); // Emitted when focus out modal
    @Output() selectMedia: EventEmitter<any> = new EventEmitter();
    @Output() selectExtension: EventEmitter<any> = new EventEmitter();
    @Output() openCrop: EventEmitter<any> = new EventEmitter();
    @Output() filterExpireMedia: EventEmitter<any> = new EventEmitter();
    @Output() reorderMedia: EventEmitter<any> = new EventEmitter();

    @ViewChild(PreviewComponent, { static: true }) private previewComponent: PreviewComponent;
    @ViewChild("treeTag") treeTag: TreeTagComponent;

    mediasFilter: any = {
        // PIM // @TODO: Create model
        pictures: [],
        videos: [],
        documents: [],
        audios: [],
        pages: [],
        drawings: [],
    };

    state: any = [
        {
            label: this.translate.instant("dam.filter.all"),
            value: "all",
        },
        {
            label: this.translate.instant("dam.filter.expire"),
            value: "expire",
        },
        {
            label: this.translate.instant("dam.filter.noExpire"),
            value: "noExpire",
        },
    ];

    dataModels = [];
    allTags = [];
    currentIndex = 0;

    massTagActive: boolean = false; // Determine if we reload datatable data when modal is hide

    acl: ACL;

    constructor(
        private _thumbnailService: ThumbnailsService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _layout: LayoutService,
        private _tagService: TagService,
        private _damService: DataModelService,
        private _authService: AuthService,
        private translate: TranslateService,
        private _elementService: ElementService
    ) {}

    ngOnInit(): void {
        this.acl = this._authService.getUserAclFromToken();

        if (this.type === "dam") {
            this._damService
                .getDataModels()
                .pipe(map((r) => r.data))
                .subscribe((dms) => {
                    this.dataModels = dms;
                });

            this._tagService.setModulePath("dam");
            this._tagService
                .getAllTags()
                .pipe(map((r: any) => r.data))
                .subscribe((tags: MaestroTags) => {
                    this.allTags = tags.map((t) => ({ id: t.id, name: t.name }));
                });

            this._thumbnailService.media.subscribe((data) => {
                if (data) {
                    this.openModal(data);
                }
            });
        } else if (this.type === "pim") {
            this.createMediasFilter(this.dataviewContent);
            this.eventsSubscription = this.events.subscribe((data) => this.createMediasFilter(data));
        }
    }

    ngOnDestroy(): void {
        const control = this.type === "pim";

        if (!this.poppedUp && !control) {
            this._layout.sidebar.enable = false;
            this._layout.actionButton.title = null;
            this._layout.actionButton.enable = false;
        }

        if (control) {
            this.eventsSubscription.unsubscribe();
        }
    }

    onSortChange(event) {
        let value = event.value;

        if (value.indexOf("!") === 0) {
            this.sortOrder = -1;
            this.sortField = value.substring(1, value.length);
        } else {
            this.sortOrder = 1;
            this.sortField = value;
        }
    }

    previousMedia(): void {
        if (this.currentIndex > 0) {
            this.previewComponent.media = this.dataviewContent[--this.currentIndex];
            this.previewComponent.update();
        }
    }

    nextMedia(): void {
        if (this.currentIndex < this.dataviewContent.length - 1) {
            this.previewComponent.media = this.dataviewContent[++this.currentIndex];
            this.previewComponent.update();
        }
    }

    openModal(media: Thumb): void {
        this.currentIndex = this.dataviewContent.indexOf(media);
        this.previewComponent.media = media;
        this.previewComponent.open();
    }

    private onCompare(_left: KeyValue<any, any>, _right: KeyValue<any, any>): number {
        // Keep original order from '*ngFor' with '| keyvalue' // @TODO: Do it global
        return -1;
    }

    massTagSelection(event: any): void {
        this.massTagActive = true;
        this.massTagStart.emit(event);
    }

    massTagOpen() {
        const that = this; // Need to change scope since it will focus html and not ts
        return $("#appTreeTagModal").on("hide.bs.modal", function () {
            that.treeTag.massTagNode = [];
            that.treeTag.propagateSelectionUp = false;
            that.treeTag.propagateSelectionDown = false;
            that.treeTag.massTagAdd = true;

            that.massTagClose();
        });
    }

    massTagClose(): void {
        if (this.massTagActive) {
            this.massTagEnd.emit();
            this.massTagActive = false;
        }
    }

    createMediasFilter(data: Thumb[]): void {
        if (this.filtered) {
            // PIM
            this.mediasFilter.pictures = data.filter((image: Thumb) => image.type == "image");
            this.mediasFilter.documents = data.filter((document: Thumb) => document.type == "misc");
            this.mediasFilter.videos = data.filter((video: Thumb) => video.type == "video");
            this.mediasFilter.drawings = data.filter((drawing: Thumb) => drawing.type == "drawing");
            this.mediasFilter.audios = data.filter((audio: Thumb) => audio.type == "audio");
            this.mediasFilter.pages = data.filter((page: Thumb) => page.type == "page");
        }
    }

    reorderMediaPosition(event: any) {
        const mediaTypeHelperMediasFilter = {
            // Used to match scoped object and helper (avoid to break use cases)
            image: "pictures",
            video: "videos",
            misc: "documents",
            audio: "audios",
            page: "pages",
            drawing: "drawings",
        };

        for (const [key, val] of Object.entries(this.mediasFilter)) {
            if (key !== mediaTypeHelperMediasFilter[event.key]) {
                const cleanedData = this._elementService.manageMedia(false, this.mediasFilter[key]);
                event.allMedias = [...event.allMedias, ...cleanedData];
            }
        }

        this.reorderMedia.emit(event);
    }
}
