import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { faSave } from "@fortawesome/free-regular-svg-icons";
import { faEdit, faTimes } from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "app/core/services/admin/user/user.service";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { SpinnerService } from "app/core/services/global/spinner/spinner.service";
import { ToastService } from "app/core/services/global/toast/toast.service";
import { ElementTypeService } from "app/core/services/pim/element-type.service";
import { TabService } from "app/core/services/pim/tab.service";
import { SidebarRoutingComponent, SidebarRoutingOptions } from "app/shared/components/sidebar";
import { ACL } from "app/shared/models/acl";
import { ElementType } from "app/shared/models/pim/element-type.model";
import { MAESTRO_ROUTES } from "app/shared/routes";
import { Subject, Subscription } from "rxjs";
import { map, takeUntil } from "rxjs/operators";

@Component({
    selector: "app-element-type-update",
    templateUrl: "./element-type-update.component.html",
})
export class ElementTypeUpdateComponent implements OnInit, OnDestroy {
    elementType: ElementType;
    updateName: boolean;
    nameAtOpening: string;

    noFieldMessage = this._translate.instant("pim.elementTypes.empty");
    noSelectMessage = this._translate.instant("pim.elementTypes.select");
    sidebarOption: SidebarRoutingOptions;

    hasTabs: boolean;
    hasSelected: boolean;

    readonly faEdit = faEdit;
    readonly faClose = faTimes;
    readonly faSave = faSave;

    private readonly _baseRoute = `/${MAESTRO_ROUTES.pim.base}/${MAESTRO_ROUTES.pim.elementType}/${MAESTRO_ROUTES.actions.update}`;

    private _ngUnsubscribe: Subject<void>;
    onFieldChangeTab: Subscription;

    constructor(
        private _elementTypeService: ElementTypeService,
        private _tabService: TabService,
        private _layout: LayoutService,
        private _route: ActivatedRoute,
        private _router: Router,
        private _translate: TranslateService,
        private _toaster: ToastService,
        private _spinner: SpinnerService,
        private _modal: SwalModalService,
        private _userService: UserService,

    ) {
        this._ngUnsubscribe = new Subject();
        this.hasTabs = false;
        this.hasSelected = false;
        this.updateName = false;
        this.onFieldChangeTab = this._elementTypeService.onFieldChangeTab$.subscribe(($event) => {
            this.onFieldChangeTabAction($event);
        });
    }

    ngOnInit(): void {
        /**
         * Get data from the resolver
         */
        this._elementTypeService.isFormValid.next(true);
        this._route.data.pipe(takeUntil(this._ngUnsubscribe)).subscribe((data: { resources: ElementType }) => {
            this.elementType = data.resources;
            this.nameAtOpening = this.elementType.name;

            this._layout.breadcrumb.setPath({ routerLink: "/pim/element_type", name: "pim.elementTypes.title" }, 1);
            this._layout.breadcrumb.setPath({ routerLink: null, name: this.elementType.name }, 2);
            this._elementTypeService.currentElementType.next(this.elementType);
            if (this.elementType.tabs.length > 0) {
                this.hasTabs = true;
                this._setCurrentTab(this.elementType.tabs[0]);
                this._router.navigate([this.elementType.tabs[0].id], { relativeTo: this._route });
            }
            this._initSideBar();

        });

        /**
         * When a tab is updated, update his fields
         */
        this._elementTypeService.tabUpdated.pipe(takeUntil(this._ngUnsubscribe)).subscribe((tab) => {
            this.elementType.tabs.find((t) => t.id === tab.id).fields = tab.fields;
        });

    }

    ngOnDestroy(): void {
        this._layout.breadcrumb.setPath(null, 1);
        this._layout.breadcrumb.setPath(null, 2);
        this._layout.sidebar.enable = false;
        this._layout.sidebar.sideBarDef = null;
        this._ngUnsubscribe.next();
        this._ngUnsubscribe.complete();
        if (this.onFieldChangeTab) {
            this.onFieldChangeTab.unsubscribe();
        }
    }

    /**
     * Save name of the data model
     */
    saveName() {
        this._spinner.disable();
        this._elementTypeService.changeName(this.elementType.id, this.elementType.name).subscribe((data) => {
            this.updateName = false;
            this.nameAtOpening = this.elementType.name;
            this._layout.breadcrumb.setPath({ routerLink: null, name: this.elementType.name }, 2);
            this._toaster.show({
                message: this._translate.instant("pim.elementTypes.elementTypeNameSaved"),
                type: "success",
            });
            this._spinner.activate();
        });
    }

    /**
     * Init the sidebar with create/update form, delete and tabs
     */
    private _initSideBar() {
        this._layout.sidebar.enable = true;

        const acl: ACL = this._userService.getUserAclFromToken();

        this._layout.sidebar.sideBarDef = {
            component: SidebarRoutingComponent,
            options: <any>{
                absoluteRoute: this._baseRoute + "/" + this.elementType.id,
                data: this.elementType.tabs,
                dataDontMove: [],
                editable: true,
                aclOptions: {
                    delete: acl.SYSPAD_PIM_TAB_DELETE,
                    create: acl.SYSPAD_PIM_TAB_CREATE,
                    edit: acl.SYSPAD_PIM_TAB_UPDATE,
                },
                clicked: (tab: any) => this.isChildTabValid(tab),
                validFormSubmit: (tab: any) => this._onSubmit(tab),
                move: (tabIds: number[]) => this._onMove(tabIds),
                delete: () => this._onDelete(),
            },
        };
    }

    /**
     * Reorder tabs positions
     *
     * @param tabIds
     */
    private _onMove(tabIds: number[]): void {
        this._tabService.updateTabPosition(this.elementType.id, tabIds).subscribe(
            () => {
                this._toaster.show({ type: "success", message: this._translate.instant("general.saved") });
            },
            () => {
                this._toaster.show({ type: "danger", message: this._translate.instant("general.notSaved") });
            }
        );
    }

    /**
     * Delete a tab
     */
    private _onDelete(): void {
        this._modal.delete().then((result) => {
            if (result.value) {
                const currentTab = this._elementTypeService.currentTab.value;
                this._tabService.deleteTypeTab(currentTab.id).subscribe(
                    () => {
                        this.elementType.tabs = this.elementType.tabs.filter((t) => t.id !== currentTab.id);
                        this.reInitSidebar()
                        if (this.elementType.tabs.length > 0) {
                            this._setCurrentTab(this.elementType.tabs[0]);
                        } else {
                            this._router.navigate(["/pim", "element_type", "update", this.elementType.id], { replaceUrl: true });
                        }
                        this._toaster.show({ type: "success", message: this._translate.instant("general.saved") });
                    },
                    () => {
                        this._toaster.show({ type: "danger", message: this._translate.instant("general.notSaved") });
                    }
                );
            }
        });
    }


    private reInitSidebar(tab = null) {

        this._layout.sidebar.enable = false;
        this._layout.sidebar.sideBarDef = null;
        this._initSideBar()
    }

    /**
     * Update a tab
     *
     * @param tab
     */
    private _onSubmit(tab: any): void {
        let findNameTab = this.elementType.tabs.find((t) => t.name === tab.name);

        if (findNameTab) {
            this._layout.sidebar.errorSubmit = true;
            this._toaster.show({ type: "danger", message: this._translate.instant("general.notSaved") });
        } else {
            if (!tab.id) {
                this._tabService
                    .createTypeTab(this.elementType.id, tab.name)
                    .pipe(map((r: any) => r.data))
                    .subscribe(
                        (data) => {
                            this.elementType.tabs.push({ id: data, name: tab.name, fields: [] });
                            this._toaster.show({ type: "success", message: this._translate.instant("general.saved") });
                            this.reInitSidebar({ id: data, name: tab.name, fields: [] });
                            this._layout.sidebar.currentTabName = tab.name;

                        },
                        () => {
                            this._toaster.show({ type: "danger", message: this._translate.instant("general.notSaved") });
                        }
                    );
            } else {
                this._tabService
                    .updateTypeTab(this._elementTypeService.currentTab.value.id, tab.name)
                    .subscribe(
                        () => {
                            let index = this.elementType.tabs.map((item) => item.id).indexOf(this._elementTypeService.currentTab.value.id);
                            this.elementType.tabs[index].name = tab.name;
                            this._toaster.show({ type: "success", message: this._translate.instant("general.saved") });
                            this.reInitSidebar();

                            this._layout.sidebar.currentTabName = tab.name;

                        }
                    );
            }
        }


    }
    /**
     * Set the current tab
     * @param tab
     */
    private _setCurrentTab(tab: any): void {
        this._elementTypeService.currentElementType.next(this.elementType);
        this._elementTypeService.currentTab.next(tab);
        this.hasSelected = true;
    }

    private isChildTabValid(tab: any) {
        if (!this._elementTypeService.isFormValid.value) {
            this._elementTypeService.changeTab.next(tab);
            this.hasSelected = true;
        } else {
            this._setCurrentTab(tab);
        }

        this._layout.sidebar.currentTabName = this._elementTypeService.currentTab.value.name;
    }

    onFieldChangeTabAction(event) {
        let oldTab = this.elementType.tabs.find((t) => {
            return t.id == event.oldTabId;
        });

        let field = oldTab.fields.find((f) => {
            return f.id == event.fieldId;
        });

        var index = oldTab.fields.indexOf(field);

        if (index !== -1) {
            oldTab.fields.splice(index, 1);
        }

        let newTab = this.elementType.tabs.find((t) => {
            return t.id == event.tabId;
        });

        let pushed = false;

        if (event.sectionId) {
            let search = false;

            newTab.fields.forEach((f, key) => {
                if (f.id == event.sectionId) {
                    search = true;
                }

                if (search && f.id != event.sectionId && f.fieldType == "section") {
                    newTab.fields.splice(key, 0, field);
                    search = false;
                    pushed = true;
                    return;
                }
            });
        }

        if (false == pushed) {
            newTab.fields.push(field);
        }

        // this._setCurrentTab(newTab); // Change tab but doesn't change in sidebar
    }
}
