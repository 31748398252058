import { NgModule } from '@angular/core';
import { SharedModule } from "app/shared/shared.module";
import { TableModule } from 'primeng-lts/table';
import { MultiSelectModule } from 'primeng/multiselect';
import { ActionButtonsModule } from "../action-buttons/action-buttons.module";
import { TreeTagModule } from '../massAction/tree/tag/treeTag.module';
import { DatatableComponent } from './datatable.component';

@NgModule({
  declarations: [
    DatatableComponent
  ],
  imports: [
    TableModule, MultiSelectModule, SharedModule, ActionButtonsModule, TreeTagModule
  ],
  exports: [
    DatatableComponent
  ]
})
export class DatatableModule { }
