import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup } from "@angular/forms";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { Editor, Toolbar } from "ngx-editor";

@Component({
    selector: "app-text-editor",
    templateUrl: "./text-editor.component.html",
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TextEditorComponent implements OnInit {
    @Input() value: string;

    @Output() focusOut = new EventEmitter<string>();
    @Output() historyClick = new EventEmitter<void>();

    @Input() showHistory = false;
    @Input() disabled = false;
    @Input() closeInput:boolean = false;

    @Input() keepActive = true;

    @Input() fieldId = "";
    @Input() focus = false;
    editor: Editor;

    isEditorActive = false;

    @Input() pluginActivated = false;

    showCode = false;

    toolbar: Toolbar = [
        ["bold", "italic"],
        ["underline", "strike"],
        ["code", "blockquote"],
        ["ordered_list", "bullet_list"],
        [{ heading: ['h1', 'h2', 'h3', 'h4', 'h5', 'h6'] }],
        //["sub","sup"],
        // [{ script: 'sub'}, { script: 'super' }],
        ['link'],
        //['link', 'image'],
        //['text_color', 'background_color'],
        //['align_left', 'align_center', 'align_right', 'align_justify'],
    ];

    form: FormGroup;

    textAreaFormControl = new FormControl();

    private _originValue: string;

    constructor(private _fb: FormBuilder, private _sanitizer: DomSanitizer, private _changeDetector: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.form = this._fb.group({
            editorContent: [this.value || ""],
        });
        this.editor = new Editor({
            history: true,
            inputRules: true,
            keyboardShortcuts: true,
        });

        this._originValue = this.value;
    }

    ngOnDestroy(): void {
        this.editor.destroy();
    }

    emitOut(): void {
        const value = this.form.value.editorContent;
        
        if (value !== this._originValue) {
            this._originValue = value; //@TODO Do it only if the value is saved
            this.focusOut.emit(value);
        } 
    }


    openEditor(): void {
        if (!this.disabled && !this.keepActive) {
            setTimeout(() => {
                this.editor = new Editor({
                    history: true,
                    keyboardShortcuts: true,
                    inputRules: true,
                });
                this.isEditorActive = true;
                this._changeDetector.markForCheck();
            });
        }
    }

    closeEditor(): void {
        if (this.editor) {
            this.editor.destroy();
        }
        this.isEditorActive = false;
    }

    getContent(): SafeHtml {
        return this._sanitizer.bypassSecurityTrustHtml(this.form.value.editorContent);
    }

    insertTrident(): void {
        this.editor.commands.insertText("Ψ").exec();
    }

    insertInsec(): void {
        this.editor.commands.insertText("→").exec();
    }

    insertCarre(): void {
        this.editor.commands.insertText("■").exec();
    }

    insertSautColonne(): void {
        this.editor.commands.insertText("⇔").exec();
    }

    insertTriangle(): void {
        this.editor.commands.insertText("▲").exec();
    }

    insertRetour(): void {
        this.editor.commands.insertText("¬").exec();
    }

    insertBulle(): void {
        this.editor.commands.insertText("• ").exec();
    }

    showSource(): void {
        this.showCode = !this.showCode;
    }

    getWordsNumber(): number {
        const splitted = this.form.value.editorContent
            .replace(/<\/?[^>]+(>|$)/g, "")
            .trim()
            .split(" ");
        if (splitted[0] === "") {
            return 0;
        }
        return splitted.length;
    }

    getCharactersNumber(): number {
        return this.form.value.editorContent.replace(/<\/?[^>]+(>|$)/g, "").trim().length;
    }

    updateWord(): void {
        this._changeDetector.markForCheck();
    }

    updateSource(event): void{
        let value = event['originalTarget']['value'];
        this.form.controls.editorContent.setValue(value);
        this.emitOut();
    }

    setFocus(editor) {
        if(this.focus){            
            setTimeout(() => {
                editor.focus()
                document.getElementById(this.fieldId).focus();                 
            },100);  
        }
    }
}
