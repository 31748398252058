import { HttpClient } from "@angular/common/http";
import { EventEmitter, Injectable } from "@angular/core";
import { MaestroElementType, MaestroElementTypes, Tabs } from "app/shared/models";
import { ElementType } from "app/shared/models/pim/element-type.model";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { environment } from "src/environments";

@Injectable()
export class ElementTypeService {
    currentElementType = new BehaviorSubject<ElementType>(null);
    currentTab = new BehaviorSubject<any>(null);
    filterSearch: BehaviorSubject<string> = new BehaviorSubject("");
    isFormValid: BehaviorSubject<boolean> = new BehaviorSubject(true);
    changeTab: BehaviorSubject<any> = new BehaviorSubject(null);

    tabUpdated = new EventEmitter<any>(null);
    onFieldChangeTab$ = new Subject<any>();

    constructor(private _http: HttpClient) {}

    /**
     * Get element types to display in the sidebar
     * @returns
     */
    getSidebarTypes(): Observable<any> {
        return this._http.get(`${environment.pimUrl}/sidebar_element_type.json`);
    }

    /**
     * Get element types
     * @returns
     */
    getElementTypes(): Observable<MaestroElementTypes> {
        return this._http.get<MaestroElementTypes>(`${environment.pimUrl}/element_type.json`);
    }

    /**
     * Get a data model
     * @param id
     * @returns
     */
    getElementType(id: number): Observable<MaestroElementType> {
        return this._http.get(`${environment.pimUrl}/element_type/${id}.json`);
    }

    /**
     * Get tabs for an data model
     * @param id
     * @returns
     */
    getTypeTabs(id: number): Observable<Tabs> {
        return this._http.get<Tabs>(`${environment.pimUrl}/element_type/${id}/tabs.json`);
    }

    /**
     * Create an Data model
     * @param elementTypeName
     * @param file
     * @returns
     */
    createElement(elementTypeName: string, file: File): Observable<{ id: number }> {
        const formData = new FormData();
        formData.append("name", elementTypeName);
        formData.append("file", file);

        return this._http.post<any>(`${environment.pimUrl}/element_type.json`, formData);
    }

    /**
     * Delete a Data Model
     * @param elementTypeId
     * @returns
     */
    deleteType(elementTypeId: number): Observable<any> {
        return this._http.delete(`${environment.pimUrl}/element_type/${elementTypeId}.json`);
    }

    /**
     * Update the model for an elementType
     *
     * @param elementId
     * @param tabId
     * @param any
     * @returns
     */
    updateFormField(elementId: number, tabId: number, any: any): Observable<any> {
        return this._http.put(`${environment.pimUrl}/element_type/${elementId}/${tabId}.json`, any);
    }

    /**
     * Update the name of a data model
     * @param id
     * @param name
     * @returns
     */
    changeName(id: number, name: string) {
        return this._http.post(`${environment.pimUrl}/element_type/${id}/name.json`, { name: name });
    }

    /**
     * Change the current tab
     * @param tab
     */
    setElementTypeTab(tab: any): void {
        this.tabUpdated.emit(tab);
    }

    /**
     * Get an data model by id
     * @param id
     * @returns
     */
    getById(id: number): Observable<any> {
        return this._http.get<any>(`${environment.pimUrl}/element_type/${id}.json`);
    }

    /**
     *
     * @param elementId Get All element_type story
     * @param start
     * @returns
     */
    getStory(elementId: number, start: number): Observable<any> {
        return this._http.post(`${environment.pimUrl}/element_type/${elementId}/story.json`, { start: start });
    }

    importFromFile(file: any) {
        return this._http.post(`${environment.pimUrl}/import/element_type.json`, file);
    }
}
