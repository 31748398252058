import { IMaestroEntity } from "./_maestro.entity";

export class VersionWTF {
    // don't use this anymore
    element_id: number;
    version_id?: number;
    version?: string;
}

export type MaestroVersion = IMaestroEntity;
// export interface MaestroVersion extends IMaestroEntity {}

export type MaestroVersions = MaestroVersion[];
