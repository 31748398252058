import { BreakpointObserver, Breakpoints, BreakpointState } from "@angular/cdk/layout";
import { ChangeDetectorRef, Component, ElementRef, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { ActivatedRoute, Router } from "@angular/router";
import { faTimes, IconDefinition } from "@fortawesome/free-solid-svg-icons";
import { TranslateService } from "@ngx-translate/core";
import { SwalComponent } from "@sweetalert2/ngx-sweetalert2";
import { UserService } from "app/core/services/admin/user/user.service";
import { LayoutService } from "app/core/services/global/layout/layout.service";
import { SwalModalService } from "app/core/services/global/modal/modal.service";
import { TableService } from "app/core/services/global/table/table.service";
import { TreeService } from "app/core/services/global/tree/tree.service";
import { ElementTypeService } from "app/core/services/pim/element-type.service";
import { ElementService } from "app/core/services/pim/element.service";
import { TagService } from "app/core/services/tag/tag.service";
// import { ElementExportService } from "app/core/services/pim/export.service";
import { ActionButtonsDef, ActionEvent, ActionType } from "app/shared/components/action-buttons";
// import { ViewType } from "app/shared/components/view-buttons";
import { DataService } from "app/core/services/global/data/data.service";
import { ToastService } from "app/core/services/global/toast/toast.service";
import { MaestroElements, MaestroElementTypes, MaestroTags } from "app/shared/models";
import { ACL } from "app/shared/models/acl";
import { LazyLoadDataModel } from "app/shared/models/lazy-load-data";
import { ElementListResources } from "app/shared/models/pim/pim-home-resources";
import { UnsubscribePipe } from "app/shared/pipes";
import { MAESTRO_ROUTES } from "app/shared/routes";
import { environment } from "environments/environment";
import { FilterMetadata, LazyLoadEvent, TableState } from "primeng-lts/api";
import { Subscription } from "rxjs";
import Swal from "sweetalert2";
declare var jQuery: any;

@Component({
    selector: "app-element-list",
    templateUrl: "./element-list.component.html",
})
export class ElementListComponent implements OnDestroy, OnInit {
    faTimes: IconDefinition = faTimes;
    previewElements: MaestroElements = [];
    elementSelect: any[] = [];
    filterTypes: MaestroElementTypes; // Current selected
    filterTags: MaestroTags; // Current selected
    filterVersions: MaestroTags; // Current selected
    history: any;
    showDiff: boolean;
    startHistory: number;
    elementStory: number;

    private acl: ACL;
    affLink: boolean;

    readonly actions: ActionButtonsDef = [];

    // readonly views: ViewButtonsDef = [{ type: ViewType.List }, { type: ViewType.Preview }]; // @TODO : Define if ViewType.Preview is really useful (true : rework how datatable will display data; false : remove useless code)

    private _subscriptions: Subscription[] = [];
    // private viewType: ViewType;
    private canEdit = false;

    @ViewChild("historyModal", { static: true }) private swal: SwalComponent;

    tableRows: any[] = [];
    tableColumns: any[] = [];
    totalRecords: number = 0;
    recordsFiltered: number = 0;
    currentDatatableFilters: LazyLoadEvent = {}; // Use to reload data when filter with tag/element type & action like duplicate/delete
    globalFilterFields: string[] = []; // Define which columns can be use to search data
    selectColumns: boolean = true; // Display a multiselect to show/hide columns
    _selectedColumns: any[] = []; // Define which columns are selected
    exportFilename: string = "maestro_pim_export_element";
    subData = {}; // Contains the sub-data of sub-method displayed in expanded rows by parent id
    subDataType: string = "medias";
    allFilters = {
        filterTags: {
            data: [],
            cascadeSelect: true,
            field: "",
        },
        filterTypes: {
            data: [],
            cascadeSelect: false,
            field: "",
        },
        filterSalabilityIndicator: {
            data: [
                { id: "false", name: this._translate.instant("table.unvalid") },
                { id: "true", name: this._translate.instant("table.valid") },
            ],
        },
        filterVersions: {
            data: [],
            cascadeSelect: false,
            field: "",
        },
    };
    preFilter: any;
    currentCustomFilters: any = {}; // Use to reload with the right filters
    shortPaginatorIsFirstPage: boolean = true;
    shortPaginatorIsLastPage: boolean = false;
    subDataQuantity: number = 13; // Let to manage how many sub data are displayed on resize
    currentSubObjectId: number;
    nodeSelected: boolean = true; // Used to know in prefilter case if selected or not

    strictMode: boolean = false; // strict value given by datatable
    searchOnFieldValue: boolean = false; // search on element value and list value given by datatable
    searchOnMedia: boolean = false; // Use to search on DAM medias

    @ViewChild("createModal", { static: false }) createModal: ElementRef;
    chips: string[] = [];
    elementsSeparator: "";
    elementTypes = [];

    private importButtonSource: Subscription;

    constructor(
        private _router: Router,
        private _route: ActivatedRoute,
        private _translate: TranslateService,
        private _modalService: SwalModalService,
        private _unsubscribePipe: UnsubscribePipe,
        private _layout: LayoutService,
        // private _exportElement: ElementExportService,
        private _sanitizer: DomSanitizer,
        private _elementService: ElementService,
        private _tagService: TagService,
        private _treeService: TreeService,
        private _tableService: TableService,
        public breakpointObserver: BreakpointObserver,
        private cdRef: ChangeDetectorRef,
        private _userService: UserService,
        private _elementTypeService: ElementTypeService,
        private _dataService: DataService,
        private _toastService: ToastService
    ) {}

    ngOnInit(): void {
        this.currentDatatableFilters.first = 0;
        this.affLink = false;
        this.setBreakPointObserver();

        this.acl = this._userService.getUserAclFromToken();
        this._layout.breadcrumb.setPath({ routerLink: null, name: "breadcrumb.pim.element.title" }, 1);

        this._elementService.filterTypes.subscribe((ft) => (this.filterTypes = ft));
        this._elementService.filterTags.subscribe((ft) => (this.filterTags = ft));
        this._elementService.filterVersions.subscribe((ft) => (this.filterVersions = ft));
        this._elementService.filterSalabilityIndicator.subscribe((filter) => {});

        if (this.acl.SYSPAD_PIM_ELEMENT_CREATE) {
            this.actions.push({ type: ActionType.Duplicate, right: "SYSPAD_PIM_ELEMENT_CREATE" });
            this._layout.actionButton.enable = true;
            this._layout.actionButton.title = "pim.element.create";
            this._subscriptions.push(this._layout.actionButton.click$.subscribe((_) => this._openCreateModal()));

            this._layout.importButton.enable = true;
            this._layout.importButton.title = "general.import";
            this.importButtonSource = this._layout.importButton.click$.subscribe((_) => this.onImport());
        }

        if (this.acl.SYSPAD_PIM_ELEMENT_UPDATE) {
            this.affLink = true;
            this.actions.push({ type: ActionType.FormUpdate, right: "SYSPAD_PIM_ELEMENT_UPDATE" });
            this.canEdit = true;
        }

        if (this.acl.SYSPAD_PIM_ELEMENT_READ) {
            this.actions.push(
                { type: ActionType.View, right: "SYSPAD_PIM_ELEMENT_READ" }
                //{ type: ActionType.Export, right: "SYSPAD_PIM_ELEMENT_READ" }, // @TODO : To rework
                //{ type: ActionType.Story, right: "SYSPAD_PIM_ELEMENT_READ"}
            );
            this._layout.viewButtons.enable = true;
            // this._layout.viewButtons.actions = this.views;
            // this._layout.viewButtons.default = this.views[0];
            // this._layout.viewButtons.click$.subscribe((value) => this.onViewSwitch(value.type)); // Currently disable until reworked
        }

        if (this.acl.SYSPAD_PIM_ELEMENT_DELETE) {
            this.actions.push({ type: ActionType.Delete, right: "SYSPAD_PIM_ELEMENT_DELETE" });
        }

        this._initData(this._route.snapshot.data.resources);

        this.setCustomFilters({}); // Set preFilter in case no filters selected before pass it to datatable component
        this.cdRef.detectChanges(); // Avoid ExpressionChangedAfterItHasBeenCheckedError
        this.elementsSeparator = this._translate.instant("tags.separator", { data: this._translate.instant("table.product") });
    }

    ngOnDestroy(): void {
        jQuery(this.createModal.nativeElement).modal("hide");
        this._elementService.filterTypes.next([]);
        this._elementService.filterTags.next([]);
        this._elementService.filterVersions.next([]);
        this._elementService.filterSalabilityIndicator.next("");
        this._layout.breadcrumb.setPath(null, 1);
        this._unsubscribePipe.transform(this._subscriptions);
        this._layout.sidebar.enable = false;
        this._layout.actionButton.enable = false;
        this._layout.actionButton.title = null;
        this._layout.viewButtons.enable = false;

        if (this.importButtonSource) {
            this.importButtonSource.unsubscribe();
        }

        this._layout.importButton.enable = false;
        this._layout.importButton.title = null;
    }

    /**
     * Handle action click
     * @param event
     */
    onActionClick(event: ActionEvent) {
        const action = typeof event.action === "object" ? event.action["action"] : event.action;
        const id = event.rowId;
        switch (action) {
            case ActionType.Delete:
                this._delete(id);
                break;
            case ActionType.FormUpdate:
            case ActionType.Update:
                this._router.navigate([MAESTRO_ROUTES.actions.update, id], { relativeTo: this._route });
                break;
            case ActionType.View:
                this._router.navigate([MAESTRO_ROUTES.actions.view, id], { relativeTo: this._route });
                break;
            /**
             * Generate an export (csv, pdf, docx)
             */
            /* 
            case ActionType.Export:
                this._exportElement.generate(id, event.action["export"]); // @TODO : To rework
                break;
            */
            case ActionType.Duplicate:
                Swal.fire({
                    title: this._translate.instant("pim.element.duplicate"),
                    showCancelButton: true,
                    confirmButtonText: this._translate.instant("general.duplication"),
                    cancelButtonText: this._translate.instant("general.cancel"),
                }).then((result) => {
                    if (result.isConfirmed) {
                        this._elementService.duplicateElement(id).subscribe((data) => {
                            if (this.acl.SYSPAD_PIM_ELEMENT_UPDATE) {
                                this._router.navigate([MAESTRO_ROUTES.actions.update, data.data.elementId], { relativeTo: this._route });
                            } else {
                                this.loadList(this.currentDatatableFilters);
                            }
                        });
                    }
                });
                break;
            case ActionType.Story:
                this._elementService.getStory(id, 0).subscribe((data) => {
                    this.getStory(data);
                });
                break;
        }
    }

    /**
     * When selecting an element, set the preview
     *
     * @param event
     */
    onSelect(event: number) {
        if (this.elementSelect.length > 0) {
            this.elementSelect[0] = event;
            this._elementService.getElementPreview(event).subscribe((elements) => {
                this.previewElements[0] = elements;
                document.getElementById("previewPanel").scrollIntoView();
            });
        } else {
            let action = "";
            if (this.canEdit) {
                action = MAESTRO_ROUTES.actions.update;
            } else {
                action = MAESTRO_ROUTES.actions.view;
            }
            this._router.navigateByUrl(`${MAESTRO_ROUTES.pim.base}/${MAESTRO_ROUTES.pim.element}/${action}/${event}`);
        }
    }

    /**
     * Get All Element's Story
     * @param id
     */
    getStory(data: any) {
        this.history = [];
        if (data.type === "success") {
            this.history = data.data.story;
            this.elementStory = data.data.id;
            this.startHistory = 10;
        }
        this.swal.fire();
    }

    /**
     * Sanitize a value
     * @param value
     * @returns
     */
    sanitize(value: string): SafeHtml {
        return this._sanitizer.bypassSecurityTrustHtml(value);
    }

    /**
     * Switch diff functionality
     */
    switchDiff(): void {
        this.showDiff = !this.showDiff;
    }

    /**
     * Delete an element
     *
     * @param id
     */
    private _delete(id: number) {
        if (id) {
            this._elementService.deleteElements([id]).subscribe((data) => {
                this._modalService.success(this._translate.instant("general.deleted"));
                this.loadList(this.currentDatatableFilters);
            });
        }
    }

    /**
     * Init filters with tags & elementTypes
     * @param tags
     * @param elementTypes
     */
    private _initFilters(tags: MaestroTags, elementTypes: MaestroElementTypes, versions: any[]) {
        const nodeTags = this._treeService.createTreeNode(tags, "name", "label");
        this.allFilters.filterTags.data = nodeTags;

        this.allFilters.filterTypes.data = elementTypes;

        versions = versions.map((v) => {
            v.name = v.title;
            return v;
        });

        this.allFilters.filterVersions.data = versions;
    }

    /**
     * Init data
     *
     * @param resources
     */
    private _initData(resources: ElementListResources) {
        this._initFilters(resources.tags, resources.types, resources.versions);
    }

    /**
     * Switch view type (Currently disable until reworked)
     *
     * @param type
     */
    // onViewSwitch(type: ViewType) {
    //     this.viewType = type;
    //     switch (type) {
    //         case ViewType.List:
    //             this.elementSelect = [];
    //             this.previewElements = [];
    //             break;
    //         case ViewType.Preview:
    //             if (this.table[0]) {
    //                 const rowZeroId = +this.table[0][0];
    //                 this.elementSelect[0] = rowZeroId;
    //                 this._elementService.getElementPreview(rowZeroId).subscribe((elements) => {
    //                     this.previewElements[0] = elements;
    //                 });
    //             } else {
    //                 this.viewType = ViewType.List;
    //             }
    //             break;
    //     }
    // }

    /**
     * Load data displayed in datatable
     * @param event
     */
    loadList(event: LazyLoadEvent): void {
        this.managePreFilter(event);

        this.subData = {};

        if (undefined !== event.filters) {
            this.manageFilters(event.filters);
            if (!this.filterVersions.length) {
                let v = this._route.snapshot.data.resources.versions.find((v) => {
                    return v.id == 1;
                });

                if (undefined != v) {
                    this.filterVersions = [v];
                }
            }
            this.setCustomFilters(event.filters);
        }

        const requestFilters: LazyLoadDataModel = {
            lazyLoadFilters: event,
            customFilters: this.currentCustomFilters,
            strictMode: this.strictMode,
            searchOnFieldValue: this.searchOnFieldValue,
            searchOnMedia: this.searchOnMedia,
        };
        this._elementService.getDatatableElements(JSON.stringify(requestFilters)).subscribe((resp) => {
            const data = resp.data;

            this.tableRows = Object.values(data.rows);

            this.tableColumns = Object.values(data.columns);

            this.totalRecords = data.recordsTotal;

            this.recordsFiltered = data.recordsFiltered;

            this.currentDatatableFilters = data.datatableFilters;

            this.setGlobalFilterFields();

            this._selectedColumns = this.tableColumns;

            this.shortPaginatorIsFirstPage = this.currentDatatableFilters.first === 0;
            this.shortPaginatorIsLastPage = this.currentDatatableFilters.first === this.recordsFiltered - this.tableRows.length;
        });
    }

    /**
     * Set global filters from LazyLoadEvent data
     */
    setGlobalFilterFields(): void {
        this.tableColumns.forEach((col) => {
            if (col.hasOwnProperty("field")) {
                if (col.hasOwnProperty("globalFilter") && col.globalFilter) {
                    this.globalFilterFields.push(col.field);
                }

                if (this.selectColumns && col.field) {
                    col.header = this._tableService.translateColumns(col); // Add columns header to display in columns selector
                }

                if (col.hasOwnProperty("filterList") && null !== col.filterList && col.hasOwnProperty("filterField") && null !== col.filterField && this.allFilters.hasOwnProperty(col.filterList)) {
                    this.allFilters[col.filterList].field = col.filterField;
                }
            }
        });
    }

    /**
     * Set custom filters formatted for request send to back
     * @param eventFilters
     */
    setCustomFilters(eventFilters: FilterMetadata): void {
        let customFilters = {};

        if (Object.keys(eventFilters).length) {
            Object.entries(eventFilters).map(([key, value]) => {
                if (this.allFilters.hasOwnProperty(key) && this.allFilters[key].field) {
                    let ids = this[key].map((d) => d.id);
                    customFilters[this.allFilters[key].field] = ids;
                }
            });
        } else {
            if (this.filterTypes.length) {
                const pre = this.filterTypes[0];

                this.filterTypes = this.filterTypes.map((obj) => this.allFilters["filterTypes"].data.find((o) => o.id === obj.id) /*|| obj*/); // Replace incomplete preFilter object with this complete version

                if (undefined !== this.filterTypes[0]) {
                    customFilters["et"] = [pre.id];
                    this.preFilter = {
                        tree: false,
                        type: "filterTypes",
                        data: this.filterTypes,
                    };
                }
            } else if (this.filterTags.length) {
                this.filterTags = this.filterTags.map((obj) => this._treeService.findRecursive(this.allFilters["filterTags"].data, obj.id));

                if (undefined !== this.filterTags[0]) {
                    customFilters["tag"] = this.filterTags.map((p) => p.id);
                    this.preFilter = {
                        tree: true,
                        type: "filterTags",
                        data: this.filterTags,
                    };
                }
            }
        }
        customFilters["tagAsSalabilityIndicator"] = environment.tagAsSalabilityIndicator;

        this.currentCustomFilters = customFilters;
    }

    /**
     * Set the filters received to display it (out of datatable)
     * @param eventFilters
     */
    manageFilters(eventFilters: FilterMetadata): void {
        Object.entries(eventFilters).map(([key, filter]) => {
            if (Array.isArray(filter)) {
                const filterValue = filter[0].value ? filter[0].value : [];
                this[key] = filterValue;
                this._elementService[key].next(this[key]);
            }
        });
    }

    /**
     * Load subdata displayed in datatable
     * @param objectId
     */
    loadSubList(objectId: number | null): void {
        this.currentSubObjectId = objectId;

        if (objectId) {
            this._elementService.getMedias(objectId).subscribe((resp: any) => {
                this.subData[objectId] = resp.data.slice(0, this.subDataQuantity);
            });
        }
    }

    shortPaginatorPrev(): void {
        this.currentDatatableFilters.first = this.currentDatatableFilters.first - this.currentDatatableFilters.rows;
        this.loadList(this.currentDatatableFilters);
    }

    shortPaginatorReset(): void {
        this.currentDatatableFilters.first = 0;
        this.loadList(this.currentDatatableFilters);
    }

    shortPaginatorNext(): void {
        this.currentDatatableFilters.first = this.currentDatatableFilters.first + this.currentDatatableFilters.rows;
        this.loadList(this.currentDatatableFilters);
    }

    massTag(event: any): void {
        this._tagService.manageEntitiesTag("element", event.dataIds, event.objectIds, event.add).subscribe();
    }

    setBreakPointObserver() {
        this.breakpointObserver.observe([Breakpoints.Large, Breakpoints.Medium, Breakpoints.Small, Breakpoints.HandsetPortrait]).subscribe((state: BreakpointState) => {
            if (this.breakpointObserver.isMatched(Breakpoints.XLarge)) {
                this.subDataQuantity = 15;
            } else if (this.breakpointObserver.isMatched(Breakpoints.Large)) {
                this.subDataQuantity = 12;
            } else if (this.breakpointObserver.isMatched(Breakpoints.Medium)) {
                this.subDataQuantity = 9;
            } else if (this.breakpointObserver.isMatched(Breakpoints.Small)) {
                this.subDataQuantity = 6;
            } else if (this.breakpointObserver.isMatched(Breakpoints.XSmall)) {
                this.subDataQuantity = 3;
            }

            if (this.currentSubObjectId && this.subData.hasOwnProperty(this.currentSubObjectId) && this.subData[this.currentSubObjectId].length) {
                this.loadSubList(this.currentSubObjectId);
            }
        });
    }

    managePreFilter(event: LazyLoadEvent): void {
        if (undefined !== this.preFilter) {
            if (!event.hasOwnProperty("filters")) {
                // Usefull when event is based on current lazy load with pre-filter and combined with header paginator (no event.filters in it)
                let transverseEvent: TableState = {}; // Use TableState interface since LazyLoadEvent interface does not provide array FilterMetadata[] implementation
                transverseEvent = Object.assign(transverseEvent, event);
                transverseEvent["filters"] = {};
                let transverseEventData: FilterMetadata[] = [{ value: null }];
                transverseEvent["filters"][this.preFilter.type] = transverseEventData;
                event = Object.assign(event, transverseEvent);
            }

            if (
                event.filters.hasOwnProperty(this.preFilter.type) &&
                this.nodeSelected &&
                (null == event.filters[this.preFilter.type][0].value || !Object.keys(event.filters[this.preFilter.type][0].value).length)
            ) {
                let preFilterCopy = [];

                this.preFilter.data.forEach((pre) => {
                    const copy = { ...pre };
                    copy.parent = null; // Avoid cyclic error
                    copy.children = null; // Avoid cyclic error
                    preFilterCopy.push(copy);
                });

                event.filters[this.preFilter.type][0].value = preFilterCopy; // Set prefilter
            }
        }
    }

    changeAdvancedSearch(event: any): void {
        if (this.hasOwnProperty(event.advancedSearchParam)) {
            this[event.advancedSearchParam] = event.advancedSearchValue;
            if (/*"searchOnFieldValue" == event.advancedSearchValue ||*/ "strictMode" == event.advancedSearchParam && this[event.advancedSearchParam]) {
                this.searchOnMedia = false;
            } else if ("searchOnMedia" == event.advancedSearchParam && this[event.advancedSearchParam]) {
                // this["searchOnFieldValue"] = false;
                this.strictMode = false;
            }
        }
    }

    /**
     * Open create modal
     */
    private _openCreateModal() {
        this._elementTypeService.getElementTypes().subscribe((res: any) => {
            this.elementTypes = res.data;
        });

        jQuery(this.createModal.nativeElement).modal("show");
    }

    create() {
        if (this.chips.length && $("#modalElements").val()) {
            this._elementService.create(this.chips, $("#modalElements").val(), 1 === this.chips.length).subscribe((res: any) => {
                this._modalService.success(this._translate.instant("general.created"));
                jQuery(this.createModal.nativeElement).modal("hide");

                if (1 === this.chips.length) {
                    this._router.navigate([MAESTRO_ROUTES.pim.base, MAESTRO_ROUTES.pim.element, MAESTRO_ROUTES.actions.update, res.data]);
                } else {
                    this.chips = [];

                    this.loadList(this.currentDatatableFilters);
                }
            });
        }
    }

    cleanPrefilters(event: any) {
        if (event.clear && this.preFilter) {
            this.preFilter.data = [];
        }
    }

    onImport() {
        const infos = [this._translate.instant("import.columnInfos.model"), this._translate.instant("import.columnInfos.product")];

        this._modalService.import("import-product-base.xlsx", infos, "product").then((fileData) => {
            if (fileData) {
                this._dataService.convertFile(fileData).subscribe((res: any) => {
                    const file = { filename: fileData.name, mimeType: fileData.type, size: fileData.size, base64: res };

                    this._elementService.importFromFile(file).subscribe((res: any) => {
                        if (res.type === "success") {
                            this._toastService.show({ message: this._translate.instant("import.succeeds"), type: "success" });
                            this._elementService.getElements().subscribe((elements: any) => this.loadList(this.currentDatatableFilters));
                        }
                    });
                });
            }
        });
    }
}
