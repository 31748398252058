    <app-workflow (lastStep)="generatePreview()" *ngIf="display"  [display]="display | workflow"
    (transitionKey)="validWorkflow($event)" [canSetValue]="display.authoSetValue"></app-workflow>
<div class="row mt-2" >
    <div *ngIf="selectedProduct" [@slideIn]="isOpen ? 'slideOpen' : 'slideClose'"  class="col-6" >
        <div *ngFor="let itemTemplate of noSortableProducts;let i = index;">
            <div class="accordion " id="example" [ngClass]="{gabarit: i == 0}" >
                <div class="card" style="overflow: visible">
                    <div class="card-header" id="headingTwo">
                        <h2 class="mb-0">
                            <button (click)="showArrowTemplate = !showArrowTemplate;" class="btn btn-link btn-block text-left" type="button" data-toggle="collapse"
                                attr.data-target="#collapsetwo{{i}}" aria-expanded="true"
                                aria-controls="collapsetwo">
                                <fa-icon data-toggle="collapse"
                                attr.data-target="#collapsetwo{{i}}" aria-expanded="true"
                                aria-controls="collapsetwo" *ngIf="!showArrowTemplate" [icon]="faCaretRight"></fa-icon>
                                <fa-icon data-toggle="collapse"
                                attr.data-target="#collapsetwo{{i}}" aria-expanded="true"
                                aria-controls="collapsetwo" *ngIf="showArrowTemplate" [icon]="faCaretDown"></fa-icon>
                                {{ itemTemplate.name | translate }}
                            </button>
                        </h2>
                    </div>
                    <div id="collapsetwo{{i}}"  class="collapse show" aria-labelledby="headingTwo"
                        data-parent="#example">
                        <div *ngIf="selectedProduct" class="col-12">
                            <ul ngbNav #nav="ngbNav" class="nav-tabs">
                                <li [ngbNavItem]="'text'" *ngIf="this.selectedProduct.values.length > 0">
                                    <a ngbNavLink>
                                        <fa-icon [icon]="faFont"></fa-icon> {{ 'projects.export.text' | translate }}
                                    </a>
                                    <ng-template ngbNavContent>
                                        <app-page-text-tab

                                            [disabled]="(blockTexts || display ? !display.authoSetValue : true)"
                                            [products]="products" [product]="selectedProduct"></app-page-text-tab>
                                    </ng-template>
                                </li>
                                <li [ngbNavItem]="'pictures'" *ngIf="this.selectedProduct.medias.length > 0">
                                    <a ngbNavLink>
                                        <fa-icon [icon]="faFileImage"></fa-icon> {{ 'projects.export.media' |
                                        translate }}
                                    </a>
                                    <ng-template ngbNavContent >
                                        <app-page-image-tab [disabled]="(blockPictures || display ? !display.authoSetValue : true)"
                                            [product]="this.selectedProduct" [places]="this.selectedProduct.medias"
                                            (delete)="deleteMedia($event)"
                                            (select)="updateMedia($event, this.selectedProduct.id)"></app-page-image-tab>
                                    </ng-template>
                                </li>
                                <li [ngbNavItem]="'options'" *ngIf="this.selectedProduct.options.length > 0">
                                    <a ngbNavLink>
                                        <fa-icon [icon]="faCog"></fa-icon> {{ 'projects.export.options' | translate
                                        }}
                                    </a>
                                    <ng-template ngbNavContent>
                                        <app-page-option-tab [product]="this.selectedProduct"></app-page-option-tab>
                                    </ng-template>
                                </li>
                            </ul>

                            <div [ngbNavOutlet]="nav"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div  [appSortable]="sortableJSOptions"  class="row accordion" id="accordionExample" style="position:relative;bottom: 17px;">
            <div class="col-12 gabarit-item " *ngFor="let item of sortableProducts;let index = index;">
                <div>
                    <div class="card">
                        <div class="card-header sortable" id="headingOne">
                            <h2  class="mb-0 d-flex flex-row justify-content-between align-items-center">
                                <button (click)="showArrowProduct = showArrowProduct == index ? -1 : index;" class="btn btn-link btn-block text-left" type="button"
                                    data-toggle="collapse" attr.data-target="#collapseOne{{index}}" aria-expanded="true"
                                    aria-controls="collapseOne{{index}}">
                                    <fa-icon data-toggle="collapse" attr.data-target="#collapseOne{{index}}" aria-expanded="true"
                                    aria-controls="collapseOne{{index}}" *ngIf="showArrowProduct != index" [icon]="faCaretRight"></fa-icon>
                                    <fa-icon data-toggle="collapse" attr.data-target="#collapseOne{{index}}" aria-expanded="true"
                                    aria-controls="collapseOne{{index}}" *ngIf="showArrowProduct == index" [icon]="faCaretDown"></fa-icon>
                                    {{ item.name | translate }}
                                </button>
                            <fa-icon class="cursor" [icon]="faArrowsAlt"></fa-icon>
                            </h2>
                        </div>
                        <div id="collapseOne{{index}}" [ngClass]="{'show' : selectedProduct.id !== null && index == 0 }" class="collapse " aria-labelledby="headingOne"
                            data-parent="#accordionExample">
                            <div *ngIf="selectedProduct" class="col-12">
                                <ul ngbNav #nav="ngbNav" class="nav-tabs">
                                    <li [ngbNavItem]="'text'" *ngIf="item.values.length > 0">
                                        <a ngbNavLink>
                                            <fa-icon [icon]="faFont"></fa-icon> {{ 'projects.export.text' | translate }}
                                        </a>
                                        <ng-template ngbNavContent>
                                            <app-page-text-tab
                                                [disabled]="(blockTexts || display ? !display.authoSetValue : true)"
                                                [products]="products" [product]="item"></app-page-text-tab>
                                        </ng-template>
                                    </li>
                                    <li *ngIf="item.medias.length > 0" [ngbNavItem]="'pictures'">
                                        <a ngbNavLink>
                                            <fa-icon [icon]="faFileImage"></fa-icon> {{ 'projects.export.media' |
                                            translate }}
                                        </a>
                                        <ng-template ngbNavContent >
                                            <app-page-image-tab [disabled]="blockPictures|| !display.authoSetValue"
                                                [product]="item" [places]="item.medias"
                                                (delete)="deleteMedia($event)"
                                                (select)="updateMedia($event, item.id)"></app-page-image-tab>
                                        </ng-template>
                                    </li>
                                    <li [ngbNavItem]="'options'" *ngIf="item.options.length > 0" >
                                        <a ngbNavLink>
                                            <fa-icon [icon]="faCog"></fa-icon> {{ 'projects.export.options' | translate }}
                                        </a>
                                        <ng-template ngbNavContent>
                                            <app-page-option-tab [product]="item"></app-page-option-tab>
                                        </ng-template>
                                    </li>
                                </ul>
                                <div [ngbNavOutlet]="nav"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div [@alert]="isOpen ? 'alertOpen' : 'alertClose'"   [ngClass]="{' mx-5' :isOpen}"  style="height:20%" *ngIf="!selectedProduct" class="text-center alert alert-warning col-lg-5" role="alert">
        {{ 'projects.flatplan.productAssoc' | translate }}
    </div>

    <div class="col-12 col-lg-6 mt-2 mt-lg-0 wrapper" [ngStyle]="{'position': (!fullscreenPreview) ? 'sticky' : ''}"
        style="top: 0">



        <div class="overlay" *ngIf="showSpinner">
            <div class="spinner-wrapper">
                <!-- TODO : Call <app-spinner></app-spinner>-->
                <div class="lds-roller">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        </div>

        <div [@hidePreview]="isOpen ? 'open' : 'closed'"  class="loaded-content" [class.blurred]="showSpinner">
            <!-- preview -->
            <div  class="card preview" [class.fullscreen]="fullscreenPreview">
                <div class="card-header">
                    <span class="card-header-title">
                        <fa-icon [icon]="faFilePdf"></fa-icon> {{ 'projects.export.preview' | translate }}:
                    </span>

                    <span *ngIf="page.template" style="font-size: 15px;">
                        <a href="/projects/templates/{{ page.templateId }}/update" target="_blank"><b><i>{{ page.template }}</i></b></a>
                    </span>
                    <div class="card-header-actions">
                        <div class="btn-group">
                            <ng-container *ngIf="exportType !== 'HTML'">
                                <button class="btn btn-outline-primary" (click)="checkValidityMediaBeforeGenerate('indesign',0)">
                                    <fa-icon [icon]="faSyncAlt"></fa-icon>
                                    <span class="ml-1">{{ "projects.export.generation.ld" | translate }}</span>
                                </button>
                                <button class="btn btn-outline-primary" (click)="checkValidityMediaBeforeGenerate('indesign',1)">
                                    <fa-icon [icon]="faSyncAlt"></fa-icon>
                                    <span class="ml-1">{{ "projects.export.generation.hd" | translate }}</span>
                                </button>
                                <button *ngIf="isAdmin" class="btn btn-outline-primary" (click)="downloadXML()">
                                    <fa-icon [icon]="faDownload"></fa-icon>
                                </button>
                            </ng-container>
                            <ng-container *ngIf="exportType === 'HTML'">
                                <button class="btn btn-outline-primary" (click)="checkValidityMediaBeforeGenerate('html')">
                                    <fa-icon [icon]="faSyncAlt"></fa-icon>
                                    <span class="ml-1">{{ "projects.export.generation.html" | translate }}</span>
                                </button>
                                <button class="btn btn-outline-primary" (click)="downloadHTML()">
                                    <fa-icon [icon]="faDownload"></fa-icon>
                                    <span class="ml-1">{{ "projects.export.generation.download" | translate
                                        }}</span>
                                </button>
                            </ng-container>
                            <button class="btn btn-outline-primary" (click)="fullscreenPreview = !fullscreenPreview">
                                <fa-icon [icon]="fullscreenPreview ? faTimes : faExpand"></fa-icon>
                            </button>
                        </div>
                    </div>
                </div>
                <div class="d-flex flex-column align-items-center p-4 maintenance" *ngIf="!showPreview">
                    <span> - {{'projects.export.generation.noPreviewAvailable' | translate }} -</span>
                </div>
                <div class="card-body">
                    <embed
                        *ngIf="showPreview && (exportType === 'CDF' || exportType === 'Marketing' || exportType === 'BOOKLET')"
                        [style.width.%]="100"
                        [style.height.vh]="fullscreenPreview ? 93 : 80"
                        [src]="previewUrl"
                        type="application/pdf"
                    />
                    <iframe *ngIf="showPreview && exportType === 'HTML'" width="100%" height="100%"
                        [src]="previewUrl"></iframe>

                    <section class="d-flex flex-column align-items-center bgImage"  *ngIf="!showPreview" style="width: 100%; height: 100%;"></section>
                </div>
            </div>

        </div>
    </div>
</div>
