import { Component, EventEmitter, forwardRef, Input, Output } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { NgbDate } from "@ng-bootstrap/ng-bootstrap";

@Component({
    selector: "app-date-picker",
    templateUrl: "./date-picker.component.html",
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => DatePickerComponent),
            multi: true,
        },
    ],
})
export class DatePickerComponent implements ControlValueAccessor {
    private _changeCallbacks: Array<(isoStrDate: string) => any> = [];
    private _touchedCallbacks: Array<(isoStrDate: string) => any> = [];
    readonly faCalendar = faCalendarAlt;

    @Input() value = "";
    @Input() active: boolean;

    @Input() minDate: { year: number; month: number; day: number };

    @Output() dateSelect = new EventEmitter<string>();

    constructor() {
        const d = new Date().toISOString().split("T")[0];
        const splitted = d.split("-");
        if (!this.minDate) {
            this.minDate = {
                year: Number(splitted[0]),
                month: Number(splitted[1]),
                day: Number(splitted[2]),
            };
        }
    }

    writeValue(obj: string): void {
        this.value = obj;
        this._onChange(this.value);
    }

    registerOnChange(fn: (isoStrDate: string) => any) {
        this._changeCallbacks.push(fn);
    }

    registerOnTouched(fn: (isoStrDate: string) => any): void {
        this._touchedCallbacks.push(fn);
    }

    setDisabledState?(isDisabled: boolean): void {
        throw new Error("Method not implemented.");
    }

    onDateSelect(date: NgbDate) {
        this.value = date.year.toString() + "/" + this._formatDate(date.month) + "/" + this._formatDate(date.day);
        this._onTouched(this.value);
        this._onChange(this.value);
        this.dateSelect.emit(this.value);
    }

    private _formatDate(datePart: number): string {
        return datePart < 10 ? "0" + datePart : datePart.toString();
    }

    private _onTouched(isoStrDate: string) {
        this._touchedCallbacks.forEach((cb) => cb(isoStrDate));
    }
    private _onChange(isoStrDate: string) {
        this._changeCallbacks.forEach((cb) => cb(isoStrDate));
    }

    resetDate() {
        this.value = null;
        this._onTouched(this.value);
        this._onChange(this.value);
        this.dateSelect.emit(this.value);
    }

    isDateFilled(): boolean {
        return this.value != null && this.value !== '';
    }
}
